import React from 'react';
import { Form, Input } from 'antd';
import { formItemLayout } from '../services/utilsService';

const { TextArea } = Input;

const messageNew = props => {

  const { getFieldDecorator } = props.form;

  return (<Form>
    <Form.Item
      {...formItemLayout(2,22,0)}
      labelAlign={"left"}
      label="Subject"
      name="subject"
      hasFeedback>
      {getFieldDecorator('message_subject', {
        rules: [
          {
            type: 'string',
            message: 'Please enter a subject'
          },
          {
            required: true,
            message: 'Please enter a subject',
          },
        ],
        initialValue: ''
      })(<Input
            type="text"
            placeholder="Write your subject"
            onChange={e => props.setAddMessageState((e.target.value.length > 0), {subject: e.target.value})}
            />)}
    </Form.Item>
    <Form.Item
      style={{marginBottom: '0px'}}
      labelAlign={"left"}
      label="Message"
      {...formItemLayout(2,22,0)}>
      {getFieldDecorator('message', {
        initialValue: ''
      })(
        <TextArea
        placeholder="Write your message"
        autoSize
        style={{minHeight: '240px'}}
        onFocus={e => e.target.style.minHeight = '300px'}
        onBlur={e => e.target.style.minHeight = '240px'}
        onChange={e => props.setAddMessageState(null, {message: e.target.value})}/> //props.setAddMessageState(props.type, {notes: e.target.value})
      )}
    </Form.Item>
</Form>);
}

export const WrappedMessageNew = Form.create({name: 'message_new'})(messageNew);
