import React, { Component } from 'react';
import AddEditPropertyProvider from '../context/AddEditPropertyProvider';

import { Form, Tabs, Collapse, Card } from 'antd';

import { addEditPropertyBasicForm } from '../components/PropertyAddEditComponents/addEditPropertyBasicForm';
import { addEditPropertyFactForm } from '../components/PropertyAddEditComponents/addEditPropertyFactForm';
import { addEditPropertyNameForm } from '../components/PropertyAddEditComponents/addEditPropertyNameForm';
import { addEditPropertyAssignmentForm } from '../components/PropertyAddEditComponents/addEditPropertyAssignmentForm';

const { TabPane } = Tabs;

const WrappedEditPropertyAssignment = Form.create({name: 'edit_property_assignment'})(addEditPropertyAssignmentForm);
const WrappedEditPropertyBasic = Form.create({name: 'edit_property_basic'})(addEditPropertyBasicForm);
const WrappedEditPropertyFact = Form.create({name: 'edit_property_fact'})(addEditPropertyFactForm);
const WrappedEditPropertyName = Form.create({name: 'edit_property_name'})(addEditPropertyNameForm);

const { Panel } = Collapse;

class EditPropertyContainer extends Component {
  constructor(props) {
    super();
    // console.log('Container Props: ', props)
  }

  render() {
    return  <div>
              <AddEditPropertyProvider
                {...this.props}
                setEditTempPropertyState={this.props.setEditTempPropertyState}>
                {<WrappedEditPropertyName {...this.props}/>}
                <Collapse
                  defaultActiveKey={1}
                  style={{marginBottom: '30px'}}>
                  <Panel
                    header="Invites"
                    key="1">
                    <WrappedEditPropertyAssignment {...this.props}/>
                  </Panel>
                  <Panel
                    header="Property Details" key="2">
                    <Card
                      bordered={false}
                      style={{marginBottom: '30px', backgroundColor: '#fafafa', borderRadius: '6px'}}>
                      <p
                        style={{marginBottom: '0px'}}>
                        Property details are optional, fill in what may be necessary for you and the participating parties needs.
                      </p>
                    </Card>
                    <Tabs onChange={key => console.log('Key: ', key)} type="card" style={{marginBottom: '30px'}}>
                      <TabPane tab="Basic Details" key="1">
                        <WrappedEditPropertyBasic {...this.props}/>
                      </TabPane>
                      <TabPane tab="Facts/Features" key="2">
                        <WrappedEditPropertyFact {...this.props}/>
                      </TabPane>
                      <TabPane
                        tab="Construction Details"
                        key="3"
                        disabled={true}>
                        Tab 3
                      </TabPane>
                      <TabPane
                        tab="Other"
                        key="4"
                        disabled={true}>
                        Tab 4
                      </TabPane>
                    </Tabs>
                  </Panel>
                </Collapse>
                {/* { saveButton() } */}
              </AddEditPropertyProvider> 
            </div>
  }
}

export default EditPropertyContainer;
