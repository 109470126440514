import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { CONST } from '../constant/const';
import AuthRegContainer from './AuthReg/AuthRegContainer';
import DasboardContainer from './DasboardContainer';
import Verification from '../components/Verification';
import PasswordReset from '../components/PasswordReset';
import RegInviteContainer from '../containers/AuthReg/RegInviteContainer';
import { cookieParser } from '../services/utilsService';


class RouterContainer extends Component {

  render() {
    return <div>
      <Router>
        <Switch>
          <Route path='/' exact component={AuthRegContainer}/>
          <Route path='/invite/:email/:type/:propId' exact component={RegInviteContainer}/>
          <Route path='/verification/:userId/:token' exact component={Verification}/>
          <Route path='/password-reset/:userId/:token' exact component={PasswordReset}/>
          <Route path='/dashboard/:name' component={DasboardContainer}/>
          <Redirect from='/dashboard/' to={`/dashboard/${cookieParser(CONST.COOKIE.USER_URL)}/properties`} exact component={DasboardContainer}/>
          <Redirect from='*' to='/' exact component={AuthRegContainer}/>
        </Switch>
      </Router>
    </div>
  }
}

export default RouterContainer;
