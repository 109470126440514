import { CONST } from '../constant/const';
// Utils Service
export const cookieParser = cookieSelection => {
  // if cookie is unavailable
  if (!document.cookie)
    return '';
  // get cookie, and check if selected value is available
  let COOKIE = document.cookie.split('; ').find(row => row.startsWith(cookieSelection));
  if (COOKIE)
    return COOKIE.split('=')[1];
  // otherwise, send empty string
  return '';
}

export const formItemLayout = (labelInt, colInt, offsetInt) => {
  const layoutObj = {
    labelCol: {span: labelInt},
    wrapperCol: {span: colInt, offset: offsetInt}
  };
  return layoutObj;
}

export const capValue = val => {
  return val.charAt(0).toUpperCase() + val.slice(1); //Capitalize value for naming sakes
}

export const titlesFromPathNames = (pathname) => {
  const path = pathname.split('/').pop();
  switch (path) {
    case 'add-property':
      return CONST.TITLES.ADD_PROPERTY
    case 'properties':
      return CONST.TITLES.PROPERTIES
    case 'templates':
      return CONST.TITLES.TEMPLATES
    case 'messages':
      return CONST.TITLES.MESSAGES
    case 'notifications':
      return CONST.TITLES.NOTIFICATIONS
    // case 'team':
    //   return CONST.TITLES.TEAM
    case 'settings':
      return CONST.TITLES.SETTINGS
    case 'admin-portal':
        return CONST.TITLES.ADMIN_PORTAL
    // case 'add-member':
    //   return CONST.TITLES.ADD_MEMBER
    default:
      return path;
  }
}

export const colorBasedOnCharacter = char => {
  // Pass first lowercase character of word and get corresponding color in array
  const colorArr = ['#f5222d', '#52c41a', '#fa8c16', '#fadb14', '#a0d911', '#faad14', '#bfbfbf', '#fa541c', '#13c2c2', '#1890ff', '#2f54eb', '#722ed1', '#eb2f96']
  const alphabetArr = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
  const num = Math.floor(alphabetArr.indexOf(char) / 2);
  return colorArr[num];
}

export const emailValidation = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let isEmailValid = (re.test(String(email).toLowerCase()));
  return isEmailValid;
}

export const roleTypesArr = [CONST.ROLE_TYPE.BUYER, CONST.ROLE_TYPE.SELLER, CONST.ROLE_TYPE.AGENT, CONST.ROLE_TYPE.ESCROW, CONST.ROLE_TYPE.LENDER, CONST.ROLE_TYPE.TITLE];

export const states = {
  alabama: 'Alabama',
  alaska: 'Alaska',
  arizona: 'Arizona',
  arkansas: 'Arkansas',
  california: 'California',
  colorado: 'Colorado',
  connecticut: 'Connecticut',
  delaware: 'Delaware',
  district_of_columbia: 'District Of Columbia',
  florida: 'Florida',
  georgia: 'Georgia',
  hawaii: 'Hawaii',
  idaho: 'Idaho',
  illinois: 'Illinois',
  indiana: 'Indiana',
  iowa: 'Iowa',
  kansas: 'Kansas',
  kentucky: 'Kentucky',
  louisiana: 'Louisiana',
  maine: 'Maine',
  maryland: 'Maryland',
  massachusetts: 'Massachusetts',
  michigan: 'Michigan',
  minnesota: 'Minnesota',
  mississippi: 'Mississippi',
  missouri: 'Missouri',
  montana: 'Montana',
  nebraska: 'Nebraska',
  nevada: 'Nevada',
  new_hampshire: 'New Hampshire',
  new_jersey: 'New Jersey',
  new_mexico: 'New Mexico',
  new_york: 'New York',
  north_carolina: 'North Carolina',
  north_dakota: 'North Dakota',
  ohio: 'Ohio',
  oklahoma: 'Oklahoma',
  oregon: 'Oregon',
  pennsylvania: 'Pennsylvania',
  puerto_rico: 'Puerto Rico',
  rhode_island: 'Rhode Island',
  south_carolina: 'South Carolina',
  south_dakota: 'South Dakota',
  tennessee: 'Tennessee',
  texas: 'Texas',
  utah: 'Utah',
  vermont: 'Vermont',
  virgin_islands: 'Virgin Islands',
  virginia: 'Virginia',
  washington: 'Washington',
  west_virginia: 'West Virginia',
  wisconsin: 'Wisconsin',
  wyoming: 'Wyoming'
};
