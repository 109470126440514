import React from 'react';
import { AddEditPropertyContext } from '../../context/AddEditPropertyProvider';
import { Form, Input, Card} from 'antd';
import { formItemLayout } from '../../services/utilsService';

export const addEditPropertyNameForm = props => {
  const { getFieldDecorator } = props.form;
  return (<div>
            <AddEditPropertyContext.Consumer>
              {(context) => (
                <React.Fragment>
              <Form>
              <Card
                style={{marginBottom: '30px'}}>
                <Form.Item
                  {...formItemLayout(2,8,0)}
                  style={{marginBottom: '0px'}}
                  labelAlign={"left"}
                  label="Name"
                  hasFeedback>
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        type: 'string',
                        message: 'Please enter a property name'
                      },
                      {
                        required: true,
                        message: 'Please enter a property name',
                      },
                    ],
                    initialValue: context.state.propertyName //props.property ? props.property.propertyName : context.state.propertyName
                  })(<Input
                        type="text" 
                        placeholder="Propery name"
                        onChange={ e =>  {
                          if (context.state.activeRolesArr.length) // if has emails, do check to see if valid
                            context.utils.isActivesRolesEmailsValid(context.utils.assignmentForm[0])
                          context.utils.setValue({propertyName: e.target.value, isSaveDisabled: context.utils.isNameInvalid(e.target.value)})
                        }}/>)}
                </Form.Item>
              </Card>
            </Form>
            </React.Fragment>
              )}
            </AddEditPropertyContext.Consumer>
          </div>);
}

// export default AddEditPropertyNameForm;
