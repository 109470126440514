import React, { Component } from 'react';
import { restCall, ORIGIN } from '../services/restService';
import { CONST } from '../constant/const';
import { message } from 'antd';
import { cookieParser } from '../services/utilsService';
import { errorHandleUnauthorized, getUnauthorizedMsgCasting } from '../services/errorHandlingService';

export const PFGlobalContext = React.createContext();

class PFGlobalProvider extends Component {

  constructor(props) {
    super(props);

    this.state = {
      unreadMessageCount: 0,
      unreadNotificationCount: 0,
      newUnreadMessage: false,
      newUnreadNotification: false,
      hasPropertyAccess: false
    };

    this.utils = {
      setValue: valObj => {
        this.setState(valObj);
      }
    };
  }

  componentDidMount() {
    this.initHasAccess();
    this.getUnreadMessageCount();
    this.getUnreadNotificationCount();
    this.initEventNotificationAndMessage();
  }

  initHasAccess() {
    restCall('/global/has-access', CONST.REST_TYPE.GET)
      .then(res => res.json())
      .then(result => {
        // Upon 401
        if (result && !result.success && result.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, result.msg);

        if (result && result.success)
          this.setState({hasPropertyAccess: result.propertyAccess, hasAdminPortalAccess: result.adminPortal ? result.adminPortal : false});
      })
      .catch(err => 'has-access ' + err);
  }

  // Initiate to get new unread notification count pushed upon events server side
  initEventNotificationAndMessage() {
    this.eventMessage =  new EventSource(`${ORIGIN}/notifications/unread-notification-message-count-onevent/${cookieParser(CONST.COOKIE.USER_ID)}`);
    this.eventMessage.onmessage = e => {
      if (e.data) {
        if (e.data === CONST.EVENTS.NOTIFICATION) {
          this.setState({newUnreadNotification: true});
          this.getUnreadNotificationCount();
        }
        if (e.data === CONST.EVENTS.MESSAGE) {
          this.setState({newUnreadMessage: true});
          this.getUnreadMessageCount();
        }
      }
    }
  }

  getUnreadMessageCount() {
    // Get unread message count
    restCall(`/messages/get-unread-message-count/${cookieParser(CONST.COOKIE.USER_ID)}`, CONST.REST_TYPE.GET)
      .then(res => res.json())
      .then(result => {
        // Upon 401
        if (result && !result.success && result.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, result.msg);

        if (result && result.success)
          this.setState({unreadMessageCount: result.count});
      })
      .catch(err => 'Message Count Err: ' + err);
  }

  getUnreadNotificationCount() {
    // Get unread message count
    restCall(`/notifications/get-unread-notification-count/${cookieParser(CONST.COOKIE.USER_ID)}`, CONST.REST_TYPE.GET)
      .then(res => res.json())
      .then(result => {
        // Upon 401
        if (result && !result.success && result.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, result.msg);

        if (result && result.success)
          this.setState({unreadNotificationCount: result.count});
      })
      .catch(err => 'Message Count Err: ' + err);
  }

  render() {
    return <div>
      <PFGlobalContext.Provider
        value={{state: this.state, utils: this.utils}}>
        { this.props.children }
      </PFGlobalContext.Provider>
    </div>
  }

}

export default PFGlobalProvider;