import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, Icon, Row, Col, Button, Modal, message } from 'antd';
import { restCall } from '../services/restService';
import { CONST } from '../constant/const';
import { cookieParser, capValue } from '../services/utilsService';
import { errorHandleUnauthorized, getUnauthorizedMsgCasting } from '../services/errorHandlingService';
import moment from 'moment';

import '../styles/notifications.css';

const { confirm } = Modal;

class Notifications extends Component {

  constructor() {
    super();
    this.state = {notificationsArr: []};
    this.confirm = confirm;
    this.getNotificationIds = this.getNotificationIds.bind(this);
    this.resetNotificationsArr = this.resetNotificationsArr.bind(this);
  }

  componentDidMount() {
    restCall('/notifications/get-notifications', CONST.REST_TYPE.GET)
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && data.success) {
          this.setState({notificationsArr: data.notificationArr});
        }
      })
      .catch(err => `/notifications/get-notifications Err: ` + err);
  }

  confirmDelete(getNotificationIds, resetNotificationsArr) {
    this.confirm({
      title: 'Are you sure you want to dismiss all notifications?',
      okText: 'Confirm',
      maskClosable: true,
      onOk() {
        return new Promise(resolve => {
          restCall('/notifications/dismiss-all-notifications', CONST.REST_TYPE.POST, {notificationsIdArr: getNotificationIds()})
            .then(res => res.json())
            .then(data => {
              // Upon 401
              if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                return errorHandleUnauthorized(message, data.msg);

              if (data && data.success)
                resolve(resetNotificationsArr());
            })
            .catch(err => `/notifications/dismiss-all-notifications Err: ` + err);
        })
      }
    })
  }

  getNotificationIds() {
    let notificationIdsArr = [];
    notificationIdsArr.push(this.state.notificationsArr.map(notification => notification.id))
    return notificationIdsArr[0];
  }

  resetNotificationsArr() {
    this.setState({notificationsArr: []});
  }

  removeAndMarkAsDismissed(id, idx) {
    restCall(`/notifications/dismiss-notification/${id}`, CONST.REST_TYPE.POST)
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && data.success)
          this.updateNotificationsArr(idx);
      })
      .catch(err => 'notification/dismiss Err: ' + err);
  }

  updateNotificationsArr(idx) {
    this.setState(prevState => {
      let tempNotificationsObj = {
        ...prevState,
        notificationsArr: [...prevState.notificationsArr]
      }
      // Remove object in array
      tempNotificationsObj.notificationsArr.splice(idx, 1);
      return tempNotificationsObj;
    })
  }

  render() {
    return <div>
      <Card
        className="pf-general-text-block"
        bordered={false}>
        <ul
          className='list-table pf-notification-list'>
          <li style={{paddingRight: '10px'}}>
            <Button
              disabled={this.state.notificationsArr.length <= 0}
              onClick={e => {
                e.preventDefault();
                this.confirmDelete(this.getNotificationIds, this.resetNotificationsArr);
              }}>
                Dismiss All
            </Button>
          </li>
          <li style={{paddingLeft: '10px', borderLeft: '1px solid #e8e8e8'}}>
            <p
              style={{marginBottom: '0px'}}>
              Notifications are displayed in real-time in order to notify any property additions (task additions, comments).
              Notifications can be dismissed individually or all at once.
            </p>
          </li>
        </ul>
      </Card>

      {
        this.state.notificationsArr && this.state.notificationsArr.map((notification, idx) => {
          return <Card
                    key={idx}
                    hoverable="true"
                    style={{marginBottom: '25px', borderRadius: '5px', cursor: 'auto'}}>
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle">
                      <Col
                        xs={24}
                        sm={20}>
                          <Link
                              style={{fontSize: '10px'}}
                              target="blank"
                              to={{pathname: `/dashboard/${cookieParser(CONST.COOKIE.USER_URL)}/property/${notification.propertyId}/${notification.propertyName}`}}>
                              { notification.propertyName } <Icon type="arrow-up" style={{transform: 'rotate(45deg)'}}/>
                          </Link>
                        <h4
                          style={{marginBottom: '0px'}}>
                          Your { capValue(notification.role) } {notification.action} a { notification.type }
                        </h4>
                        {/* If preview available, show preview */}
                        { notification.preview && <div>
                            <small>
                              <i>{ notification.preview }</i>
                            </small>
                            <br/>
                          </div> }
                        <small
                          style={{color: '#aaa'}}>
                          <Icon type="clock-circle"/> { moment(notification.created).format('dddd h:mma') }
                        </small>
                      </Col>
                      <Col
                        xs={24}
                        sm={4}
                        style={{textAlign: 'right'}}>
                            <Button
                              onClick={e => {
                                // Dismiss 
                                this.removeAndMarkAsDismissed(this.state.notificationsArr[idx].id, idx);
                              }}>
                              Dismiss
                            </Button>
                      </Col>
                    </Row>
                  </Card>
        })
      }

    </div>
  };
}

export default Notifications;
