import React, { Component } from 'react';
import moment from 'moment';
import { restCall } from '../../services/restService';
import { CONST } from '../../constant/const';
import { Card, Row, Col, Icon, Modal, Upload, Tag, message, Checkbox, Collapse, Divider, Button } from 'antd';
import { WrappedPropertyEditTask } from './properytyEditTask';
import PropertyTaskComments from './propertyTaskComments';
import { uploadClientSide } from '../../services/uploadService';
import { errorHandleUnauthorized, getUnauthorizedMsgCasting } from '../../services/errorHandlingService';
import '../../styles/task.css';

const { confirm } = Modal;
const { Panel } = Collapse;

const getPanelHeader = uploadLength => {
  return <div>
            <Icon type="link" /> {uploadLength} Uploads
          </div>;
}

// Maybe a little ridiculous but working with confirm modal, if can do differently..
const confirmDelete = (propTypeId, taskId, type, initRemovalOfClientSideTask, uploadArrIdsDeleteOnM2M) => {
  confirm({
    title: 'Are you sure you want to delete this task?',
    content: 'Once this task has been deleted it and it\'s items cannot be retrieved.',
    okType: 'danger',
    okText: 'Delete',
    maskClosable: true,
    onOk() {
      return new Promise(resolve => {
        restCall(`/task/delete-task/${propTypeId}/${taskId}/${type}`, CONST.REST_TYPE.POST, {uploadArrIdsDeleteOnM2M: uploadArrIdsDeleteOnM2M})
                .then(res => res.json())
                .then(data => {
                  // Upon 401
                  if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                    return errorHandleUnauthorized(message, data.msg);

                  if (data && data.success) {
                    resolve(data);
                    initRemovalOfClientSideTask();
                  }
                })
              });
              
    },
    // onCancel() {},
  });
}

class PropertyTask extends Component {

  constructor(props) {
    super();
    this.state = {
      isModalVisible: false,
      isLoading: false,
      showPara: false,
      isEllipsisBeingUsed: false,
      isDraggable: false,
      ...props};

    this.paraRef = React.createRef();
    this.ref = React.createRef();
    this.initRemovalOfClientSideTask = this.initRemovalOfClientSideTask.bind(this);
    this.setStateOnEditTask = this.setStateOnEditTask.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.updateTaskComments = this.updateTaskComments.bind(this);
    this.checkEllipsis = this.checkEllipsis.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    this.checkEllipsis();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.state.id) // Dnd, if do not match, re-run to correct issue
      this.checkEllipsis();
  }

  checkEllipsis() {
    const textContainer = this.paraRef.current;
    const hasLineBreak = this.state.notes.indexOf('\n') !== -1; // Check for a line break
    const isMaxTwoLineHeight = textContainer.scrollHeight > textContainer.clientHeight;
    this.setState({isEllipsisBeingUsed: isMaxTwoLineHeight || hasLineBreak ||  isMaxTwoLineHeight});
  }

  initRemovalOfClientSideTask() {
    this.state.removeClientSideTask(this.state.propTypeIdx, this.state.taskIdx, this.state.isTaskComplete)
  }

  setStateOnEditTask(input) {
    let inputObj = input[Object.keys(input)[0]];
    this.setState({[inputObj.name]: inputObj.value});
  }

  // Check to make sure props and state match upon editing from parent component ("Mark as Complete" issue)
  static getDerivedStateFromProps(props, state) {
    if (props.id !== state.id || props.isTaskComplete !== state.isTaskComplete)
      return props;
    return null;
  }

  // TODO: currently dangersously doing this, something less dangerous may be better
  urlify(str) {
    if (!str)
      return {__html: ''};
    const URL_REGEX = /(https?:\/\/[^\s]+)/g;
    return {__html: str.replace(URL_REGEX, function(url) {
      return `<a href="${url}" target='blank'>${url}</a>`;
    })}
  }

  updateTask() {
    const updatedTask = {task: this.state.task,
                         notes: this.state.notes,
                         dueDate: this.state.dueDate};
    restCall(`/task/update-task/${this.state.id}`, CONST.REST_TYPE.POST, {payload: updatedTask})
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && data.success) {
          let updatedTaskObj = {};
          updatedTaskObj = data.task;
          this.setState({isModalVisible: !this.state.isModalVisible, isLoading: false});
          // Update property with new task and sort as well..
          this.props.updateTasksArr(this.state.propTypeIdx, this.state.taskIdx, updatedTaskObj);
          this.checkEllipsis();
        }
      });
  }

  // TODO: Updating task comments
  updateTaskComments(commentId) {
    this.setState({comments: this.state.comments.concat(commentId)});
    // Will take added comment and update task accordingly
  }

  getGrabberIcon() { // SVG Icon on task to indicate where to grab
    return (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>);
  }

  onMouseDown() {
    this.setState({isDraggable: true}); // Allows task to be grabbed only by grabber handle
  }

  onDragStart(e, order) {
    if (!this.state.isDraggable)
      e.preventDefault();
    e.dataTransfer.setData('order', order);
  }

  onDragEnd() {
    this.setState({isDraggable: false}); // Allows task to be grabbed only by grabber handle
  }

  render() {
    const paraClassNames = !this.state.showPara ? 'pf-task-para' : 'pf-task-para pf-task-para-show';
    return <div
            className="pf-task-wrap"
            data-order={this.props.order} // data-order is mandantory and dependent upon sorting logic
            onDragStart={e => this.onDragStart(e, this.props.order)}
            onDragEnd={this.onDragEnd}
            onDrop={this.onDrop}
            draggable={this.state.isDraggable}>
            <Modal
              title="Edit Task"
              className="pf-modal"
              visible={this.state.isModalVisible}
              okText="Update"
              confirmLoading={this.state.isLoading}
              okButtonProps={{disabled: this.state.task ? false : true}}
              onOk={() => {
                this.setState({isLoading: true});
                this.updateTask();
              }}
              onCancel={() => {
                this.setState({isModalVisible: !this.state.isModalVisible, task: this.props.task, notes: this.props.notes, dueDate: this.props.dueDate ? moment(this.props.dueDate) : null});
            }}>
                <WrappedPropertyEditTask
                  {...this.state}
                  propTypeIdx={this.props.propTypeIdx}
                  newTask={this.props.newTask}
                  setStateOnEditTask={this.setStateOnEditTask}/>
            </Modal>
            <Row
              type="flex"
              justify="space-around"
              align="stretch"
              className="pf-task-row">
              <Col
                xs={24}>
                <div className={`pf-task-indicator ${this.props.isTaskComplete ? ' pf-task-indicator-complete' : moment().isSameOrAfter(this.props.dueDate)  ? 'pf-task-indicator-overdue' : ''}`}></div>
                <Card bordered={false}>
                  {/* Dragger */}
                  {(!this.state.showPara && this.props.isTaskMaker) && <div
                    className='pf-task-grabber'
                    onMouseDown={this.onMouseDown}>
                      { this.getGrabberIcon() }
                    </div>}
                  <ul className="pf-task-list pf-task-list-right clearfix">
                    <li>
                      <Checkbox
                        checked={this.props.isTaskComplete}
                        onChange={() => this.props.markTaskAsComplete(this.props.id, this.props.propTypeIdx, this.props.taskIdx, this.props.type, this.props.isTaskComplete)}>
                        {this.props.isTaskComplete ? <Tag style={{cursor: 'pointer', fontSize: '11px', border: '1px solid rgb(0,0,0,65%)', color: 'rgb(0,0,0,65%)'}}>Complete</Tag> : <Tag style={{cursor: 'pointer', fontSize: '11px', border: '1px solid #006aff', color: '#006aff'}}>Mark as complete</Tag>}</Checkbox>
                    </li>
                    {this.props.isTaskMaker && <li>
                      <a href="/#" onClick={e => {
                          e.preventDefault();
                          this.setState({isModalVisible: !this.state.isModalVisible});
                        }}><Icon type="edit"/> Edit</a>
                    </li>}
                    {this.props.isTaskMaker && <li>
                      <a href="/#"
                            onClick={e => {
                              e.preventDefault();
                              const uploadArrIdsDeleteOnM2M = [];
                              if (this.state.isTemplatedTask && this.state.uploads.length > 0)
                                this.state.uploads.forEach(upload => {
                                  if (upload.isTemplatedUpload)
                                    uploadArrIdsDeleteOnM2M.push(upload.id);
                              });
                              confirmDelete(this.state.propTypeId, this.state.id, this.state.type, this.initRemovalOfClientSideTask, uploadArrIdsDeleteOnM2M);
                        }}><Icon type="delete"/> Delete</a>
                    </li>}
                    {this.props.dueDate && <li>
                      <span style={{color: this.state.isTaskComplete ? '' : moment().isSameOrAfter(this.props.dueDate) ? '#f5222d' : ''}}>
                        <Icon type="calendar"/> Due: {moment(this.props.dueDate).format('MM-DD-YYYY')}
                      </span>
                    </li>}
                  </ul>

                  <div style={{padding: '0 24px'}}>
                    <div>
                      <h4>{this.props.task}</h4>
                      {/* Currently dangerously setting this HTML apparently */}
                      <p
                        ref={this.paraRef}
                        className={paraClassNames}
                        dangerouslySetInnerHTML={this.urlify(this.state.notes)}/>
                      { this.state.isEllipsisBeingUsed && <a
                        href='/#'
                        onClick={e => {
                          e.preventDefault();
                          this.setState({showPara: !this.state.showPara});
                        }}>
                          <small>{!this.state.showPara ? 'Show more...' : 'Show less...'}</small>
                        </a> }
                    </div>

                    <Divider style={{margin: '6px 0', backgroundColor: 'transparent'}}/>

                    <Collapse
                      className='pf-task-upload-collapse'
                      bordered={false}
                      expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0}/>}>
                      <Panel
                        className='pf-task-panel pf-task-upload-panel'
                        header={getPanelHeader(this.state.uploads.length)}
                        key='1'>
                          <Upload
                            className={"pf-task-upload"}
                            showUploadList={true}
                            listType="list"
                            fileList={this.state.uploads ? this.state.uploads : []}
                            beforeUpload={file => {
                              // TODO: may or may not need to figure out type, file.type
                              if (file.size >= 100000000) { // 100000000 = 100MBs
                                // TODO: return some kind of error message
                                message.error('Upload exceeds max limit of 100MBs.');
                                return false
                              }
                              return true;
                            }}
                            customRequest={fileEvent => {
                              fileEvent.filename = fileEvent.file.name;

                              restCall('/task/task-get-signed-url', CONST.REST_TYPE.POST, {fileEvent, propId: this.state.propId})
                                  .then(res => res.json())
                                  .then(data => {
                                    // Upon 401
                                    if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                                      return errorHandleUnauthorized(message, data.msg);

                                    if (data && data.success) {
                                      const signedUrl = data.signedUrlObj;
                                      uploadClientSide(signedUrl, fileEvent)
                                        .then(result => {
                                          // Update uploads array
                                          restCall('/task/task-update-uploads', CONST.REST_TYPE.POST, {taskId: this.state.id, uploads: this.state.uploads})
                                            .then(res => res.json())
                                            .then(data => {
                                              // Upon 401
                                              if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                                                return errorHandleUnauthorized(message, data.msg);

                                              // if (data && data.success)
                                              // Possible uploads confirmation notification
                                              // console.log('updated uploads array: ', data);
                                            })
                                        })
                                    }
                                  });
                            }}
                            onChange={e => {
                              if (!e.file || e.file.size >= 100000000)
                                return;
                              // Adds URL to file within list..
                              if (e.file.status === CONST.UPLOAD.STATUS.DONE) {
                                e.fileList.map(file => {
                                  if (file.originFileObj.url)
                                    file.url = file.originFileObj.url
                                  return file;
                                });
                              }
                              // If upload removed, update uploads arr and delete object
                              if (e.file.status === CONST.UPLOAD.STATUS.REMOVED) {
                                // Update and add key prop with fileList
                                // Key, check if isTemplatedUpload, if is fail key check in order to not take off file server
                                const key = e.file.isTemplatedUpload ? '' : `${this.state.propId}/${e.file.uid}/${e.file.name}`; // Create key in order to delete object
                                const templatedUploadId = e.file.isTemplatedUpload ? e.file.id : '';
                                restCall('/task/task-update-uploads', CONST.REST_TYPE.POST, {key: key, taskId: this.state.id, uploads: e.fileList, templatedUploadId: templatedUploadId})
                                  .then(res => res.json())
                                  .then(data => {
                                    // Upon 401
                                    if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                                      return errorHandleUnauthorized(message, data.msg);
                                    // if (data && data.success)
                                      // Something here..
                                  })
                                  .catch(err => console.log('/task/task-update-uploads Err: ', err));
                              }
                              this.setState({uploads: e.fileList});
                            }}>
                            <Button
                              block={true}>
                              <Icon type="upload"/> Upload
                            </Button>
                            <div style={{textAlign: 'center', color: '#ccc'}}>
                              <small>*Max file size 100MBs</small>
                            </div>
                          </Upload>
                      </Panel>
                    </Collapse>
                  </div>

                </Card>
              </Col>
            </Row>

            <div className="pf-task-comments" style={{marginBottom: '30px'}}>
              <PropertyTaskComments
                propId={this.state.propId}
                propertyRoles={this.state.propertyRoles}
                commentsIds={this.state.comments}
                taskId={this.state.id}
                taskIdx={this.state.taskIdx}
                propTypeIdx={this.state.propTypeIdx}
                type={this.state.type}
                updateTaskComments={this.updateTaskComments}/>
            </div>
          </div>
    }
}

export default PropertyTask;
