import React, { Component } from 'react';
import moment from 'moment';
import { Card,  Modal, Divider, Table, Alert, message, Icon } from 'antd';
import { restCall } from '../../services/restService';
import { CONST } from '../../constant/const';
import { cookieParser } from '../../services/utilsService';
import { myTasksColumnsArr } from '../../services/templateService';
import { errorHandleUnauthorized, getUnauthorizedMsgCasting } from '../../services/errorHandlingService';
import { WrappedPropertyAddTask } from '../PropertyTaskComponents/properytyAddTask';
import { WrappedTemplateEditTask } from './templateEditTask';

const { confirm } = Modal;

const alertMsg = () => {
  return <div>
      Any upload to a templated task will automatically be saved in <strong>My Documents</strong> section for later use.
    </div>
}

class TemplateMyTasks extends Component {

  constructor() {
    super();
    this.userId = cookieParser(CONST.COOKIE.USER_ID);
    this.state = {
      myTasksColumns: [],
      myTasksTableData: [],
      myTasksPagination: {current: 1},
      isMyTaskTemplatesLoading: false,
      addMyTask: {},
      addMyTaskProps: {propId: this.userId, uploads: []},
      editMyTask: {isModalVisible: false, isLoading: false},
    };

    this.createNewTemplatedTask = this.createNewTemplatedTask.bind(this);
    this.setAddTaskState = this.setAddTaskState.bind(this);
    this.setStateOnEditTask = this.setStateOnEditTask.bind(this);
    this.setEditTaskLoading = this.setEditTaskLoading.bind(this);
    this.closeEditTaskModal = this.closeEditTaskModal.bind(this);
    this.handleMyTaskTableChange = this.handleMyTaskTableChange.bind(this);
    this.confirm = confirm;
    this.confirmDelete = this.confirmDelete.bind(this);
    this.getMyTaskTemplates = this.getMyTaskTemplates.bind(this);
  }

  componentDidMount() {
    // My Tasks array
    const updatedRender = date => moment(date).format('MMMM, D, YYYY h:mma');
    const taskActionsRender = (text, record) => (<div><a href="/#" onClick={e => {
              e.preventDefault();
              let editMyTask = {...this.state.editMyTask};
              // build task obj w/ record obj
              Object.keys(record).forEach(key => {
                editMyTask[key] = record[key];
              });
              this.setState({editMyTask: {...editMyTask, isModalVisible: true}});
            }}>Edit</a> | <a href="/#" onClick={e => {
              e.preventDefault();
              this.confirmDelete(record, this.getMyTaskTemplates);
            }}>Delete</a></div>);
    // Set to  state below
    this.myTasksColumnsArr = myTasksColumnsArr(updatedRender, taskActionsRender);
    this.setState({myTasksColumns: this.myTasksColumnsArr});
    this.getMyTaskTemplates();
  }

  getMyTaskTemplates() {
    this.setState({isMyTaskTemplatesLoading: true});
    restCall(`/template/get-my-task-templates/${this.userId}/${this.state.myTasksPagination.current}`, CONST.REST_TYPE.GET)
      .then(res => res.json())
      .then(result => {
        // Upon 401
        if (result && !result.success && result.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, result.msg);

        if (result && result.success)
          this.setState({myTasksTableData: result.data.tasks, isMyTaskTemplatesLoading: false, myTasksPagination: {current: this.state.myTasksPagination.current, total: result.data.count}});
      })
      .catch(err => '/template/get-task-templates Err: ' + err);
  }

  updateTemplateTask() {
    let templateTaskObj = {...this.state.editMyTask};

    // May have to do more.. check uploads just incase.?.
    if (templateTaskObj.hasUploads)
      delete templateTaskObj.hasUploads;

    // remove unnecssary properties
    delete templateTaskObj.isModalVisible;
    delete templateTaskObj.isLoading;
    restCall(`/template/update-task-template/${this.state.editMyTask.id}/`, CONST.REST_TYPE.PATCH, templateTaskObj)
      .then(res => res.json())
      .then(result => {
        // Upon 401
        if (result && !result.success && result.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, result.msg);

        if (result && result.success) {
          const hasUpdated = true;
          // Just get latest tasks for table and close modal
          this.getMyTaskTemplates();
          this.closeEditTaskModal(hasUpdated);
        }
      })
      .catch(err => '/template/get-my-document-templates Err: ' + err);
  }

  setAddTaskState(type, value) {
    this.setState({addMyTask: {...this.state.addMyTask, ...value}});
  }

  setStateOnEditTask(templatedTask) {
    // uploads have been modified, flag it
    if (Object.keys(templatedTask)[0] === 'uploads') {
      let templatedTaskForUploadObj = {...this.state.editMyTask};
      templatedTaskForUploadObj.uploads = templatedTask.uploads;
      this.setState({editMyTask: {...templatedTaskForUploadObj, hasUploads: true}});
    } else {
      let templatedTaskObj = templatedTask[Object.keys(templatedTask)[0]];
      this.setState({editMyTask: {...this.state.editMyTask, [templatedTaskObj.name]: templatedTaskObj.value}});
    }
  }

  setEditTaskLoading(isLoading) {
    this.setState({editMyTask: {...this.state.editMyTask, isLoading: isLoading}});
  }

  closeEditTaskModal(hasUpdated) {
    if (this.state.editMyTask.isLoading && !hasUpdated)
      return;
    // uploads have been modified
    if (this.state.editMyTask.hasUploads)
      this.getMyTaskTemplates();
    // Close modal w/ data
    this.setState({editMyTask: {...this.state.editMyTask, isModalVisible: false}});
    // Once cloesed, reset the editMyTask object
    setTimeout(() => this.setState({editMyTask: {isModalVisible: false, isLoading: false}}),500);
  }

  handleMyTaskTableChange(pagination) {
    this.setState({myTasksPagination: pagination}, () => this.getMyTaskTemplates());
  }

  createNewTemplatedTask(propTypeId, propTypeIdx, type, resetFields) {
    restCall('/template/create-task-template', CONST.REST_TYPE.POST, this.state.addMyTask)
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && data.success) {
          this.setState(prevState => ({
            addMyTask: {},
            myTasksTableData: [...prevState.myTasksTableData, data.data]
          }), () =>resetFields());
          this.handleMyTaskTableChange(this.state.myTasksPagination);
        }
      })
      .catch(err => `template/create-task-template Err: ` + err);
  }

  confirmDelete(templateTask, getMyTaskTemplates) {
    this.confirm({
      title: 'Are you sure you want delete?',
      content: `${templateTask.task}`,
      okText: 'Confirm',
      okType: 'danger',
      maskClosable: true,
      onOk() {
        return new Promise(resolve => {
          restCall(`/template/delete-template-task/${templateTask.id}`, CONST.REST_TYPE.DELETE)
            .then(res => res.json())
            .then(data => {
              // Upon 401
              if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                return errorHandleUnauthorized(message, data.msg);

              if (data && data.success)
                resolve(getMyTaskTemplates());
            })
            .catch(err => `/notifications/dismiss-all-notifications Err: ` + err);
        })
      }
    })
  }

  render() {
    return <div>
      {/* Edit Task Modal */}
      <Modal
        title="Edit Templated Task"
        className="pf-modal"
        visible={this.state.editMyTask.isModalVisible}
        okText="Update"
        confirmLoading={this.state.editMyTask.isLoading}
        okButtonProps={{disabled: this.state.editMyTask.task ? false : true}}
        onOk={() => {
          this.setState({editMyTask: {...this.state.editMyTask, isLoading: true}});
          this.updateTemplateTask(this.state.editMyTask.editTask);
        }}
        onCancel={() => {
          this.closeEditTaskModal();
      }}>
          <WrappedTemplateEditTask
            {...this.state.editMyTask}
            setEditTaskLoading={this.setEditTaskLoading}
            setStateOnEditTask={this.setStateOnEditTask}/>
      </Modal>
      <Card
        className="pf-general-text-block"
        style={{marginBottom: '15px'}}
        bordered={false}>
        <p
          style={{marginBottom: '0px'}}>
          Your task list comprises tasks you've added here (below) and can be seamlessly incorporated into
          any of your properties by selecting <strong>My Tasks</strong> within the respective property.
        </p>
      </Card>
      <Alert showIcon message={alertMsg()} type="info" />
      <Divider dashed orientation={"left"} style={{marginBottom: '30px'}}>
        Templated tasks
      </Divider>
      {/* Task Table */}
      {
        this.state.myTasksTableData.length > 0 &&  <Table
                                              loading={this.state.isMyTaskTemplatesLoading}
                                              rowKey={record => record.id}
                                              columns={this.state.myTasksColumns}
                                              scroll={{x: 500, y: 275}}
                                              size='middle'
                                              dataSource={this.state.myTasksTableData}
                                              pagination={this.state.myTasksPagination}
                                              onChange={this.handleMyTaskTableChange}/>
      }
      { // Empty state..
        this.state.myTasksTableData.length === 0 && <div style={{textAlign: 'center', marginBottom: '35px'}}>
            <Icon style={{ fontSize: '48px', marginBottom: '10px', color: '#ddd' }} type="folder-open" />
            <h3>You currently have no templates made</h3>
            <p
              style={{marginBottom: '0px'}}>
              Get started by adding a task below!
            </p>
        </div>
      }
      <Divider dashed orientation={"left"} style={{marginBottom: '30px'}}>
        Add a templated task
      </Divider>
      <Card
        className="pf-general-text-block"
        bordered={false}>
        <p
          style={{marginBottom: '0px'}}>
          Create a templated task here for easy reuse within any property.
          When within a specific property, simply choose <strong>My Tasks</strong> to select and apply the template.
        </p>
      </Card>
        <Card
          className='pf-card-add-task'>
          {/* Adding a task */}
          <WrappedPropertyAddTask
            {...this.state.addMyTaskProps}
            task={this.state.addMyTask.task}
            notes={this.state.addMyTask.notes}
            uploads={this.state.addMyTask.uploads}
            newTask={this.createNewTemplatedTask}
            setAddTaskState={this.setAddTaskState}/>
        </Card>
    </div>
  }

}

export default TemplateMyTasks;
