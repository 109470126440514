import React from 'react';
import moment from 'moment';
import { Row, Col, Button, Form, Input, Upload, Icon, DatePicker, message } from 'antd';
import { restCall } from '../../services/restService';
import { errorHandleUnauthorized, getUnauthorizedMsgCasting } from '../../services/errorHandlingService';
import { uploadClientSide } from '../../services/uploadService';
import { CONST } from '../../constant/const';
import '../../styles/task.css';

const { TextArea } = Input;

const propertyAddTask = props => {

  const disabledDates = current => {
    return current && current < moment().endOf('day');
  };

  const { getFieldDecorator, resetFields } = props.form;
  
  return(<Form
            layout='vertical'>
            <Form.Item
              labelAlign={"left"}
              label="Task"
              hasFeedback>
              {getFieldDecorator('task_'+[props.type], {
                rules: [
                  {
                    type: 'string',
                    message: 'Please enter a task'
                  },
                  {
                    required: true,
                    message: 'Please enter a task',
                  },
                ],
                initialValue: ''
              })(<Input
                    type="text"
                    id={props.type + 'TaskId'}
                    name={'task_'+[props.type]}
                    placeholder="Task"
                    onChange={e => props.setAddTaskState(props.type, {task: e.target.value})}
                    />)}
            </Form.Item>
            <Form.Item
              labelAlign={"left"}
              label="Notes">
              {getFieldDecorator('notes', {
                initialValue: ''
              })(
                <TextArea
                placeholder="Notes"
                autoSize={true}
                style={{minHeight: '60px'}}
                onFocus={e => e.target.style.minHeight = '90px'}
                onBlur={e => e.target.style.minHeight = '60px'}
                onChange={e => props.setAddTaskState(props.type, {notes: e.target.value})}/>
              )}
              
            </Form.Item>
            <Row
              gutter={[{ xs: 8, sm: 16}]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  labelAlign={"left"}
                  label="Upload">
                    <Upload
                      className="pf-task-upload"
                      showUploadList={true}
                      fileList={props.uploads ? props.uploads : []}
                      beforeUpload={file => {
                        // TODO: may or may not need to figure out type, file.type
                        if (file.size >= 100000000) { // 100000000 = 100MBs
                          // TODO: return some kind of error message
                          message.error('Upload exceeds max limit of 100MBs.');
                          return false
                        }
                        return true;
                      }}
                      customRequest={fileEvent => {
                        fileEvent.filename = fileEvent.file.name;

                        restCall('/task/task-get-signed-url', CONST.REST_TYPE.POST, {fileEvent, propId: props.propId})
                            .then(res => res.json())
                            .then(data => {
                              // Upon 401
                              if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                                return errorHandleUnauthorized(message, data.msg);

                              if (data && data.success) {
                                let signedUrl = data.signedUrlObj;
                                uploadClientSide(signedUrl, fileEvent)
                              }
                            });
                      }}
                      onChange={e => {
                        if (!e.file || e.file.size >= 100000000)
                          return;
                        // Adds URL to file within list..
                        if (e.file.status === CONST.UPLOAD.STATUS.DONE) {
                          e.fileList.map(file => {
                            if (file.originFileObj.url)
                              file.url = file.originFileObj.url
                            return file;
                          });
                        }
                        // Delete object if 'removed'
                        if (e.file.status === CONST.UPLOAD.STATUS.REMOVED) {
                          const key = `${props.propId}/${e.file.uid}/${e.file.name}`; // Create key in order to delete object
                          restCall('/task/task-remove-upload', CONST.REST_TYPE.POST, {key: key})
                            .then(res => res.json())
                            .then(data => {
                              // Upon 401
                              if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                                return errorHandleUnauthorized(message, data.msg);
                              // if (data && data.success)
                            })
                            .catch(err => console.log('/task-remove-upload Err: ', err));
                        }
                        props.setAddTaskState(props.type, {uploads: e.fileList})
                      }}>
                      <Button
                        block={true}>
                        <Icon type="upload"/> Upload
                      </Button>
                      <div style={{textAlign: 'center', color: '#ccc'}}>
                        <small>*Max file size 100MBs</small>
                      </div>
                    </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  className="pf-task-col-mobile"
                  labelAlign={"left"}
                  label="Due">
                  {getFieldDecorator('datepicker', {
                    
                  })(<div
                        className='pf-task-date-wrap'>
                      <DatePicker
                      format={'MM/DD/YYYY'}
                      disabledDate={disabledDates}
                      onChange={date => props.setAddTaskState(props.type, {dueDate: (date) ? (moment(date).toString()) : ''})} />
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              className="pf-task-col-mobile"
              style={{marginBottom: '0px'}}>
              <Button
                type="primary"
                className="pf-task-btn-mobile"
                disabled={!props.form.getFieldValue('task_'+[props.type])}
                onClick={e => {
                    e.preventDefault();
                    props.newTask(props.id, props.propTypeIdx, props.type, resetFields)
                  }}>
                  Add Task</Button>
            </Form.Item>
          </Form>);
};

export const WrappedPropertyAddTask = Form.create({name: 'property_add_task'})(propertyAddTask);
