import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Icon, Input, Button, notification, Modal, message } from 'antd';
import { restCall } from '../../services/restService';
import { CONST } from '../../constant/const';
import { cookieParser } from '../../services/utilsService';
import WrapForgotPassword from '../ForgotPassword';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      userURL: '',
      loading: false,
      redirect: false,
      isForgetPasswordVisible: false,
      isSendDisabled: true,
      isSendLoading: false,
      forgetPasswordEmail: ''
    };
    this.notification = notification;
  }

  initLogin() {
    const loginObj = {email: this.state.email, password: this.state.password};
    restCall('/auth/login', CONST.REST_TYPE.POST, loginObj)
    .then(res => res.json())
    .then(data => {
      if (!data.success && data.type === CONST.ERROR_TYPE.MAX_SIGNIN) {
        this.setState({loading: false});
        this.infoMaxSignInNotification();
        return;
      }

      if (data.success)
        return setTimeout(() => this.setState({redirect: true, userURL: cookieParser(CONST.COOKIE.USER_URL), loading: false}),CONST.LOAD_TIME);
      setTimeout(() => this.setState({loading: false}),CONST.LOAD_TIME);
      
      if (!data.success)
        return message.error(data.msg, CONST.INTS.MESSAGE_DURATION);
    })
    .catch(err => {
      setTimeout(() => this.setState({loading: false}),CONST.LOAD_TIME);
      console.log('auth/login Err: ', err)})
  }

  infoMaxSignInNotification() {
    this.notification.config({duration: CONST.INTS.NOTIFICATION_DURATION_MAX});
    this.notification.info({
      message: 'Max number of unverified logins reached.',
      description: 'At this time please click "Resend Verification Email" link and retrieve your email. Please note, the email could take a couple minutes.',
      btn: <a href="/#" onClick={e => this.resendNotification(e)}>Resend Verification Email</a>,
      key: '1'
    });
  }

  successConfirmationNotification() {
    this.notification.config({duration: CONST.INTS.NOTIFICATION_DURATION});
    this.notification.success({
      message: 'Email verification has been sent.',
      description: 'Please check your email, it could take a few minutes to be received.',
      key: '2'
    });
  }

  resendNotification(e) {
    e.preventDefault();
    this.notification.close('1');
    if (this.props.form.getFieldError('email') === undefined) // Email is valid
      restCall(`/sign-up/request-email-verification/${this.state.email}`, CONST.REST_TYPE.POST, {})
        .then(res => res.json())
        .then(result => {
          this.successConfirmationNotification();
        })
        .catch(err => 'request-email-verification err: ' + err);
  }

  sendForgotPasswordEmail() {
    if (!this.state.forgetPasswordEmail)
      return; // TODO: Can display alert, no email found
    restCall('/forgot-password', CONST.REST_TYPE.POST, {email: this.state.forgetPasswordEmail})
      .then(res => res.json())
      .then(result => {
        this.setState({isSendLoading: false, isForgetPasswordVisible: false});
        if (result.success)
          message.success(result.msg, CONST.INTS.MESSAGE_DURATION);
        // Otherwise, failure notifcation
      })
  }

  render () {
    if (this.state.redirect)
      return <Redirect push to={{ pathname: '/dashboard/' + this.state.userURL + '/properties',
              state: {userURL: this.state.userURL, menuLabel: 'Properties'} }} />;
    const { getFieldDecorator } = this.props.form;
    return <div>
            <Modal
              title="Forgot Password"
              destroyOnClose={true}
              visible={this.state.isForgetPasswordVisible}
              okText="Send"
              okButtonProps={{disabled: this.state.isSendDisabled}}
              confirmLoading={this.state.isSendLoading}
              onOk={() => {
                this.setState({isSendLoading: true});
                this.sendForgotPasswordEmail();
              }}
              onCancel={() => {
                this.setState({isForgetPasswordVisible: false})
              }}>
                {
                  <WrapForgotPassword 
                    setValues={obj => this.setState(obj)}/>
                }
            </Modal>
            <Form>
              <Form.Item
                hasFeedback>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: 'Input a valid e-mail'
                    },
                    {
                      required: true,
                      message: 'Input your e-mail',
                      validator: (rule, value, cb) => {
                        if (value === '' || value === undefined)
                          return setTimeout(() => cb(false), 1000);
                        setTimeout(() => cb(), 1000);
                      },
                    },
                  ],
                })(<Input
                      prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="email"
                      placeholder="E-mail"
                      onChange={e => this.setState({email: e.target.value})}/>)}
              </Form.Item>
              <Form.Item
                required={true}
                hasFeedback>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Input a valid password',
                      validator: (rule, value, cb) => {
                        const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[0-9a-zA-Z][\w~@#$%^&*+=`|\\{}:;!.?"()[\]-]{7,}$/; // Non special char
                        if (re.test(value)) {
                          return setTimeout(() => cb(), 1000);
                        } else {
                          return setTimeout(() => cb(false), 1000);
                        }
                      }
                    }
                  ],
                })(
                  <Input.Password
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Password"
                    onChange={e => this.setState({password: e.target.value})}/>,
                )}
              </Form.Item>
              <Form.Item
              style={{marginBottom: 0}}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={this.state.loading}
                  onClick={() => {
                    this.setState({loading: true});
                    this.props.form.validateFields((err, values) => {
                      if (!err)
                        return this.initLogin();
                      this.setState({loading: false});
                    })
                  }}
                  block>
                  Login
                </Button>
                <a href="/#" onClick={e => {
                  e.preventDefault();
                  this.setState({isForgetPasswordVisible: true})
                }}>Forgot password</a>
              </Form.Item>
          </Form>
          </div>;
  };
}

const WrapLogin = Form.create({ name: 'login_reg' })(Login);

export default WrapLogin;