import React, { Component } from 'react';
import { restCall } from '../services/restService';
import { Form, Input, Button, Row, Col, Divider, Card, message } from 'antd';
import { CONST } from '../constant/const';
import { errorHandleUnauthorized, getUnauthorizedMsgCasting } from '../services/errorHandlingService';

class SettingsPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {code: '', isAccessCodeLoading: false};
  }

  applyAccessCode(resetFields) {
    restCall('/settings/access-code', CONST.REST_TYPE.POST, {accessCode: this.state.code})
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && data.success) {
          message.success('Your access code has been applied successfully');
          this.setState({isAccessCodeLoading: false, code: ''});
          resetFields();
        }
      })
      .catch(err => {
        message.error('Something went wrong, please try back later');
        return 'apply-coupon Err: ' + err;
      });
  }

  render () {
    const { getFieldDecorator, resetFields } = this.props.form;
    return <Form>
            <Row>
              <Col
                lg={12}
                sm={24}
                style={{paddingLeft: '5px'}}>
                  <Card
                    bordered={false}
                    style={{marginBottom: '0', borderRadius: '3px', backgroundColor: '#f8f8f8'}}>
                    <Divider dashed orientation={"left"} style={{marginBottom: '30px'}}>Access Code</Divider>
                    <p
                      style={{marginBottom: '35px'}}>
                      For full access, please enter your access code now.
                    </p>
                    <Form.Item
                      hasFeedback>
                      {getFieldDecorator('code', {
                        initialValue: '',
                        rules: [
                          {
                            type: 'string',
                            message: 'Input your code'
                          },
                          {
                            required: true,
                            message: 'Input your code',
                          },
                        ]
                      })(<Input
                            type="text"
                            placeholder="Access Code"
                            onChange={e => this.setState({code: e.target.value})}/>)}
                      </Form.Item>
                      <Form.Item
                        style={{marginBottom: '0'}}>
                        <Button
                          type="primary"
                          disabled={!this.state.code}
                          loading={this.state.isAccessCodeLoading}
                          onClick={() => {
                            this.setState({isAccessCodeLoading: true})
                            this.applyAccessCode(resetFields)
                          }}>
                          Apply
                        </Button>
                      </Form.Item>
                  </Card>
              </Col>
            </Row>

          </Form>;
  };
}

const WrapSettingsPayment = Form.create({ name: 'settings_payment' })(SettingsPayment);

export default WrapSettingsPayment;
