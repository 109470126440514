import React, { Component } from 'react';
import { restCall } from '../services/restService';
import { CONST } from '../constant/const';
import { Redirect } from 'react-router-dom';
import { message  } from 'antd';
import { cookieParser } from '../services/utilsService';
import { errorHandleUnauthorized, getUnauthorizedMsgCasting } from '../services/errorHandlingService';

export const AddEditPropertyContext = React.createContext();

class AddEditPropertyProvider extends Component {
  constructor(props) {
    super();

    this.props = props;
    this.state = {
      propertyName: '',
      propertyId: '',
      isSaveDisabled: false,
      isLoading: false,
      isEdit: false,
      redirect: false,
      type: '',
      activeRolesArr: [], // Add edit assignment roles
      emails: {},
      basicDetails: {
        type: '',
        setPrice: '',
        offer: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        link: '',
        sqft: '',
        lotSize: '',
        bd: '',
        ba: '',
        overview: ''
      },
      factsFeatures: {
        yearBuilt: '',
        parking: '',
        hoa: '',
        heating: '',
        cooling: '',
        priceSqFt: ''
      }
    };

    this.utils = {
      setValue: (valObj) => {
        this.setState(valObj);
      },
      assignmentForm: [],
      totalRolesArr: [],
      getAddEditRolesArr: (excludeType, activeRoles) => { // Edit available roles based upon user type and if seller or buyer
        let rolesArr = [CONST.ROLE_TYPE.AGENT, CONST.ROLE_TYPE.LENDER, CONST.ROLE_TYPE.ESCROW, CONST.ROLE_TYPE.TITLE, CONST.ROLE_TYPE.BUYER, CONST.ROLE_TYPE.SELLER];
        if (activeRoles.length && activeRoles.indexOf(CONST.ROLE_TYPE.BUYER) > -1)
          rolesArr.splice(rolesArr.indexOf(CONST.ROLE_TYPE.SELLER), 1)
        if (activeRoles.length && activeRoles.indexOf(CONST.ROLE_TYPE.SELLER) > -1)
          rolesArr.splice(rolesArr.indexOf(CONST.ROLE_TYPE.BUYER), 1)
        if (excludeType) {
          rolesArr.splice(rolesArr.indexOf(excludeType), 1)
        }
        return rolesArr;
      },
      isNameInvalid: val => {
        // TODO: from name check and see if roles are valid
        let nameInvalid = val.length ? false : true;
        return nameInvalid;
      },
      setRolesCorrectValue: (form, rolesArr) => { // Check to see if role/emails have changed/reset
        rolesArr.forEach(val => {
          if (this.state.emails[val] && form.getFieldValue(val) !== this.state.emails[val])
            this.setState({emails: {...this.state.emails, [val]: ''}})
        });
      },
      isActivesRolesEmailsValid: form => {
        setTimeout(() => {
          const errors = form.getFieldsError(this.state.activeRolesArr);
          let isEmptyOrUndefined = false;
          let hasErrors = false;
          this.state.activeRolesArr.forEach(val => {
            if (this.state.emails[val] === undefined || this.state.emails[val] === '')
              isEmptyOrUndefined = true;
          });
          
          hasErrors = Object.values(errors).find(val => val !== undefined);
          this.setState({isSaveDisabled: !(hasErrors === undefined && !isEmptyOrUndefined && !this.utils.isNameInvalid(this.state.propertyName)) }) //&& (this.utils.isNameInvalid(this.state.propertyName) === false)
        }, 600)// Timeout to bypass user indication
      },
      saveProperty: () => {
        // Get users type/role, agent/lender/title, ect.
        this.setState({isLoading: true});
        const propertyObj = this.buildPropertyObj();
        restCall('/property/create', CONST.REST_TYPE.POST, propertyObj)
        .then(res => res.json())
        .then(data => {
          if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
            return errorHandleUnauthorized(message, data.msg);

          if (data && data.success)
            this.setState({isLoading: false, propertyId: data.propertyId, redirect: true});
        })
        .catch(err => console.log('ProfileType Err: ', err));
      }
    };
  }

  componentDidMount() {
    if (!this.state.propertyName.length && this.props.property === undefined) // Upon mount, enable/disable save button. If props.property, is edit
      this.setState({isSaveDisabled: true});
      
    // Get users type/role, agent/lender/title, ect.
    restCall('/profile/get-field', CONST.REST_TYPE.POST, {field: 'type'})
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && data.success) {
          if (data.type)
            this.setState({type: data.type});
        }
      })
      .catch(err => 'ProfileType Err: ' + err);
    
    // If property is being edited, setState
    if (this.props.property) {
      let propertyObj = Object.assign({}, this.props.property);
      propertyObj.activeRolesArr = [];
      propertyObj.isEdit = true;
      propertyObj.newInviteArr = [];
      delete propertyObj.propertyTypes;
      if (propertyObj.emails)
        Object.keys(propertyObj.emails).forEach(key => {
          // If emails is current user, do not push to array
          if (key !== cookieParser(CONST.COOKIE.USER_TYPE))
            propertyObj.activeRolesArr.push(key);
        })
      this.setState(prevState => ({...propertyObj}))
    }
  }

  // Upon component updating "state", check if edited in Property.js needs to be updated and temp state set there (Property.js)
  componentDidUpdate() {
    if (this.state.isEdit) // Only run when editing
      this.props.setEditTempPropertyState(this.state);
  }

  // Build property object in order to create property payload
  buildPropertyObj() {
    let propertyObj = Object.assign({}, this.state);
    delete propertyObj.activeRolesArr;
    delete propertyObj.isSaveDisabled;
    delete propertyObj.isLoading;
    delete propertyObj.redirect;
    delete propertyObj.propertyId;
    delete propertyObj.isEdit;
    Object.keys(CONST.ROLE_TYPE).forEach(value => {
      // If not in array and email is present in state then delete
      if (this.state.activeRolesArr.indexOf(CONST.ROLE_TYPE[value]) === -1 && (this.state.emails[CONST.ROLE_TYPE[value]] || this.state.emails[CONST.ROLE_TYPE[value]] === undefined || this.state.emails[CONST.ROLE_TYPE[value]] === '') )
        delete propertyObj.emails[CONST.ROLE_TYPE[value]];
    });
    return propertyObj;
  }

  render() {
    //history.push(`/dashboard/${cookieParser(CONST.COOKIE.USER_URL)}/property/${propertyId}/${propertyObj.propertyName}`);
    if (this.state.redirect)
    return <Redirect push to={{pathname: `/dashboard/${cookieParser(CONST.COOKIE.USER_URL)}/property/${this.state.propertyId}/${this.state.propertyName}`}} />;
    return <div>
        <AddEditPropertyContext.Provider value={{state: this.state, utils: this.utils}}>
          { this.props.children }
        </AddEditPropertyContext.Provider>
      </div>
  }
}

export default AddEditPropertyProvider;