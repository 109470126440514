import React from 'react';
import { AddEditPropertyContext } from '../../context/AddEditPropertyProvider';
import { Form, InputNumber, Select, Card } from 'antd';
import { formItemLayout, capValue } from '../../services/utilsService';

const { Option } = Select;

const factFeatureArr = [
  {
    name: 'yearBuilt',
    label: 'Year Built',
    placeholder: 'n/a'
  },
  {
    name: 'parking',
    label: 'Parking',
    placeholder: 'n/a'
  },
  {
    name: 'hoa',
    label: 'HOA',
    placeholder: 'n/a'
  },
  {
    name: 'heating',
    label: 'Heating',
    placeholder: 'Select'
  },
  {
    name: 'cooling',
    label: 'Cooling',
    placeholder: 'Select'
  },
  {
    name: 'priceSqFt',
    label: 'Price/SqFt',
    placeholder: 'n/a'
  }
];

export const addEditPropertyFactForm = props => {
  return (
    <AddEditPropertyContext.Consumer>
      {(context) => (
        <React.Fragment>
          <Form>
            <Card
              bordered={false}
              style={{marginBottom: '20px', backgroundColor: '#fafafa', borderRadius: '6px'}}>
              <p
                style={{marginBottom: '0px'}}>Please fill in necessary facts and features.</p>
            </Card>
            {
              factFeatureArr.map((val, idx) => {
                if (val.name === 'heating' || val.name === 'cooling')
                  return <Form.Item
                            {...formItemLayout(2,3,0)}
                            key={idx}
                            labelAlign={"left"}
                            label={val.label}>
                              <Select
                                placeholder={capValue(context.state.factsFeatures[val.name]) || val.placeholder}
                                onChange={value => context.utils.setValue({factsFeatures: {...context.state.factsFeatures, [val.name]: value}})}>
                                <Option value="none">None</Option>
                                <Option value="forced">Forced Air</Option>
                              </Select>
                          </Form.Item>
                return <Form.Item
                          {...formItemLayout(2,3,0)}
                          style={{marginBottom: val.name === 'priceSqFt' ? '0px' : ''}}
                          key={idx}
                          label={val.label}
                          labelAlign={"left"}>
                            <InputNumber
                              min={0}
                              placeholder={val.placeholder}
                              defaultValue={context.state.factsFeatures[val.name]}
                              formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/(,*)/g, '')}
                              onChange={value => context.utils.setValue({factsFeatures: {...context.state.factsFeatures, [val.name]: value}})}/>
                        </Form.Item>
              })
            }
          </Form>
        </React.Fragment>
              )}
      </AddEditPropertyContext.Consumer>
  );
}