import React from 'react';
import { Collapse, Descriptions, Icon } from 'antd';
import { CONST } from '../constant/const';

const { Panel } = Collapse;

// CSS is coming from '../styles/property.css'

export const PropertyDetails = props => {
  // basicDetailLabelValObj ensures order and proper labeling
  const basicDetailsObj = {
    address: {label: 'Address'},
    city: {label: 'City'},
    state: {label: 'State'},
    zipcode: {label: 'Zipcode'},
    offer: {label: 'Offer'},
    setPrice: {label: 'Set price'},
    type: {label: 'Type'},
    sqft: {label: 'SqFt'},
    lotSize: {label: 'Lot size'},
    ba: {label: 'Ba'},
    bd: {label: 'Br'},
    link: {label: 'Link'},
    overview: {label: 'Overview'}};

    const factsFeaturesObj = {
      yearBuilt: {label: 'Year built'},
      hoa: {label: 'HOA'},
      priceSqFt: {label: 'Price sqft'},
      cooling: {label: 'Cooling'},
      heating: {label: 'Heating'}};

  return (<Collapse
            className="pf-property-collapse"
            bordered={false}
            expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0}/>}>
              <Panel
                header="Property Details"
                key="1">
                <Descriptions
                  className="pf-property-description"
                  title="Basic Details"
                  size="small"
                  style={{marginBottom: '0'}}>
                  {
                    Object.keys(basicDetailsObj).map((key, idx) => {
                      return <Descriptions.Item
                                key={idx}
                                label={basicDetailsObj[key].label}>
                                  {props.basicDetails[key] ? (key === CONST.PROPERTY_DETAILS.GENERAL.LINK) ? <a href={props.basicDetails[key]} target="_blank" rel="noopener noreferrer"><Icon type="select"/> {props.basicDetails[key]}</a> : props.basicDetails[key] : CONST.GENERAL.LABELS.NA}
                              </Descriptions.Item>
                    })
                  }
                </Descriptions>
                <Descriptions
                  className="pf-property-description"
                  title="Facts/Features"
                  size="small">
                  {
                    Object.keys(factsFeaturesObj).map((key, idx) => {
                      return <Descriptions.Item
                                key={idx}
                                label={factsFeaturesObj[key].label}>
                                  {props.factsFeatures[key] ? props.factsFeatures[key] : CONST.GENERAL.LABELS.NA}
                              </Descriptions.Item>
                    })
                  }
                </Descriptions>
              </Panel>
          </Collapse>);
};
