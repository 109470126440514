import React, { Component } from 'react';
import SettingsAccount from './SettingsAcount';
import SettingsPayment from './SettingsPayment';
import { Card, Tabs } from 'antd';
// import { restCall } from '../services/rest';
// import { CONST } from '../constant/const';

const { TabPane } = Tabs;

class Settings extends Component {

  constructor() {
    super();
    this.state = {};
  }

  render() {
    return <div>
      <Card
        className="pf-general-text-block"
        bordered={false}>
        <p
          style={{marginBottom: '0px'}}>
          Change your account and payments settings. Please be aware that changing your <strong>type</strong> could effect your current properties.
        </p>
      </Card>
      <Tabs
        type="card">
        <TabPane
          key={1}
          tab="Account Settings">
            {/* <p>
              Please use toggle in order to edit your account settings.
            </p> */}
            <SettingsAccount/>
        </TabPane>
        <TabPane
          key={2}
          tab="Payment Settings">
            <SettingsPayment/>
        </TabPane>
      </Tabs>
    </div>
  };
}

export default Settings;
