// import { CONST } from '../constant/const';

// Template Service

// My task columns for ant.design table
export const myTasksColumnsArr = (updatedRender, taskActionsRender) => {
   // My Tasks array
  const myTasksColumnsArr = [{
    title: 'Task title',
    dataIndex: 'task',
    key: 'task',
    ellipsis: true
  },
  {
    title: 'Updated',
    dataIndex: 'updated',
    key: 'updated',
    width: 250,
    render: updatedRender
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fixed: 'right',
    width: 150,
    render: taskActionsRender
  }];
  return myTasksColumnsArr;
};

// My task columns for ant.design table
export const addUploadToTaskColumnsArr = (updatedRender) => {
  // My Tasks array
 const addUploadToTaskColumnsArr = [{
   title: 'Task title',
   dataIndex: 'task',
   key: 'task',
   ellipsis: true
 },
 {
   title: 'Updated',
   dataIndex: 'updated',
   key: 'updated',
   width: 250,
   render: updatedRender
 }];
 return addUploadToTaskColumnsArr;
};

// Same as above, just for naming purposes (maybe could have been named better..)
export const addTaskColumnsArr = updatedRender => addUploadToTaskColumnsArr(updatedRender);

// My doc columns for ant.design table
export const myDocumentsColumnsArr = (docTitleRender, createdRender, docActionsRender) => {
  const myDocumentsColumnsArr = [{
    title: 'Document title',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: docTitleRender
  },
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    width: 250,
    render: createdRender
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fixed: 'right',
    width: 175,
    render: docActionsRender
  }];

  return myDocumentsColumnsArr;
};

// PF task columns for ant.design table
export const pfTasksColumnsArr = (pfTaskUpdatedRender, pfTaskActionsRender) => {
  const pfTasksColumnsArr = [{
    title: 'Task title',
    dataIndex: 'task',
    key: 'task',
    ellipsis: true
  },
  {
    title: 'Updated',
    dataIndex: 'updated',
    key: 'updated',
    width: 250,
    render: pfTaskUpdatedRender
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fixed: 'right',
    width: 150,
    render: pfTaskActionsRender
  }];

  return pfTasksColumnsArr;
};

// PF doc columns for ant.design table
export const pfDocumentsColumnsArr = (pfDocTitleRender, pfCreatedRender, pfActionsRender) => {
  const pfDocumentsColumnsArr = [{
    title: 'Document title',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: pfDocTitleRender
  },
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    width: 250,
    render: pfCreatedRender
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fixed: 'right',
    width: 150,
    render: pfActionsRender
  }];

  return pfDocumentsColumnsArr;
};
