import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PFGlobalContext } from '../context/PFGlobalProvider';
import { Row, Col, Card, Avatar, Empty, Button, Icon, Badge, Input, Tag, Modal, Skeleton, message } from 'antd';
import { restCall } from '../services/restService';
import { cookieParser, capValue, colorBasedOnCharacter } from '../services/utilsService';
import { errorHandleUnauthorized, getUnauthorizedMsgCasting } from '../services/errorHandlingService';
import { CONST } from '../constant/const';
import moment from 'moment';

import '../styles/messages.css';

const { TextArea } = Input;
const { confirm } = Modal;

class Messages extends Component {

  constructor(props) {
    super();
    this.state = {messages: [], message: {conversation: [], meta: {}, idx: ''}, response: '', messagePageIdx: 1, isLoading: false, isMoreMessagesDisabled: true, isMoreMessagesLoading: false };
    this.confirm = confirm;
    this.removeMessageFromMessagesArr = this.removeMessageFromMessagesArr.bind(this);
  }

  componentDidMount() {
    this.getMappedMessages();
  }

  getMappedMessages() {
    // Get mapped messages
    restCall(`/messages/get-mapped-messages/${cookieParser(CONST.COOKIE.USER_ID)}/${this.state.messagePageIdx}`, CONST.REST_TYPE.GET)
      .then(res => res.json())
      .then(result => {
        // Upon 401
        if (result && !result.success && result.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, result.msg);

        if (result && result.success) {
          if (this.state.messagePageIdx > 1) // If retrieving more message, concat..
            return this.setState({messages: this.state.messages.concat(result.arrMessages), isMoreMessagesDisabled: result.arrMessages.length < CONST.INTS.MAX_EMAIL_PAGE, isMoreMessagesLoading: false});
          // Otherwise, set messages
          this.setState({messages: result.arrMessages, isMoreMessagesDisabled: result.arrMessages.length < CONST.INTS.MAX_EMAIL_PAGE, isMoreMessagesLoading: false});
        }
      })
      .catch(err => '/messages/get-mapped-messages Err: ' + err);
  }

  getMessageAndMarkAsRead(currentMessage, idx) {
    restCall(`/messages/get-message/${currentMessage.messageId}/${currentMessage.id}/${currentMessage.hasRead}`, CONST.REST_TYPE.GET)
      .then(res => res.json())
      .then(result => {
        // Upon 401
        if (result && !result.success && result.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, result.msg);

        if (result && result.success) {
          if (result.hasRead) // If marked as read
            currentMessage.hasRead = result.hasRead;
          // Will set state of array as well *(hasRead of array is set too)
          this.setState({message: {conversation: result.messages, meta: currentMessage, idx: idx}});
        }
      })
      .catch(err => 'get-message Err: ' + err)
  }

  sendResponse() {
    this.setState({loading: true});
    const responseObj = {messageId: this.state.message.meta.messageId,
                          recipientId: this.state.message.meta.createdBy !== cookieParser(CONST.COOKIE.USER_ID) ? this.state.message.meta.recId : this.state.message.meta.createdBy,
                          mappedMessageId: this.state.message.meta.id,
                          response: this.state.response,
                          userId: cookieParser(CONST.COOKIE.USER_ID)};
    
    if (!responseObj.response) // If no response, return error message
      return;

    restCall(`/messages/send-response`, CONST.REST_TYPE.POST, responseObj)
      .then(res => res.json())
      .then(result => {
        // Upon 401
        if (result && !result.success && result.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, result.msg);

        if (result && result.success) {
          this.setState(prevState => {
            let tempMessageObj = {
              ...prevState,
              response: '',
              loading: false
            };
            tempMessageObj.message.conversation = result.messages;
            tempMessageObj.messages[tempMessageObj.message.idx].hasReplied = true;
            return tempMessageObj;
          });
        }
      })
      .catch(err => 'send-response Err: ' + err)
  }

  removeMessageFromMessagesArr(messageMappedId, idx) {
    this.setState(prevState => {
      let tempMessageObj = {
        ...prevState,
        // reset message
        message: {conversation: [], meta: {}, idx: ''}
      }
      // Remove message from array
      tempMessageObj.messages.splice(idx, 1);
      return tempMessageObj;
    });
  }
  
  confirmDelete(currentMessage, removeMessageFromMessagesArr) {
    this.confirm({
      title: 'Are you sure you want to delete this message?',
      content: 'This message will be able to be retrieved.',
      okType: 'danger',
      okText: 'Delete',
      maskClosable: true,
      onOk() {
        return new Promise(resolve => {
          restCall(`/messages/mark-message-as-delete/${currentMessage.meta.id}`, CONST.REST_TYPE.POST, {})
                  .then(res => res.json())
                  .then(data => {
                    // Upon 401
                    if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                      return errorHandleUnauthorized(message, data.msg);

                    if (data && data.success) {
                      // Resolve in order to dismiss confirmation
                      resolve(data);
                      removeMessageFromMessagesArr(currentMessage.meta.id, currentMessage.idx);
                    }
                  })
                  .catch(err => `/messages/mark-message-as-delete/${currentMessage.meta.id} Err: ` + err)
        })
      }
    })
  }


  render () {
    return <PFGlobalContext.Consumer>
      {context => (
        <div>
          <Row>
            <Col
              sm={24}
              md={8}>
              <div
                className="pf-message-queue-heading">
                <ul className="list-table">
                  <li>
                    <h4 style={{marginBottom: '0px', marginRight: '5px'}}>
                      Inbox
                    </h4>
                  </li>
                  <li>
                    <Tag
                      style={{position: 'absolute', top: '12px'}}
                      color="#bfbfbf">
                        <strong>{ context.state.unreadMessageCount }</strong> Unread
                    </Tag>
                  </li>
                </ul>
                <Button
                  color="#bfbfbf"
                  size="small"
                  disabled={context.state.unreadMessageCount <= 0}
                  loading={this.state.isLoading}
                  style={{position: 'absolute', right: '12px', cursor: 'pointer'}}
                  onClick={() => {
                    this.setState({message: {conversation: [], meta: {}, idx: ''}, messagePageIdx: 1}, this.getMappedMessages());
                  }}>
                    <Icon type="reload"/>
                </Button>
              </div>
              <div
                className="pf-messages-queue">
                {/* Inbox cards, messages */}
                {
                  this.state.messages.length > 0 ? this.state.messages.map((message, idx) => {
                    return <Card
                            key={idx}
                            bordered={false}
                            className={`pf-messages-card ${this.state.message.idx === idx ? ' pf-messages-card-active' : ''}`}
                            onClick={() => {
                              this.getMessageAndMarkAsRead(message, idx);
                            }}>
                            <span
                              className="pf-messages-queue-date">{ moment(message.updated).fromNow() }</span>
                            { !message.hasRead && <Badge
                                                    color="blue"
                                                    className="pf-messages-badge"/> }
                            { message.hasReplied && <Icon 
                                                    type="enter"
                                                    className="pf-messages-icon-replied"/> }
                            <ul
                              className="list-table">
                              <li
                                style={{paddingRight: '10px'}}>
                                <Avatar
                                  size="large"
                                  style={{backgroundColor: colorBasedOnCharacter(message.firstname.charAt(0).toLowerCase()), verticalAlign: 'middle'}}>
                                    { message.firstname.charAt(0) + message.lastname.charAt(0) }
                                </Avatar>
                              </li>
                              <li>
                                <span className="pf-messages-queue-name">
                                  { capValue(message.firstname) } { capValue(message.lastname) }, { capValue(cookieParser(CONST.COOKIE.USER_ID) === message.createdBy ? message.recRole : message.createdByRole) }
                                </span>
                                <h4>{ message.subject.length > 30 ? message.subject.substring(0, 30) + '...' :  message.subject}</h4>
                                <span className="pf-messages-property-name">
                                  Property: { message.propertyName.length > 30 ? message.propertyName.substring(0, 30) + '...' : message.propertyName }
                                </span>
                              </li>
                            </ul>
                          </Card>
                  })
                  : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="0 Messages" />
                }

              </div>
              {/* TODO: Dependent upon count and paging, change verbiage of button, disable */}
              <Button
                style={{marginTop: '15px'}}
                type="primary"
                block
                loading={this.state.isMoreMessagesLoading}
                disabled={this.state.isMoreMessagesDisabled}
                onClick={() => {
                  this.setState({messagePageIdx: this.state.messagePageIdx + 1, isMoreMessagesLoading: true}, () => this.getMappedMessages());
                }}>
                  More Messages
              </Button>
            </Col>
            
            {/* Message display area */}
            <Col
              sm={24}
              md={16}>
              <div
                className="pf-message-queue-heading pf-heading-message">
                <h4 style={{marginBottom: '0px'}}> Message</h4>
              </div>

              {/* Message display placeholer */}
              {
                this.state.message.conversation.length <= 0 && <div style={{paddingLeft: '22px', paddingTop: '2px'}}>
                  <Skeleton avatar paragraph={{ rows: 5 }} />
                </div>
              }

              { this.state.message.conversation.length > 0 && <div
                className="pf-messages-display">
                {/* Message displayed below */}
                {
                  this.state.message.conversation.length > 0 ? <div className="pf-messages-message-meta">
                    <Icon
                      type="delete"
                      className="pf-messages-delete"
                      onClick={() => this.confirmDelete(this.state.message, this.removeMessageFromMessagesArr)}/>
                    <span
                      className="pf-messages-coversation-heading-date">
                      {moment(this.state.message.meta.created).format('MMMM, D, YYYY h:mma')}
                    </span>
                    <ul
                      className="list-table">
                        <li
                          style={{paddingRight: '10px'}}>
                          <Avatar
                            size="large"
                            style={{backgroundColor: colorBasedOnCharacter(this.state.message.meta.firstname.charAt(0).toLowerCase()), verticalAlign: 'middle'}}>
                              { this.state.message.meta.firstname.charAt(0) + this.state.message.meta.lastname.charAt(0) }
                          </Avatar>
                        </li>
                        <li>
                          <h4
                            style={{marginBottom: '0px'}}>
                            {capValue(this.state.message.meta.firstname) + ' ' + capValue(this.state.message.meta.lastname)}
                            , { capValue(cookieParser(CONST.COOKIE.USER_ID) === this.state.message.meta.createdBy ? this.state.message.meta.recRole : this.state.message.meta.createdByRole) }
                          </h4>
                          <span className="pf-messages-property-name">
                            Property : <Link
                              target="blank"
                              to={{pathname: `/dashboard/${cookieParser(CONST.COOKIE.USER_URL)}/property/${this.state.message.meta.propId}/${this.state.message.meta.propertyName}`}}>
                                { this.state.message.meta.propertyName } <Icon type="arrow-up" style={{transform: 'rotate(45deg)'}}/>
                            </Link>
                          </span>
                        </li>
                    </ul>
                    <h3
                      style={{marginBottom: '10px', marginLeft: '50px'}}>
                      { this.state.message.meta.subject }
                    </h3>
                  </div>: ''
                }

                {/* Conversation area */}
                <div
                  //TODO: below based upon inbox queue, button is not equated for if not present as of now
                  style={{maxHeight: 'calc(100vh - 149px)'}}
                  className="pf-messages-conversation-display">
                    {/* Conversation displayed below */}
                    {
                      this.state.message.conversation.map((message, idx) => {
                        return <div
                                  key={idx}
                                  className="pf-message-conversation"
                                  style={{
                                    // remove border and padding if on message and or last message
                                    borderBottom: this.state.message.conversation.length > 1 && this.state.message.conversation.length !== idx + 1 ? '1px solid #f8f8f8' : '',
                                    paddingBottom: this.state.message.conversation.length > 1 && this.state.message.conversation.length !== idx + 1 ? '15px' : '0'}}>
                                  <div
                                    style={{marginBottom: '3px'}}>
                                    <ul className="list-table">
                                      <li>
                                      <Avatar
                                        size="medium"
                                        style={{marginLeft: '-5px', marginRight: '5px', marginTop: '5px', fontSize: '10px', backgroundColor: message.createdBy !== cookieParser(CONST.COOKIE.USER_ID) ? colorBasedOnCharacter(this.state.message.meta.firstname.charAt(0).toLowerCase()) : colorBasedOnCharacter(cookieParser(CONST.COOKIE.USER_URL).split('.')[0].charAt(0).toLowerCase()), verticalAlign: 'middle'}}>
                                          { message.createdBy !== cookieParser(CONST.COOKIE.USER_ID) ? this.state.message.meta.firstname.charAt(0) + this.state.message.meta.lastname.charAt(0) : capValue(cookieParser(CONST.COOKIE.USER_URL).split('.')[0].charAt(0)) + capValue(cookieParser(CONST.COOKIE.USER_URL).split('.')[1].charAt(0))}
                                      </Avatar>
                                      </li>
                                      <li>
                                      <span
                                        className="pf-messages-coversation-name">
                                            <strong>{ message.createdBy !== cookieParser(CONST.COOKIE.USER_ID) ? ` ${capValue(this.state.message.meta.firstname)} ${capValue(this.state.message.meta.lastname)}`  : ' Me' }</strong>
                                      </span>
                                      <div
                                        className="pf-messages-coversation-date">
                                        {/* {moment(message.created).fromNow()} */}
                                        { moment(message.created).format('dddd h:mma') }
                                      </div>
                                      </li>
                                    </ul>
                                  </div>
                                  <p>
                                    { message.message }
                                  </p>
                                </div>
                      })
                    }
                </div>

                {/* Response area */}
                <div
                  className="pf-messages-textarea">
                  <TextArea
                    placeholder="Start writing your response here"
                    autoSize
                    value={this.state.response}
                    style={{minHeight: '50px', marginBottom: '12px'}}
                    onFocus={e => e.target.style.minHeight = '120px'}
                    onBlur={e => e.target.style.minHeight = '50px'}
                    onChange={e => this.setState({response: e.target.value}) }/>
                  <Button
                    type="primary"
                    disabled={!this.state.response}
                    loading={this.state.isLoading}
                    onClick={() => this.sendResponse()}>
                    Send
                  </Button>
                </div>
              </div> }
            </Col>
          </Row>
        </div>
    )}
    </PFGlobalContext.Consumer>
  }
}

export default Messages;