import React, { Component } from 'react';
import { PFGlobalContext } from '../context/PFGlobalProvider';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Pagination, message, Icon, Button } from 'antd';
import { restCall } from '../services/restService';
import { cookieParser } from '../services/utilsService';
import { CONST } from '../constant/const';
import { errorHandleUnauthorized, getUnauthorizedMsgCasting } from '../services/errorHandlingService';
import '../styles/properties.css';

class Properties extends Component {
  constructor(props) {
    super();
    this.state = {loading: true, current: 1, properties: [], count: 0};

    this.username = cookieParser(CONST.COOKIE.USER_URL); // Get username to keep url intact
    this.userType = cookieParser(CONST.COOKIE.USER_TYPE);
  }
  
  componentDidMount() {
    if (this.props.location.page) // If user went back and has page number
      return this.setState({current: this.props.location.page}, () => this.getPage());
    this.getPage()
  }

  getPage() {
    restCall(`/property/get-properties/${this.state.current}`, CONST.REST_TYPE.GET)
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);
        
        if (data && data.success) {
          setTimeout(() => this.setState({properties: data.properties, count: data.count, loading: false}),500);
        }
        
      })
      .catch(err => console.log('ProfileType Err: ', err));
  }

  render() {
    const isBuyerOrSeller = (CONST.ROLE_TYPE.BUYER === this.userType || CONST.ROLE_TYPE.SELLER === this.userType);
    // Para based upon type
    const emptyStatePara = isBuyerOrSeller ? 'Your agent, lender, escrow, title agent will need to add you to a property.' : 'Add a property that will have participating parties of your choice.' ;
    return <div style={{textAlign: 'center'}}>
      <PFGlobalContext.Consumer>
        {context => (
          <div>
            { // Empty states
              !this.state.properties.length && !this.state.loading && <div style={{textAlign: 'center', marginBottom: '35px'}}>
                <Icon style={{ fontSize: '48px', marginBottom: '10px', color: '#ddd' }} type="folder-open" />
                <h3>You currently have no properties</h3>
                <p>
                    { emptyStatePara }
                </p>
                <Button
                  type='primary'
                  icon='plus'
                  hidden={isBuyerOrSeller || !context.state.hasPropertyAccess} // hide if buyer/seller or no access
                  href={`/dashboard/${this.username}/add-property`}>
                    Add property
                </Button>
                { // If not buyer/seller and doesn't have access
                  (!context.state.hasPropertyAccess && !isBuyerOrSeller) && <Link to={{pathname: `/dashboard/${this.username}/settings`}}>For a limited time, go to: Settings / Payment Settings / access code: PROPERTYFLO2021</Link>
                }
              </div>
            }

            <Row
              style={{marginBottom: '10px'}}
              gutter={16}>
            {
              this.state.properties &&  this.state.properties.map((val, idx) => {
                  return <Col 
                            key={idx}
                            sm={24}
                            md={8}>
                            <Link
                              to={{pathname: `property/${val.id}/${val.propertyName}`, page: this.state.current}}>
                            <Card
                              hoverable
                              type="inner"
                              headStyle={{textAlign: 'left'}}
                              className='pf-properties-card'
                              title={val.propertyName}>
                              <small>
                                { val.basicDetails.address && val.basicDetails.address + ' | ' + val.basicDetails.city }
                                { !val.basicDetails.address && 'Address:'}
                              </small>
                            </Card>
                            </Link>
                          </Col>
                })
            }
            </Row>
            {
              this.state.count > 12 && <Pagination
                total={this.state.count}
                defaultPageSize={12}
                current={this.state.current}
                onChange={page => {
                  this.setState({current: page, loading: true}, () => this.getPage()) }}/>
            }
          </div>
        )}
      </PFGlobalContext.Consumer>
    </div>
  
  }
}

export default Properties;