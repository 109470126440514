import React, { Component } from 'react';
import { Modal, Form, Select, message } from 'antd';
import { restCall } from '../services/restService';
import { CONST } from '../constant/const';
import { cookieParser } from '../services/utilsService';
import { errorHandleUnauthorized, getUnauthorizedMsgCasting } from '../services/errorHandlingService';

const { Option } = Select;

let { info } = Modal;

export default class ConfirmSignUpForm extends Component {

  confirmInfoContinuation = (props) => {
    info  = info({
      title: 'One more question.',
      okText: 'Save',
      okButtonProps: {
        disabled: true,
      },
      content: (
        <WrapConfirmForm props={props}/>
      ),
      onOk() {
        // TODO: Rest call, make backend profile route except anything
        const userId = cookieParser(CONST.COOKIE.USER_ID);
        const userType = props.selected;
        // console.log('Props: ', props);
        restCall('/profile/update-type', CONST.REST_TYPE.POST, {userId, type: userType})
          .then(res => res.json())
          .then(data => {
            // Upon 401
            if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
              return errorHandleUnauthorized(message, data.msg);

            if (data && data.success) {
              if (data.replaced) {
                props.setState({type: props.selected});
              }
            }
          })
          .catch(err => console.log('Type Update Err: ', err));
      }
    });  
  }

  render() {
    const {getFieldDecorator} = this.props.form;

    return <Form>
              <Form.Item
                label="Select your title"
                required={true}
                hasFeedback>
                {getFieldDecorator('select', {
                  rules: [{ required: true, message: 'Please select your title (Agent, Lender, Title, Escrow).' }],
                })(
                  <Select
                    placeholder="Please select your title"
                    onChange={selected => {
                      this.props.props.selected = selected;
                      info.update({
                        okButtonProps: {
                          disabled: false,
                        }
                      })
                    }}>
                    <Option value="buyer">Buyer</Option>
                    <Option value="seller">Seller</Option>
                    <Option value="agent">Agent</Option>
                    <Option value="lender">Lender</Option>
                    <Option value="title">Title</Option>
                    <Option value="escrow">Escrow</Option>
                  </Select>,
                )}
              </Form.Item>
            </Form>
  }
}

const WrapConfirmForm = Form.create({ name: 'confirm_form'})(ConfirmSignUpForm);
