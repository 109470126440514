import React, { Component } from 'react';
import EditPropertyContainer from '../containers/EditPropertyContainer';

class EditProperty extends Component {
  constructor(props) {
    super();
    // console.log('EditProp Props: ', props)
  }

  render() {
    return  <div>
              <EditPropertyContainer
                {...this.props}
                setEditTempPropertyState={this.props.setEditTempPropertyState}/>
            </div>
  }
}

export default EditProperty;