import React, { Component } from 'react';
import { Form, Input, Icon } from 'antd';

// Contains only form/input and value with validation. Parent component will contain REST call
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {email: ''};
  }

  render () {
    const { getFieldDecorator, validateFields } = this.props.form;
    return <Form>
            <p
              style={{marginBottom: '15px'}}>
              Please enter the email associated with your account and we will send you further instructions in order to reset your password.
            </p>
            <Form.Item
              hasFeedback>
              {getFieldDecorator('email', {
                initialValue: '',
                rules: [
                  {
                    type: 'email',
                    message: 'Input a valid e-mail'
                  },
                  {
                    required: true,
                    message: 'Input your e-mail',
                    validator: (rule, value, cb) => {
                      if (value === '' || value === undefined) {
                        return setTimeout(() => cb(false), 1000);
                      }
                      setTimeout(() => cb(), 1000);
                    },
                  },
                ]
              })(<Input
                    prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="email"
                    placeholder="E-mail"
                    onChange={e => {
                      // Set email, validate and then set forgetPasswordEmail
                      this.setState({email: e.target.value}, () => {
                        validateFields(['email'], (err) => {
                          if (!err) {
                            this.props.setValues({isSendDisabled: false, forgetPasswordEmail: this.state.email});
                          } else {
                            this.props.setValues({isSendDisabled: true, forgetPasswordEmail: ''});
                          }
                        })
                      });
                    }}/>)}
              </Form.Item>
          </Form>;
  };
}

const WrapForgotPassword = Form.create({ name: 'forgot_password' })(ForgotPassword);

export default WrapForgotPassword;
