import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { restCall } from '../../services/restService';
import { Form, Icon, Input, Button, Row, Col, Collapse, message } from 'antd';
import { CONST } from '../../constant/const';
import { cookieParser, emailValidation, roleTypesArr } from '../../services/utilsService';
import '../../styles/auth.css';

const { Panel } = Collapse;

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      firstname: '',
      lastname: '',
      userUrl: '',
      accessCode: '',
      loading: false,
      redirect: false,
      isInvite: this.props.isInvite,
      isAccessExpanded: false
    };
  }

  componentDidMount() {
    if (this.state.isInvite)
      this.setState({email: this.props.match.params.email, type: this.props.match.params.type, propId: this.props.match.params.propId});

    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams && urlParams.get('sign_up') && urlParams.get('access_code'))
      this.setState({accessCode: urlParams.get('access_code').toUpperCase(), isAccessExpanded: true});
  }

  initSignUp() {
    const signUpObj = {firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        email: this.state.email,
                        password: this.state.password,
                        accessCode: this.state.accessCode};

    restCall('/sign-up/', CONST.REST_TYPE.POST, signUpObj)
    .then(res => res.json())
    .then(data => {
      if (data.success)
        return setTimeout(() => this.setState({redirect: true, userUrl: cookieParser(CONST.COOKIE.USER_URL), loading: false}),CONST.LOAD_TIME);
      if (!data.success)
        message.error(data.msg, CONST.INTS.MESSAGE_DURATION);
      setTimeout(() => this.setState({loading: false}),CONST.LOAD_TIME);
    })
    .catch(err => {
      console.log('Err: ', err)
      setTimeout(() => this.setState({loading: false}),CONST.LOAD_TIME)
    });
  }

  initInvite() {
    //  Check if propId exists, email is valid and role type is valid
    const hasPropId = this.state.propId ? true : false;
    if (!emailValidation(this.state.email) || roleTypesArr.indexOf(this.state.type) === -1 || !hasPropId)
      return this.setState({loading: false}); // TODO: notifiy email, type or propId is not valid

    const inviteObj = {firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        email: this.state.email,
                        password: this.state.password,
                        propId: this.state.propId,
                        type: this.state.type,
                        accessCode: ''};

    restCall('/invite/', CONST.REST_TYPE.POST, inviteObj)
    .then(res => res.json())
    .then(data => {
      if (data.success)
        return setTimeout(() => this.setState({redirect: true, userUrl: cookieParser(CONST.COOKIE.USER_URL), loading: false}),CONST.LOAD_TIME);
      setTimeout(() => this.setState({loading: false}),CONST.LOAD_TIME);
    })
    .catch(err => {
      console.log('Err: ', err)
      setTimeout(() => this.setState({loading: false}),CONST.LOAD_TIME)
    });
  }

  render () {
    if (this.state.redirect)
    return <Redirect push to={{ pathname: '/dashboard/' + this.state.userUrl + '/properties',
            state: {userUrl: this.state.userUrl, menuLabel: 'Properties'} }} />;
    const { getFieldDecorator } = this.props.form;
    return <Form>
            <Row
              type="flex"
              justify="space-between">
              <Col
                span={24}
                sm={11}>
                <Form.Item
                  hasFeedback>
                  {getFieldDecorator('firstname', {
                    rules: [
                      {
                        type: 'string',
                        message: 'Input a first name'
                      },
                      {
                        required: true,
                        message: 'Input your first name',
                      },
                    ],
                    setFieldsValue: this.state.firstname
                  })(<Input
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text"
                        placeholder="First Name"
                        onChange={e => this.setState({firstname: e.target.value})}/>)}
                  </Form.Item>
              </Col>
              <Col
                span={24}
                sm={11}>
              <Form.Item
                  hasFeedback>
                  {getFieldDecorator('lastname', {
                    rules: [
                      {
                        type: 'string',
                        message: 'Input your last name'
                      },
                      {
                        required: true,
                        message: 'Input your last name',
                      },
                    ],
                    setFieldsValue: this.state.lastname
                  })(<Input
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text"
                        placeholder="Last Name"
                        onChange={e => this.setState({lastname: e.target.value})}/>)}
                  </Form.Item>
              </Col>
            </Row>
            { !this.state.isInvite &&
              <Form.Item
                hasFeedback>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: 'Input your e-mail'
                    },
                    {
                      required: true,
                      message: 'Input your e-mail',
                      validator: (rule, value, cb) => {
                        if (value === '' || value === undefined)
                          return setTimeout(() => cb(false), 1000);
                        setTimeout(() => cb(), 1000);
                      },
                    },
                  ]
                })(<Input
                      prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="E-mail"
                      onChange={e => this.setState({email: e.target.value})}/>)}
              </Form.Item>
            }
            <Form.Item
              required={true}
              extra="Password must be a minimum of 8 characters and at least one digit and capital letter"
              hasFeedback>
              {getFieldDecorator('password', {
                rules: [
                  { 
                    required: true,
                    message: 'Input a valid password',
                    validator: (rule, value, cb) => {
                      const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[0-9a-zA-Z][\w~@#$%^&*+=`|\\{}:;!.?"()[\]-]{7,}$/; // Non special char
                      if (re.test(value)) {
                        return setTimeout(() => cb(), 1000);
                      } else {
                        return setTimeout(() => cb(false), 1000);
                      }
                    }
                  }
                ],
              })(
                <Input.Password
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Password"
                  onChange={e => this.setState({password: e.target.value})}/>,
              )}
            </Form.Item>
            
            { !this.state.isInvite &&
              <Collapse
                className="pf-auth-collapse"
                activeKey={this.state.isAccessExpanded ? '1' : ''}
                onChange={() => this.setState({isAccessExpanded: !this.state.isAccessExpanded})}
                bordered={false}
                expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0}/>}>
                  <Panel
                    header="Access code"
                    forceRender={true}
                    key="1">
                    <Form.Item
                      style={{marginBottom: 0}}
                      extra="If you have an access code you can enter it now">
                      {getFieldDecorator('code', {
                        initialValue: this.state.accessCode
                      })(<Input
                            type="text"
                            placeholder="Access Code"
                            onChange={e => this.setState({accessCode: e.target.value})}/>)}
                    </Form.Item>
                  </Panel>
              </Collapse>
            }

            <Form.Item
            style={{marginBottom: 0}}>
              <Button 
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={this.state.loading}
                onClick={() => {
                  this.setState({loading: true});
                  this.props.form.validateFields((err, values) => {
                    if (!err) {
                      if (!this.state.isInvite)
                        return this.initSignUp();
                      return this.initInvite();
                    }

                    this.setState({loading: false});
                  })
                }}
                block>
                {this.state.isInvite ? 'Continue' : 'Sign up'}
              </Button>
            </Form.Item>
          </Form>;
  };
}

const WrapSignUp = Form.create({ name: 'signup_reg' })(SignUp);

export default WrapSignUp;