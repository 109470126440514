export const CONST = {
  LOAD_TIME: 300,
  REST_TYPE: {
    GET: 'GET',
    POST: 'POST',
    PATCH: 'PATCH',
    PUT: 'PUT',
    DELETE: 'DELETE'
  },
  COOKIE: {
    USER_URL: 'USER_URL',
    USER_ID: 'USER_ID',
    USER_TYPE: 'USER_TYPE'
  },
  TITLES: {
    ADD_PROPERTY: 'Add Property',
    PROPERTIES: 'Properties',
    MESSAGES: 'Messages',
    TEAM: 'Team',
    SETTINGS: 'Settings',
    ADD_MEMBER: 'Add Member',
    NOTIFICATIONS: 'Notifications',
    ADMIN_PORTAL: 'Admin Portal',
    TEMPLATES: 'Templates'
  },
  ROLE_TYPE: {
    AGENT: 'agent',
    LENDER: 'lender',
    TITLE: 'title',
    ESCROW: 'escrow',
    SELLER: 'seller',
    BUYER: 'buyer'
  },
  VALIDATION_STATES: {
    SUCCESS: 'success',
    ERROR: 'error'
  },
  UPLOAD: {
    STATUS: {
      DONE: 'done',
      REMOVED: 'removed'
    }
  },
  INTS: {
    MAX_EMAIL_PAGE: 20,
    MESSAGE_DURATION: 7,
    NOTIFICATION_DURATION: 10,
    NOTIFICATION_DURATION_MAX: 20,
    ERROR_CODE_BAD_TOKEN: 401,
    ERROR_CODE_BAD_REQUEST: 400
  },
  GENERAL: {
    LABELS: {
      NA: 'n/a'
    }
  },
  PROPERTY_DETAILS: {
    GENERAL: {
      LINK: 'link'
    }
  },
  ERROR_TYPE: {
    MAX_SIGNIN: 'max signin'
  },
  EVENTS: {
    NOTIFICATION: 'notification',
    MESSAGE: 'message'
  }
};
