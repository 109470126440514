import { CONST } from '../constant/const';

export const uploadClientSide = (signedUrl, fileEvent) => {

  const xhr = new XMLHttpRequest();

  return new Promise(resolve => {
    xhr.onerror = e => console.log('uploadClientSide Err: ', e);

    xhr.upload.addEventListener('progress', e => {
      fileEvent.onProgress({ percent: parseInt(Math.round((e.loaded / e.total) * 100).toFixed(2)) }, fileEvent.file);
    });

    xhr.onreadystatechange = e => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status >= 200 && xhr.status <= 299) {
          fileEvent.file.url = e.target.responseURL.split('?')[0];
          fileEvent.onSuccess(e, fileEvent.file);
          resolve(fileEvent.file); // Just returning url of upload for now
        } else {
          console.log('uploadClientSide Err: ', xhr.status, e);
        }
      }
    }

    xhr.open(CONST.REST_TYPE.PUT, signedUrl);
    xhr.setRequestHeader('x-amz-acl', 'public-read');
    xhr.setRequestHeader('Content-Type', fileEvent.file.type);
    xhr.send(fileEvent.file);
  });
}
