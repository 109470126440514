import React, { Component } from 'react';
import SignUp from '../../components/AuthReg/SignUp';
import { Row, Col, Card } from 'antd';
import './auth-reg-container.css';

class RegInviteContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {tabkey: 'invite'};
    this.tabList = [{key: 'invite', tab: 'Invite'}];
    this.tabContent = {invite: <SignUp isInvite={true} {...props}/>};
  }
  render () {
    return <Row
          type="flex"
          justify="center"
          align="middle">
          <Col
            span={24}
            md={10}
            lg={7}>
            <div
              className="pf-auth-wrap">
              <img className="pf-auth-logo" src={require('../../img/logo-horizontal.svg')} alt="PropertyFlo horizontal logo"/>
              <Card
                className="auth-reg"
                style={{ width: '100%' }}
                tabList={this.tabList}
                activeTabKey={this.state.tabkey}
                onTabChange={key => this.setState({tabkey: key})}>
                {this.tabContent[this.state.tabkey]}
              </Card>
            </div>
          </Col>
        </Row>;
  };
}

export default RegInviteContainer;