import React, { Component } from 'react';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import { Col, Row, Icon, Statistic, Card, Tabs, Progress, Tag, Divider, Button, Modal, message, Table } from 'antd';
import { restCall } from '../services/restService';
import { CONST } from '../constant/const';
import { cookieParser, capValue } from '../services/utilsService';
import { errorHandleUnauthorized, getUnauthorizedMsgCasting } from '../services/errorHandlingService';
import { addTaskColumnsArr } from '../services/templateService';
import { WrappedPropertyAddTask } from './PropertyTaskComponents/properytyAddTask';
import { WrappedMessageNew } from './messageNew';
import EditProperty from './EditProperty';
import PropertyTask from './PropertyTaskComponents/propertyTask';
import { PropertyDetails } from './PropertyDetails';
import '../styles/property.css';

const { TabPane } = Tabs;
const { confirm } = Modal;

const confirmDelete = (id, ownerEditor, roles, updateState) => {
  confirm({
    title: 'Are you sure you want to delete this property?',
    content: 'Once this property has been deleted it and it\'s items cannot be retrieved for all parties associated.',
    okType: 'danger',
    okText: 'Delete',
    maskClosable: true,
    onOk() {
      return new Promise(resolve => {
        restCall(`/property/mark-for-delete/${id}`, CONST.REST_TYPE.POST, {ownerEditor: ownerEditor, roles: roles})
                .then(res => res.json())
                .then(data => {
                  // Upon 401
                  if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                    return errorHandleUnauthorized(message, data.msg);

                  if (data && data.success) {
                    resolve(data);
                    if (data.arr.length)
                      updateState({deleteConfirmed: true}); // deleteConfirmed will render redirect to properties
                  }
                })
                .catch(err => console.log(`/property/mark-for-delete/${id} Err: `, err))
              });
              
    }
  });
}

class Property extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: true,
      property: null,
      isEditPropertyModalVisible: false,
      isMessageSellerBuyerModalVisible: false,
      isEditSaveDisabled: false,
      canEdit: false,
      messageType: '',
      isMessageDisabled: true,
      deleteConfirmed: false,
      addTemplatedTask: {isModalVisible: false, isLoading: false, taskRecordArr: [], propTypeIdx: null, propTypeId: null},
      myTasksColumns: [],
      myTasksTableData: [],
      myTasksPagination: {current: 1},
      isMyTaskTemplatesLoading: false
    };
    this.username = cookieParser(CONST.COOKIE.USER_URL); // Get username to keep url intact
    this.userType = cookieParser(CONST.COOKIE.USER_TYPE);
    this.userId = cookieParser(CONST.COOKIE.USER_ID);

    this.editTempOldState = null;

    this.createNewTasks = this.createNewTasks.bind(this);
    this.removeClientSideTask = this.removeClientSideTask.bind(this);
    this.setAddTaskState = this.setAddTaskState.bind(this);
    this.markTaskAsComplete = this.markTaskAsComplete.bind(this);
    this.sortTasks = this.sortTasks.bind(this);
    this.updateTasksArr = this.updateTasksArr.bind(this);
    this.setEditTempPropertyState = this.setEditTempPropertyState.bind(this);
    this.updateEditPropertyState = this.updateEditPropertyState.bind(this);
    this.setAddMessageState = this.setAddMessageState.bind(this);
    this.updateState = this.updateState.bind(this);
    this.getMyTaskTemplates = this.getMyTaskTemplates.bind(this);
    this.handleMyTaskTableChange = this.handleMyTaskTableChange.bind(this);
    this.addTaskRowSelection = this.addTaskRowSelection.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount() {
    // Upon redirect of new property, scroll to top
    if (window.scrollY > 0)
      window.scrollTo({top: 0, behavior: 'smooth'});
    restCall(`/property/get-property/${this.props.match.params.id}`, CONST.REST_TYPE.GET)
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && data.success) {

          if (data.propertyTypes) {
            // Go through tasks and sort before displaying and set new task for propType
            data.propertyTypes.forEach(propType => {
              if (propType && propType.tasks)
                this.sortTasks(propType.tasks);
              if (propType && propType.isTaskMaker)
                this.setState({[`new${capValue(propType.type)}Task`]: {}});
            });
          }

          if (data.ownerEditor.indexOf(cookieParser(CONST.COOKIE.USER_ID)) >= 0)
            this.setState({canEdit: true});

          setTimeout(() => this.setState({property: data, loading: false}),500);
        }
      })
      .catch(err => console.log('ProfileType Err: ', err));
    const updatedRender = date => moment(date).format('MMMM, D, YYYY h:mma');
    this.addTaskColumnsArr = addTaskColumnsArr(updatedRender);
    this.setState({myTasksColumns: this.addTaskColumnsArr});
  }

  updateState(stateObj) {
    this.setState(stateObj);
  }

  updateTasksArr(propTypeIdx, taskIdx, taskObj) {
    this.setState(prevState => {
      let tempTaskObj = {
        ...prevState,
        propertyTypes: [...prevState.property.propertyTypes]
      }
      // Replace object in array with updated object
      tempTaskObj.propertyTypes[propTypeIdx].tasks[taskIdx] = taskObj;
      return tempTaskObj;
    })
  }

  createNewTasks(propTypeId, propTypeIdx, type, resetFields, isTemplatedTask) {
    // Init call in order to do a check against newly created task state, for date and eventually upload (resolves possible issue with edit mode)
    // Get buyer or sellers userId in order to send notification

    isTemplatedTask = isTemplatedTask || false; // Assign isTemplatedTask

    // Add order number to task.. If templated, send count and add on backend
    let taskHighestCount = this.getHighestOrderNumber(this.state.property.propertyTypes[propTypeIdx].tasks);
    if (!isTemplatedTask) {
      const newTaskObj = Object.assign(this.state[`new${capValue(type)}Task`]);
      newTaskObj.order = ++taskHighestCount; // Increment from task count
      this.setState({[`new${capValue(type)}Task`]: newTaskObj});
    }

    const buyerSellerRole = this.state.property.roles.seller.length > 0 ? CONST.ROLE_TYPE.SELLER : CONST.ROLE_TYPE.BUYER;
    const buyerSellerId = this.state.property.roles[buyerSellerRole][0]; // converts to userId on notifications..
    const taskPayloadArr = isTemplatedTask ? this.state.addTemplatedTask.taskRecordArr : [this.state[`new${capValue(type)}Task`]];

    const initRestCall = () => restCall('/task/create-task/', CONST.REST_TYPE.POST, {propTypeId, type, buyerSellerId, propId: this.state.property.id, payload: taskPayloadArr, isTemplatedTask: isTemplatedTask || null, taskHighestCount: taskHighestCount})
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && data.success) {
          const tasksArr = data.tasks;
          this.setState(prevState => {
            let tempTaskObj = {
              ...prevState,
              propertyTypes: [...prevState.property.propertyTypes],
              [`new${capValue(type)}Task`]: {task: '', notes: '', date: '', uploads: []}
            }

            if (isTemplatedTask) // Reset addTemplatedTask
              tempTaskObj.addTemplatedTask = {isModalVisible: false, isLoading: false, taskRecordArr: [], propTypeIdx: null, propTypeId: null};
            // Tally up taskcount
            tempTaskObj.propertyTypes[propTypeIdx].taskCount = tasksArr.length + tempTaskObj.propertyTypes[propTypeIdx].taskCount;
            // Add tasks to tasks array..
            tasksArr.forEach(obj => {
              tempTaskObj.propertyTypes[propTypeIdx].tasks.push(obj); // Replace object in array with updated object
            });
            // Sort tasks based upon complete and updated
            tempTaskObj.propertyTypes[propTypeIdx].tasks = this.sortTasks(tempTaskObj.propertyTypes[propTypeIdx].tasks);
            return tempTaskObj;
          }, () => {
            if (resetFields) // If templated, no need for reset
              resetFields()
          });// and then reset create task fields..
        }
      })
      .catch(err => console.log('task/create-task Task Error: ', err))

    // Check against state for dueDate and eventually uploads as well
    if (!this.state[`new${capValue(type)}Task`].dueDate || this.state[`new${capValue(type)}Task`].dueDate === "Invalid date")
      return this.setState({[`new${capValue(type)}Task`]: {...this.state[`new${capValue(type)}Task`], dueDate: null}}, () => initRestCall());
    initRestCall();
  }

  removeClientSideTask(propTypeIdx, taskIdx, isTaskComplete) {
    this.setState(prevState => {
      let tempTaskObj = {
        ...prevState,
        propertyTypes: [...prevState.property.propertyTypes]
      }
      // Decrement taskComplete if needed
      if (isTaskComplete)
        tempTaskObj.propertyTypes[propTypeIdx].tasksComplete = --tempTaskObj.propertyTypes[propTypeIdx].tasksComplete;
      // Decrement task count
      tempTaskObj.propertyTypes[propTypeIdx].taskCount = --tempTaskObj.propertyTypes[propTypeIdx].taskCount;
      // Remove task from array
      tempTaskObj.propertyTypes[propTypeIdx].tasks.splice(taskIdx, 1);
      return tempTaskObj;
    });
  }

  setAddTaskState(type, value) {
    this.setState({[`new${capValue(type)}Task`]: {...this.state[`new${capValue(type)}Task`], ...value}});
  }

  // Sets state in Property.js from AddEditPropertyProvider.js in order to save correct edit data when save is initiated
  setEditTempPropertyState(state) {
    // Check old state to new state for changes
    // Chachkies type fix in order to bypass component not mounted error and resolve unecessary state setting
    if (this.editTempOldState === null)
      this.editTempOldState = state;
    if (JSON.stringify(state) !== JSON.stringify(this.editTempOldState)) {
      const tempEditStateObj = Object.assign({}, state);
      this.setState({tempEditState: tempEditStateObj, isEditSaveDisabled: tempEditStateObj.isSaveDisabled});
    }
    this.editTempOldState = state;
  }

  // TODO: Upon success update, update client side property
  // Saves when editing property state
  updateEditPropertyState() {
    // Client side check if owner/editor
    if (this.state.property.ownerEditor.indexOf(cookieParser(CONST.COOKIE.USER_ID)) < -1)
      return;

    let originalPropertyState = Object.assign({}, this.state.property);
    let tempEditState = Object.assign({}, this.state.tempEditState);
    // Check to see if property details have been edited
    tempEditState.hasPropertyDetailsBeenEdited = this.hasPropertyDetailsBeenEdited(originalPropertyState, tempEditState);

    // TODO: Build new property object.. if new invites.., update state upon success
    // Check to see if property details have been edited and/or invites have been added
    if (!tempEditState.hasPropertyDetailsBeenEdited && !tempEditState.newInviteArr.length > 0) {
      this.setState({isEditPropertyModalVisible: false, loading: false});
      return;
    }
    delete tempEditState.propertyId;
    delete tempEditState.isEdit;
    delete tempEditState.isLoading;
    delete tempEditState.isSaveDisabled;
    delete tempEditState.redirect;
    delete tempEditState.activeRolesArr;
    delete tempEditState.roles;
    restCall(`/property/update-property/${this.props.match.params.id}`, CONST.REST_TYPE.POST, tempEditState)
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && data.success) {
          message.success('Your property has been updated successfully');
            // If no invites can just setState on propery
          if (tempEditState.newInviteArr.length <= 0) {
            this.setState(prevState => {
              let tempPropertyObj = {
                ...prevState,
                loading: false,
                isEditPropertyModalVisible: false,
                property: {...prevState.property}
              }
              // Replace object in array with updated object
              tempPropertyObj.property.propertyName = data.propertyName;
              tempPropertyObj.property.basicDetails = data.basicDetails;
              tempPropertyObj.property.factsFeatures = data.factsFeatures;
              tempPropertyObj.property.updated = data.updated;
              tempPropertyObj.property.emails = data.emails ? data.emails : {...prevState.property.emails};
              tempPropertyObj.property.roles = data.roles ? data.roles : {...prevState.property.roles};
              return tempPropertyObj;
            });
          }
          // TODO: put some where, done twice, here an above at componentDidMount
          // Otherwise, if invite/s, getAndBuildProperty with propTypes and tasks (could change in future, full property fetch not needed but okay for now)
          if (tempEditState.newInviteArr.length > 0) {
            setTimeout(() => this.setState({property: data, loading: false, isEditPropertyModalVisible: false,}),500);
            if (data.propertyTypes)
              data.propertyTypes.forEach(propType => {
                if (propType && propType.isTaskMaker)
                  this.setState({[`new${capValue(propType.type)}Task`]: {}});
              });
            if (data.ownerEditor.indexOf(cookieParser(CONST.COOKIE.USER_ID)) >= 0)
              this.setState({canEdit: true});
          }
        }
      })
      .catch(err => {
        message.error('Something went wrong, please try back later');
        return 'Update Res Err: ' + err;
      });
  }

  // Check to see if property details have been edited upon update
  hasPropertyDetailsBeenEdited(originalState, editedState) {
    const arrObjProperties = ['propertyName', 'basicDetails', 'factsFeatures'];
    let hasPropertyDetailsEdited = false;
    arrObjProperties.forEach(prop => {
      if (JSON.stringify(originalState[prop]) !== JSON.stringify(editedState[prop]))
        hasPropertyDetailsEdited = true;
    });
    return hasPropertyDetailsEdited;
  }

  markTaskAsComplete(id, propTypeIdx, taskIdx, type, isTaskComplete) {

    const IS_TASK_COMPLETE = !isTaskComplete;
    // Notification for mark as complete
    let notificationObj = {propId: this.state.property.id};
    
    // If seller or buyer then get type userId
    if (this.userType === CONST.ROLE_TYPE.SELLER || this.userType === CONST.ROLE_TYPE.BUYER) {
      notificationObj.userId = this.state.property.roles[type][0];
      notificationObj.type = this.state.property.roles.seller.length > 0 ? CONST.ROLE_TYPE.SELLER : CONST.ROLE_TYPE.BUYER;;
    } else { // Otherwise, get seller or buyers userId and type that marked task
      const buyerSellerRole = this.state.property.roles.seller.length > 0 ? CONST.ROLE_TYPE.SELLER : CONST.ROLE_TYPE.BUYER;
      notificationObj.userId = this.state.property.roles[buyerSellerRole][0];
      notificationObj.type = type;
    }

    restCall(`/task/update-task/${id}`, CONST.REST_TYPE.POST, {notificationObj, payload: {isTaskComplete: IS_TASK_COMPLETE}})
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && data.success) {
          // Upon success, set correct state
          // SetState on correct propType including correct task
          this.setState(prevState => {
            let tempTaskObj = {
              ...prevState,
              propertyTypes: [...prevState.property.propertyTypes]
            }
            tempTaskObj.propertyTypes[propTypeIdx].tasks[taskIdx] = data.task; // Replace object in array with updated object
            // Tally up complete tasks
            tempTaskObj.propertyTypes[propTypeIdx].tasksComplete = IS_TASK_COMPLETE ? ++tempTaskObj.propertyTypes[propTypeIdx].tasksComplete : --tempTaskObj.propertyTypes[propTypeIdx].tasksComplete;
            // Sort tasks based upon complete and updated
            // (Sorting turned off currently when marked complete)
            // tempTaskObj.propertyTypes[propTypeIdx].tasks = this.sortTasks(tempTaskObj.propertyTypes[propTypeIdx].tasks);
            return tempTaskObj;
          })
        }
      })
      .catch(err => console.log('markTaskAsComplete Error: ', err))
  }

  sortTasks(tasksArr) {
    return tasksArr.sort((a,b) => a.order - b.order);
  }

  getMessageLink() {
    const styleObj = this.state.canEdit ? {marginRight: '15px', paddingRight: '15px', borderRight: '1px solid #e8e8e8'} : {};
    return <Link
            to=""
            size="small"
            style={styleObj}
            onClick={e => {
              e.preventDefault();
              const buyerSellerRole = this.state.property.roles.seller.length > 0 ? CONST.ROLE_TYPE.SELLER : CONST.ROLE_TYPE.BUYER;
              this.setState({isMessageSellerBuyerModalVisible: true, messageType: buyerSellerRole});
            }}>
              <Icon type="message"/> Message {capValue(this.state.property.roles[CONST.ROLE_TYPE.BUYER].length > 0 ? CONST.ROLE_TYPE.BUYER : CONST.ROLE_TYPE.SELLER)}, <small>({this.state.property.names[this.state.property.roles[CONST.ROLE_TYPE.BUYER].length > 0 ? CONST.ROLE_TYPE.BUYER : CONST.ROLE_TYPE.SELLER].name})</small>
          </Link>
  }

  setAddMessageState(valid, value) {
    if (valid !== null)
      this.setState({isMessageDisabled: !valid, messageNew: {...this.state.messageNew, ...value}});
    this.setState({messageNew: {...this.state.messageNew, ...value}});
  }

  sendMessage() {
    if (this.state.messageNew && this.state.messageNew.subject.length > 0) {
      const messageObj = {subject: this.state.messageNew.subject, 
                          message: this.state.messageNew.message ? this.state.messageNew.message : '',
                          recId: this.state.property.roles[this.state.messageType][0],
                          recRole: this.state.messageType,
                          sendRole: cookieParser(CONST.COOKIE.USER_TYPE),
                          propId: this.state.property.id};
      restCall('/messages/create-message', CONST.REST_TYPE.POST, messageObj)
        .then(res => res.json())
        .then(data => {
          // Upon 401
          if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
            return errorHandleUnauthorized(message, data.msg);

          if (data && data.success) {
            message.success('Your message has been sent successfully');
            this.setState({loading: false, isMessageSellerBuyerModalVisible: false, isMessageDisabled: true});
          }
        })
        .catch(err => {
          message.error('Something went wrong, please try back later');
          return 'Create Message Err: ' + err
        })
    }
  }

  getMyTaskTemplates() {
    this.setState({isMyTaskTemplatesLoading: true});
    restCall(`/template/get-my-task-templates/${this.userId}/${this.state.myTasksPagination.current}`, CONST.REST_TYPE.GET)
      .then(res => res.json())
      .then(result => {
        // Upon 401
        if (result && !result.success && result.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, result.msg);

        if (result && result.success)
          this.setState({myTasksTableData: result.data.tasks, isMyTaskTemplatesLoading: false, myTasksPagination: {current: this.state.myTasksPagination.current, total: result.data.count}});
      })
      .catch(err => '/template/get-task-templates Err: ' + err);
  }

  handleMyTaskTableChange(pagination) {
    this.setState({myTasksPagination: pagination},() => this.getMyTaskTemplates());
  }

  addTaskRowSelection() {
    return {
      selectedRowKeys: this.state.addTemplatedTask.taskRecordArr,
      onChange: (selectedRowKeys) => {
        this.setState({addTemplatedTask: {...this.state.addTemplatedTask, taskRecordArr: selectedRowKeys}})
      }
    }
  }

  // Task ordering function
  getHighestOrderNumber(arr) {
    let highestOrderNumber = 0;
    arr.forEach(task => {
      if (highestOrderNumber < task.order)
        highestOrderNumber = task.order;
    });
    return highestOrderNumber;
  }

  // DND
  onDragOver(e) {
    e.preventDefault();
  }

  onDrop(e, propTypeIdx) {
    const subjectOrder = parseInt(e.dataTransfer.getData("order"));
    let parentNode = e.target;

    if (!subjectOrder) // Check
      return;

    while (parentNode.dataset && !parentNode.dataset.order) {
      parentNode = parentNode.parentNode;
    }

    if (!parentNode.dataset || !parentNode.dataset.order) // Check
      return;

    // Get the target index (the index of the drop container)
    const targetOrder = parseInt(parentNode.dataset.order);

    let tasksArr = Object.assign([], this.state.property.propertyTypes[propTypeIdx].tasks);
    let updatedOrderArr = [];
    let targetId = null;

    // Change targetOrder based upon subjectOrder, up or down
    tasksArr.forEach(task => {
      if (task.order === targetOrder) {
        task.order = targetOrder > subjectOrder ? targetOrder - 1 : targetOrder + 1;
        targetId = task.id;
        updatedOrderArr.push({ id: task.id, order: task.order });
      }
    });

    // Change subject to target
    tasksArr.forEach(task => {
      if (task.order === subjectOrder && task.id !== targetId) {
        task.order = targetOrder;
        updatedOrderArr.push({ id: task.id, order: task.order });
      }
    });

    this.sortTasks(tasksArr); //Sort arr before checking for dupes

    // Check for dupes, change order again if needed
    for (let i = 0; i < tasksArr.length - 1; i++) {
      if (tasksArr[i].order === tasksArr[i + 1].order) {
        tasksArr[i + 1].order++;
        // Check if exists in updateOrderArr
        if (updatedOrderArr.some(orderItem => orderItem.id === tasksArr[i + 1].id))
          updatedOrderArr.forEach(oItem => { // True, loop and update order
            if (oItem.id === tasksArr[i + 1].id)
              oItem.order = tasksArr[i + 1].order
          });
        else // Add to array
          updatedOrderArr.push({id: tasksArr[i + 1].id, order: tasksArr[i + 1].order}); // Suspect, check this logic w/ all the incrementing...
      }
    }

    // Update backend to pertain change
    this.updateTaskOrder(updatedOrderArr);

    this.setState(prevState => {
      let tempTaskObj = {
        ...prevState,
        propertyTypes: [...prevState.property.propertyTypes]
      }
      tempTaskObj.propertyTypes[propTypeIdx].tasks = this.sortTasks(tasksArr);
      return tempTaskObj;
    });
  }

  updateTaskOrder(updatedOrderArr) {
    restCall('/task/update-task-order/', CONST.REST_TYPE.POST, {payload: updatedOrderArr})
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && data.success) {
          // Currently do nothing
        }
      })
      .catch(err => console.log('markTaskAsComplete Error: ', err))
  }



  render() {
    // Chachke, currently name is dependent upon url. Therefore, a redirect in place when name is changed
    if (this.state.property && this.state.property.propertyName !== this.props.match.params.name)
      return <Redirect push to={{pathname: `/dashboard/${cookieParser(CONST.COOKIE.USER_URL)}/property/${this.state.property.id}/${this.state.property.propertyName}`}}/>;
    
    if (this.state.deleteConfirmed)
      return <Redirect push to={{pathname: `/dashboard/${cookieParser(CONST.COOKIE.USER_URL)}/properties`}}/>;

    return <div>
            {/* Edit property modal */}
            <Modal
              title={`Edit ${this.state.property ? ' | ' + this.state.property.propertyName : ''}`}
              className="pf-modal"
              destroyOnClose={true}
              visible={this.state.isEditPropertyModalVisible}
              okText="Update"
              okButtonProps={{disabled: this.state.isEditSaveDisabled}}
              confirmLoading={this.state.loading}
              onOk={() => {
                this.setState({loading: true});
                this.updateEditPropertyState();
              }}
              onCancel={() => { 
                this.setState({isEditPropertyModalVisible: false})
              }}>
                <EditProperty
                  {...this.state}
                  setEditTempPropertyState={this.setEditTempPropertyState}/>
            </Modal>

            {/* Message modal */}
            <Modal
              title={`Message ${capValue(this.state.messageType)}`}
              className="pf-modal"
              destroyOnClose={true}
              visible={this.state.isMessageSellerBuyerModalVisible}
              okText="Send"
              okButtonProps={{disabled: this.state.isMessageDisabled}}
              confirmLoading={this.state.loading}
              onOk={() => {
                this.setState({loading: true});
                this.sendMessage();
              }}
              onCancel={() => { 
                if (this.state.messageNew)
                  return this.setState({isMessageSellerBuyerModalVisible: false, messageNew: {}})
                this.setState({isMessageSellerBuyerModalVisible: false})
              }}>
                {/* Message Component in here.. */}
                <WrappedMessageNew
                  {...this.state}
                  setAddMessageState={this.setAddMessageState}/>
            </Modal>

            {/* Templated tasks modal TODO: complete this.., adding templated tasks to property */}
            <Modal
              title={'My Tasks'}
              className="pf-modal"
              destroyOnClose={true}
              visible={this.state.addTemplatedTask.isModalVisible}
              okText="Add"
              okButtonProps={{disabled: !this.state.addTemplatedTask.taskRecordArr.length}}
              confirmLoading={this.state.addTemplatedTask.isLoading}
              onOk={() => {
                this.setState({addTemplatedTask: {...this.state.addTemplatedTask, isLoading: true},
                  myTasksPagination: {current: 1}});
                const propTypeId = this.state.addTemplatedTask.propTypeId;
                const propTypeIdx = this.state.addTemplatedTask.propTypeIdx;
                const isTemplatedTask = true;
                this.createNewTasks(propTypeId, propTypeIdx, this.userType, null, isTemplatedTask);
              }}
              onCancel={() => {
                this.setState({
                  addTemplatedTask: {...this.state.addTemplatedTask, taskRecordArr: [], isModalVisible: false},
                  myTasksPagination: {current: 1}
                });
              }}>
                {/* My Task table.. */}
                <Card
                  className="pf-general-text-block"
                  bordered={false}>
                    <p
                      style={{marginBottom: '0px'}}>
                      Select task/s and add in order to add to property.
                    </p>
                </Card>
                {
                  this.state.myTasksTableData.length > 0 &&  <Table
                                                        loading={this.state.isMyTaskTemplatesLoading}
                                                        rowSelection={this.addTaskRowSelection()}
                                                        selectedRowKeys={this.state.addTemplatedTask.taskRecordArr}
                                                        rowKey={record => record.id}
                                                        columns={this.state.myTasksColumns}
                                                        scroll={{x: 500, y: 275}}
                                                        size='middle'
                                                        dataSource={this.state.myTasksTableData}
                                                        pagination={this.state.myTasksPagination}
                                                        onChange={this.handleMyTaskTableChange}/>
                }
            </Modal>

            {/* Nav back and edit if canEdit is true */}
            <Row
              type="flex"
              justify="space-between"
              align-content="center"
              style={{marginBottom: '25px'}}>
              <Col className="pf-property-resp-col" xs={24} sm={6}>
                <Link
                  to={{pathname: `/dashboard/${this.username}/properties`, page: this.props.location.page ? this.props.location.page : 1}}>
                  <Icon type="left"/> Back
                </Link>
              </Col>
              {( (!this.state.canEdit && this.state.property && (this.userType !== CONST.ROLE_TYPE.SELLER && this.userType !== CONST.ROLE_TYPE.BUYER)) && (this.state.property.roles[CONST.ROLE_TYPE.BUYER].length > 0 || this.state.property.roles[CONST.ROLE_TYPE.SELLER].length) > 0 ) && <Col xs={24} sm={6}>
                {this.getMessageLink()}
              </Col>}
              {(this.state.canEdit && this.state.property) && <Col className="pf-property-resp-col">
                  {/* Message seller/buyer if available */}
                  {(this.state.property && (this.state.property.roles[CONST.ROLE_TYPE.BUYER].length > 0 || this.state.property.roles[CONST.ROLE_TYPE.SELLER].length) > 0 ) && 
                    this.getMessageLink()
                  }
                  <Button
                    type="default"
                    icon="form"
                    size="small"
                    onClick={() => {
                      this.setState({isEditPropertyModalVisible: true})
                    }}>
                      Edit
                  </Button>
                  <Button
                    type="default"
                    icon="delete"
                    size="small"
                    style={{marginLeft: '5px'}}
                    onClick={() => {
                      confirmDelete(this.state.property.id, this.state.property.ownerEditor, this.state.property.roles, this.updateState);
                    }}>
                      Delete
                  </Button>
                </Col>}
            </Row>
            
            { this.state.property &&
              <Card className="pf-property-card-details" bordered={false}>
                <Row
                  style={{marginBottom: '20px'}}
                  type="flex"
                  gutter={16}>
                    {/* TODO: if values, map over this */}
                    <Col 
                    xs={24}
                    sm={6}>
                      <Statistic className="pf-statistic" title="Offer" prefix="$" value={this.state.property.basicDetails.offer ? this.state.property.basicDetails.offer : 'N/A'}/>
                    </Col>
                    <Col
                    xs={24}
                    sm={6}>
                      <Statistic className="pf-statistic" title="Set Price" prefix="$" value={this.state.property.basicDetails.setPrice ? this.state.property.basicDetails.setPrice : 'N/A'}/>
                    </Col>
                    <Col
                    xs={24}
                    sm={6}>
                      <Statistic className="pf-statistic" title="Address" value={this.state.property.basicDetails.address ? this.state.property.basicDetails.address : 'N/A'}/>
                    </Col>
                    <Col
                    xs={24}
                    sm={6}>
                      <Statistic className="pf-statistic" title="City" value={this.state.property.basicDetails.city ? this.state.property.basicDetails.city : 'N/A'}/>
                    </Col>
                    
                </Row>
                <PropertyDetails
                      basicDetails={this.state.property.basicDetails}
                      factsFeatures={this.state.property.factsFeatures}/>
              </Card>
            }

            {/* {
              this.state.property && <PropertyDetails
                                        basicDetails={this.state.property.basicDetails}
                                        factsFeatures={this.state.property.factsFeatures}/>
            } */}

            <Row style={{marginBottom: '30px'}}>
            { // Stats bar
              this.state.property && this.state.property.propertyTypes.map((stat, idx) => {
                if (!stat)
                  return null;
                const TASK_AMOUNT = 100 / stat.taskCount * stat.tasksComplete;
                return  <Col key={idx} xs={24} sm={6} style={{textAlign: 'center'}} className="pf-property-resp-progress">
                            <Progress type="dashboard" width={80} percent={TASK_AMOUNT} format={percent => `${stat.tasksComplete}/${stat.taskCount}`}/>
                            <div>
                              <Tag color={stat.isTaskMaker ? "blue" : "#f8f8f8"} style={{border: '0px', color: stat.isTaskMaker ? '' : '#585858'}}>{capValue(stat.type)} tasks complete</Tag>
                            </div>
                        </Col>
              })
            }
            </Row>

            { // Tabs start
              this.state.property && <Tabs type="card" style={{marginBottom: '30px'}}>
                {
                  this.state.property.propertyTypes.map((tabContent, index) => {
                    if (!tabContent || (!tabContent.isTaskMaker && !(this.userType === CONST.ROLE_TYPE.SELLER || this.userType === CONST.ROLE_TYPE.BUYER)))
                      return null;
                    
                    return <TabPane tab={capValue(tabContent.type)} key={index}>
                            <Row>
                              <Col>
                                {/* TODO: put message in here.. */}
                                {/* Buyer/Seller no task info message */}
                                {!tabContent.isTaskMaker && <Card
                                  style={{backgroundColor: '#f8f8f8', marginBottom: '20px'}} bordered={false}>
                                    <ul
                                      className='list-table pf-property-list-task'>
                                      <li style={{paddingRight: '10px'}}>
                                        <ul className="list-table">
                                          <li style={{paddingRight: '5px'}}>
                                            <Button
                                              type="primary"
                                              icon="message"
                                              onClick={e => {
                                                e.preventDefault();
                                                this.setState({isMessageSellerBuyerModalVisible: true, messageType: tabContent.type})
                                              }}>
                                                Message {capValue(tabContent.type)}
                                            </Button>
                                          </li>
                                          <li>
                                            <small style={{whiteSpace: 'nowrap'}}>({this.state.property.names[tabContent.type].name})</small>
                                          </li>
                                        </ul>
                                      </li>
                                      <li style={{paddingLeft: '10px', borderLeft: '1px solid #e8e8e8'}}>
                                        {/* Buyer/Seller task info message based upon */}
                                        {tabContent.tasks.length > 0 ? <p style={{marginBottom: '0px'}}>
                                            Below is a list of tasks from your <strong>{capValue(tabContent.type)}</strong>. In order to help you and your {tabContent.type},
                                            please complete the task/s and check or "Mark as Complete".
                                          </p>
                                          : 
                                          <p style={{marginBottom: '0px'}}>
                                            Currently your <strong>{capValue(tabContent.type)}</strong> has not provided you with any task/s as of now.
                                          </p>
                                        }
                                      </li>
                                    </ul>
                                </Card>}
                                {/* Task list */}
                                {tabContent.tasks.length > 0 && <div
                                  onDragOver={e => this.onDragOver(e)}
                                  onDrop={e => this.onDrop(e, index /* propTypeIdx */)}>
                                  {tabContent.tasks.map((task, idx) => {
                                    return <PropertyTask
                                              key={idx}
                                              {...task}
                                              propertyRoles={this.state.property.roles}
                                              propId={this.state.property.id}
                                              propTypeIdx={index}
                                              updateTasksArr={this.updateTasksArr}
                                              taskIdx={idx}
                                              isTaskMaker={tabContent.isTaskMaker}
                                              markTaskAsComplete={this.markTaskAsComplete}
                                              removeClientSideTask={this.removeClientSideTask}
                                              type={tabContent.type}/>
                                  })}
                                </div>}
                                {/* Add My Task Templates, TODO: cont. w/ adding templates.. */}
                                { tabContent.isTaskMaker && <Card
                                      className="pf-general-text-block"
                                      bordered={false}>
                                      <ul
                                        className='list-table pf-property-list-task'>
                                        <li style={{paddingRight: '10px'}}>
                                          <ul className="list-table">
                                            <li style={{paddingRight: '10px'}}>
                                              <Button
                                                    type="primary"
                                                    icon="file-text"
                                                    onClick={e => {
                                                      e.preventDefault();
                                                      this.setState({addTemplatedTask: {...this.state.addTemplatedTask, isModalVisible: true, propTypeIdx: index, propTypeId: tabContent.id}});
                                                      this.getMyTaskTemplates();
                                                    }}>
                                                      My Tasks
                                                </Button>
                                            </li>
                                            <li>
                                              <small style={{whiteSpace: 'nowrap'}}><Link
                                                  size="small"
                                                  target="_blank"
                                                  to={{pathname: `/dashboard/${this.username}/templates`, page: this.props.location.page ? this.props.location.page : 1}}>
                                                  Create Templates <Icon type="arrow-up" style={{transform: 'rotate(45deg)'}}/>
                                                </Link>
                                              </small>
                                            </li>
                                          </ul>
                                        </li>
                                        <li style={{paddingLeft: '10px', borderLeft: '1px solid #e8e8e8'}}>
                                          <p
                                            style={{marginBottom: '0px'}}>
                                            As an <strong>{capValue(tabContent.type)}</strong> add required tasks from your Templated Tasks or a new custom task for your seller or buyer to complete.
                                          </p>
                                        </li>
                                    </ul>
                                    </Card>}
                              {tabContent.isTaskMaker && <div>
                                <Divider dashed orientation={"left"} style={{marginBottom: '30px'}}>
                                  Add a task as {capValue(tabContent.type)}
                                </Divider>
                                  <Card
                                    className='pf-card-add-task'>
                                      <WrappedPropertyAddTask
                                      {...tabContent}
                                      propTypeIdx={index}
                                      task={this.state[`new${capValue(tabContent.type)}Task`].task}
                                      notes={this.state[`new${capValue(tabContent.type)}Task`].notes}
                                      uploads={this.state[`new${capValue(tabContent.type)}Task`].uploads}
                                      newTask={this.createNewTasks}
                                      setAddTaskState={this.setAddTaskState}/>
                                  </Card>
                                </div>}
                              </Col>
                            </Row>
                          </TabPane>
                  })
                }
              </Tabs> 
            }
          </div>
  
  }
}

export default Property;
