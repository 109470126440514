import React, { Component } from 'react';
import { Route, Switch, Redirect, Link } from 'react-router-dom'; // BrowserRouter as Router, Link
import { Layout, Typography, Divider } from 'antd';
import PFGlobalProvider from '../context/PFGlobalProvider';
import SideBar from '../components/SideBar';
import { restCall } from '../services/restService';
import { CONST } from '../constant/const';
// import HeaderBar from '../components/HeaderBar';
import ConfirmSignUpForm  from '../components/ConfirmSignUpForm';
import { Spin, Icon, Row, Col, message } from 'antd';
import { titlesFromPathNames, cookieParser } from '../services/utilsService';

import Properties from '../components/Properties';
import AddProperty from '../components/AddProperty';
import Messages from '../components/Messages';
import Notifications from '../components/Notifications';
import Admin from '../components/Admin';
// import { TeamAddMember } from '../components/TeamAddMember';
// import { TeamMember } from '../components/TeamMember';
import Settings from '../components/Settings';
import Property from '../components/Property';
import Templates from '../components/Templates';
import { errorHandleUnauthorized, getUnauthorizedMsgCasting } from '../services/errorHandlingService';

import '../styles/dashboard.css';

const { Footer, Content } = Layout;
const { Title } = Typography;

class DashboardContainer extends Component {

  constructor() {
    super();
    this.state = {loading: true, type: ''};
    this.antIcon = <Icon type="loading" style={{fontSize: 42}} spin/>;
  }
  
  // TODO: Insert spinner within content area while doing below..
    // Elaborate on spinner, overlay, center that stuff..
  componentDidMount() {
    restCall('/profile/get-field', CONST.REST_TYPE.POST, {field: 'type'})
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg)

        if (data && data.success) {
          // Type is empty string, confirm user type if needed
          this.setState({loading: false, type: data.type});
          if (!data.type) {
            // TODO: figure out..
            this.confirmSignUpForm = new ConfirmSignUpForm();
            this.confirmSignUpForm.confirmInfoContinuation(this)
          }
        }
      })
      .catch(err => console.log('ProfileType Err: ', err));
  }

  render() {
    return <div>
            <PFGlobalProvider>
              <Layout style={{ minHeight: '100vh' }}>
                <SideBar {...this.props}/>
                <Layout>
                  {/* <HeaderBar/> */}
                  <Content className="pf-dashboard-main" style={{ height: '100%'}}>
                    { /* Content {this.state.type} */}
                    <Spin indicator={this.antIcon} spinning={this.state.loading}/>
                      
                    {/* TODO: Figure out Title name, how does that work as drilling down.. */}
                    <div className="boxshadow pf-dashboard-wrap">
                      <Title level={3}>{titlesFromPathNames(this.props.location.pathname)}</Title>
                      <Divider dashed/>
                      <Switch>
                        <Route path={`${this.props.match.path}/add-property`} exact component={AddProperty}/>
                        <Route path={`${this.props.match.path}/properties`} exact component={Properties}/>
                        <Route path={`${this.props.match.path}/property/:id/:name`} exact component={Property}/>
                        <Route path={`${this.props.match.path}/templates`} exact component={Templates}/>
                        <Route path={`${this.props.match.path}/messages`} exact component={Messages}/>
                        <Route path={`${this.props.match.path}/notifications`} exact component={Notifications}/>
                        <Route path={`${this.props.match.path}/admin-portal`} exact component={Admin}/>
                        {/* <Route path={`${this.props.match.path}/team/add-member`} exact component={TeamAddMember}/>
                        <Route path={`${this.props.match.path}/team/member`} exact component={TeamMember}/> */}
                        <Route path={`${this.props.match.path}/settings`} exact component={Settings}/>
                        <Redirect from={`/`} to={`/dashboard/${cookieParser(CONST.COOKIE.USER_URL)}/properties`} component={Properties}/>
                      </Switch>
                    </div>

                  </Content>
                  <Footer>
                    <Row>
                      <Col
                        span={24}>
                        <ul
                          className="pf-footer-list list-table">
                          <li>
                            <Link to="#" onClick={() => window.location.href = 'https://propertyflo.com'}>Home</Link>
                          </li>
                          <li>
                            <Link to="#" onClick={() => window.location.href = 'https://propertyflo.com/#about'}>About</Link>
                          </li>
                          <li>
                            <Link to="#" onClick={() => window.location.href = 'mailto:propertyflo.info@gmail.com?subject=General app inquiry...'}>Contact</Link>
                          </li>
                        </ul>
                        <span className="pf-footer-text">
                          &#169; <strong>PropertyFlo</strong>, 2021-{ new Date().getFullYear().toString() } All rights reserved. Patent pending.
                        </span>
                      </Col>
                    </Row>
                  </Footer>
                </Layout>
              </Layout>
            </PFGlobalProvider>
          </div>
  }
}

export  default DashboardContainer;