import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { restCall } from '../services/restService';
import { Row, Col, Typography, Divider, Spin, Icon } from 'antd';
import { CONST } from '../constant/const';

const { Title, Paragraph } = Typography

class Verification extends Component {
  constructor(props) {
    super(props);
    this.state = {token: props.match.params.token,
      userId: props.match.params.userId,
      isVerified: false,
      isLoading: true,
      resentEmailVerification: false};
    this.icon = <Icon type="loading" style={{fontSize: '42px'}}/>
  }

  componentDidMount() {
    // Verification (signup route)
    restCall(`/verify/${this.state.userId}/${this.state.token}`, CONST.REST_TYPE.POST, {})
      .then(res => res.json())
      .then(result => {
        if (!result.success)
          return this.setState({isLoading: false})
        this.setState({isLoading: false, isVerified: true});
      })
      .catch(err => 'sign-up err: ' + err);
  }

  reSendEmailVerification() {
    this.setState({isLoading: true});
    restCall(`/request-email-verification/${this.state.userId}`, CONST.REST_TYPE.POST, {})
      .then(res => res.json())
      .then(result => {
        this.setState({isLoading: false, resentEmailVerification: true});
      })
      .catch(err => 'request-email-verification err: ' + err);
  }

  render () {
    return <Row
            type="flex"
            justify="center"
            align="middle"
            style={{padding: '50px 25px'}}>
        <Col
          span={24}
          md={12}>
          <Title
            level={2}>
            PropertyFlo Email Verification
          </Title>
          <Divider/>
          {
            this.state.isLoading && <Spin indicator={this.icon}/>
          }
          { (!this.state.isLoading && this.state.isVerified) &&
              <div>
                <Title
                  level={4}>
                  Great, all verified!
                </Title>
                <Paragraph>
                  Now that your email is now verified, please head over to login and use your email and password to login.
                </Paragraph>
                <Link
                  to={{pathname: '/'}}>
                  Go to Login
                </Link>
              </div>
          }
          { (!this.state.isLoading && !this.state.isVerified && !this.state.resentEmailVerification) &&
              <div>
                <Title
                  level={4}>
                  Sorry, looks like your email token has expired.
                </Title>
                <Paragraph>
                  Your token has expired. However, we can send you another verification email in order to verify now.
                </Paragraph>
                <a
                  href="/#"
                  onClick={e => {
                    e.preventDefault();
                    this.reSendEmailVerification();
                  }}>
                  Send Verification Email
                </a>
              </div>
          }
          { (!this.state.isLoading && this.state.resentEmailVerification) &&
              <div>
                <Title
                  level={4}>
                  Great, we've sent you a new email verification.
                </Title>
                <Paragraph>
                  A new email is on its way. You should expect an email with in the next few minutes or before. Please open the email and follow the "Verify Email" link.
                </Paragraph>
              </div>
          }
        </Col>
      </Row>
  };
}

export default Verification;
