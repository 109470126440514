import React, { Component } from 'react';
import { Card, Tabs, Table } from 'antd';
import { restCall } from '../services/restService';
import { CONST } from '../constant/const';
import moment from 'moment';

const { TabPane } = Tabs;

class Admin extends Component {

  constructor() {
    super();
    this.state = {columns: [], tableData: [], pagination: {current: 1}, isLoading: false};
    this.columnsArr = [];
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    this.columnsArr = [{
        title: 'First Name',
        dataIndex: 'firstname',
        key: 'firstname'
      },
      {
        title: 'Last Name',
        dataIndex: 'lastname',
        key: 'lastname'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
      },
      {
        title: 'Access',
        dataIndex: 'access',
        key: 'access',
        width: 100,
        render: access => `${access && access.propertyAccess !== undefined ? access.propertyAccess : ''}`
      },
      {
        title: 'Verified',
        dataIndex: 'isVerified',
        key: 'isVerified',
        width: 100,
        render: verified => verified.toString()
      },
      {
        title: 'Created',
        dataIndex: 'created',
        key: 'created',
        width: 250,
        render: date => moment(date).format('MMMM, D, YYYY h:mma')
      },
      {
        title: 'Last Login',
        dataIndex: 'lastLogin',
        key: 'lastLogin',
        width: 250,
        render: date => moment(date).format('MMMM, D, YYYY h:mma')
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        width: 100
      },
      {
        title: 'Prop. Count',
        dataIndex: 'propertyCount',
        key: 'propertyCount',
        width: 100,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: 100,
        fixed: 'right'
      }]
    this.getPage();
  }

  getPage() {
    this.setState({isLoading: true})
    restCall(`/admin/users/${this.state.pagination.current}`, CONST.REST_TYPE.GET)
      .then(res => res.json())
      .then(result => {
        if (result.success)
          this.setState({tableData: result.data.users, columns: this.columnsArr, isLoading: false, pagination: {current: this.state.pagination.current, total: result.data.count}});
          
      })
  }

  handleTableChange(pagination, filter, sorter) {
    // console.log('handleTableChange: ', pagination, filter, sorter);
    this.setState({pagination: pagination}, () => this.getPage());
  }

  render() {
    return <div>
      <Card
        className="pf-general-text-block"
        bordered={false}>
        <p
          style={{marginBottom: '0px'}}>
          Admin portal is to view user specific data including meta type data to give a current overiview of app usage.
        </p>
      </Card>
      <Tabs
        type="card">
        <TabPane
          key={1}
          tab="Users">
            {
              this.state.pagination.total && <div>
                                                <Card
                                                  className="pf-general-text-block"
                                                  bordered={false}>
                                                  <p
                                                    style={{marginBottom: '0px'}}>
                                                      Currently <strong>{this.state.pagination.total}</strong> total users
                                                  </p>
                                                </Card>
                                              </div>
            }
            {
              this.state.tableData.length > 0 &&  <Table
                                                    rowKey={record => record.id}
                                                    scroll={{ x: 2000, y:275 }}
                                                    size='middle'
                                                    columns={this.state.columns}
                                                    dataSource={this.state.tableData}
                                                    pagination={this.state.pagination}
                                                    onChange={this.handleTableChange}/>
            }

        </TabPane>
        <TabPane
          key={2}
          tab="Analytics">
            Analytics
        </TabPane>
      </Tabs>
    </div>
  };
}

export default Admin;
