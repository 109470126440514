import React, {Component} from 'react';
import { PFGlobalContext } from '../context/PFGlobalProvider';
import { restCall } from '../services/restService';
import { CONST } from '../constant/const';
import { Layout, Menu, Icon, Badge } from 'antd';
import { cookieParser } from '../services/utilsService';

import '../styles/sidebar.css';

const {Sider} = Layout;
// const {SubMenu} = Menu;

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      sideBarLabel: 'Properties',
      userType: cookieParser(CONST.COOKIE.USER_TYPE)
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.setState({collapsed: true}), 1500)
  }

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  handleClick(e) {
    this.props.history.push(`${this.props.match.url}/${e.item.props.path}`)
  }

  handleOverlayClick() {
    this.setState({collapsed: true});
  }

  render() {
    return <div>
      <PFGlobalContext.Consumer>
        {context => (
          <div style={{height: '100%'}}>
            <Sider
              className={this.state.collapsed ? 'pf-sidebar' : 'pf-sidebar pf-sidebar-expanded'}
              theme="light"
              collapsible
              collapsed={this.state.collapsed}
              onCollapse={this.onCollapse}
              style={{minHeight: '100%', borderRight: '1px solid #e8e8e8'}}>
                <div
                  className="logo"
                  style={{height: '45px', margin: '10px'}}>
                    {
                      this.state.collapsed ? <img className="pf-sb-logo-standalone" src={require('../img/logo-standalone-white.svg')} alt="PropertyFlo horizontal logo"/> : <img className="pf-sb-logo-horizontal" src={require('../img/logo-horizontal-white.svg')} alt="PropertyFlo stand alone logo"/>
                    }
                </div>
                <Menu
                  onClick={this.handleClick}
                  theme="light"
                  defaultSelectedKeys={this.props.location.pathname}
                  mode="inline"
                  selectedKeys={this.props.location.pathname}
                  style={{height: '100%'}}>
                  <Menu.Item
                    // Has access, enable/disable
                    disabled={!context.state.hasPropertyAccess}
                    key={`${this.props.match.url}/add-property`}
                    path="add-property">
                      <Icon type="plus" />
                      <span>Add Property</span>
                  </Menu.Item>
                  <Menu.Item key={`${this.props.match.url}/prop`}
                    path="properties">
                    <Icon type="home" />
                    <span>Properties</span>
                  </Menu.Item>
                  {
                    (this.state.userType !== CONST.ROLE_TYPE.BUYER && this.state.userType !==  CONST.ROLE_TYPE.SELLER) && <Menu.Item key={`${this.props.match.url}/templates`}
                      path="templates">
                      <Icon type="file-text" />
                      <span>Templates</span>
                    </Menu.Item>
                  }
                  <Menu.Item key={`${this.props.match.url}/messages`}
                    path="messages">
                    <Badge
                      count={context.state.unreadMessageCount}
                      className="pf-sidebar-badge">
                    </Badge>
                    <Icon type="inbox" />
                    <span>Messages</span>
                  </Menu.Item>
                  <Menu.Item key={`${this.props.match.url}/notifications`}
                    path="notifications">
                    <Badge
                      count={context.state.unreadNotificationCount}
                      className="pf-sidebar-badge">
                    </Badge>
                    <Icon type="bell" />
                    <span>Notifications</span>
                  </Menu.Item>
                  {/* <SubMenu
                  key="sub1"
                  title={
                    <span>
                      <Icon type="user" />
                      <span>User</span>
                    </span>}>
                    <Menu.Item key="4">Tom</Menu.Item>
                    <Menu.Item key="5">Bill</Menu.Item>
                    <Menu.Item key="6">Alex</Menu.Item>
                  </SubMenu> */}
                  {/* <SubMenu
                  key="sub2"
                  title={
                    <span>
                      <Icon type="team" />
                      <span>Team</span>
                    </span>}>
                    <Menu.Item key={`${this.props.match.url}/team/add-member`}
                      path="team/add-member">
                      <Icon type="plus" />
                      <span>Add Member</span>
                    </Menu.Item>
                    <Menu.Item key="denise"
                      path={`team/1234-1234-1234-1234`}>
                      Denise Richards</Menu.Item>
                  </SubMenu> */}
                  {
                    context.state.hasAdminPortalAccess && <Menu.Item key={`${this.props.match.url}/admin-portal`}
                      path="admin-portal">
                      <Icon type="user" />
                      <span>Admin Portal</span>
                    </Menu.Item>
                  }
                  <Menu.Item key={`${this.props.match.url}/settings`}
                    path="settings">
                    <Icon type="setting" />
                    <span>Settings</span>
                  </Menu.Item>
                  <Menu.Item key={`${this.props.match.url}/*`}
                    path=""
                    onClick={() => {
                      // Logout, clear cookies server side
                      restCall('/auth/logout', CONST.REST_TYPE.POST)
                        .then(res => res.json())
                        .then(data => {
                          if (data.result)
                            window.location.pathname = '';
                        })
                        .catch(err => 'Logout Err: ' + err);
                    }}>
                    <Icon type="logout" />
                    <span>Logout</span>
                  </Menu.Item>
                </Menu>
              </Sider>
              <div
                className="pf-sidebar-mobile-expand"
                onClick={() => this.onCollapse(!this.state.collapsed)}>
                <Icon type="menu"/>
              </div>
              {
                !this.state.collapsed && <div className='pf-sidebar-overlay' onClick={this.handleOverlayClick}></div>
              }
            </div>
          )}
        </PFGlobalContext.Consumer>
      </div>
  }
}

export default SideBar
