import React from 'react';
import moment from 'moment';
import { Form, Input, DatePicker } from 'antd';
import { CONST } from '../../constant/const';
import { formItemLayout } from '../../services/utilsService';

const { TextArea } = Input;

const propertyEditTask = props => {
  
  const disabledDates = current => {
    return current && current < moment().endOf('day');
  };

  const { getFieldDecorator } = props.form;

  return(<Form>
            <Form.Item
              {...formItemLayout(2,22,0)}
              labelAlign={"left"}
              label="Task"
              validateStatus={props.task ? CONST.VALIDATION_STATES.SUCCESS : CONST.VALIDATION_STATES.ERROR}
              hasFeedback>
              {getFieldDecorator('task', {
                rules: [
                  {
                    type: 'string',
                    required: true,
                    message: 'Please enter a task'
                  }
                ],
              })(<Input
                    type="text"
                    id={props.type + 'TaskId'}
                    placeholder="Task"/>)}
            </Form.Item>
            <Form.Item
              labelAlign={"left"}
              label="Notes"
              {...formItemLayout(2,22,0)}>
              {getFieldDecorator('notes', {
                initialValue: ''
              })(
                <TextArea
                name="notes"
                autoSize="true"
                style={{minHeight: '60px'}}
                onFocus={e => e.target.style.minHeight = '90px'}
                onBlur={e => e.target.style.minHeight = '60px'}
                placeholder="Notes"/>
              )}
            </Form.Item><Form.Item
              labelAlign={"left"}
              label="Due"
              {...formItemLayout(2,22,0)}>
              {getFieldDecorator('dueDate', {
                
              })(
                <DatePicker
                  format={'MM/DD/YYYY'}
                  disabledDate={disabledDates}/>
              )}
            </Form.Item>
            <Form.Item
              style={{marginBottom: '0px'}}
              {...formItemLayout(0,2,2)}>
            </Form.Item>
          </Form>);
};

export const WrappedPropertyEditTask = Form.create({
                                                    name: 'property_edit_task',
                                                    onFieldsChange(props, changedField) {
                                                      props.setStateOnEditTask(changedField)
                                                    },
                                                    mapPropsToFields(props) {
                                                      return {
                                                        task: Form.createFormField({
                                                          value: props.task,
                                                        }),
                                                        notes: Form.createFormField({
                                                          value: props.notes,
                                                        }),
                                                        dueDate: Form.createFormField({
                                                          value: props.dueDate ? moment(props.dueDate) : null,
                                                        })
                                                      };
                                                    }
                                                  })(propertyEditTask);
