import React, { Component } from 'react';
import moment from 'moment';
import { Card, Icon, message, Divider, Table, Upload, Modal } from 'antd';
import { myDocumentsColumnsArr, addUploadToTaskColumnsArr } from '../../services/templateService';
import { CONST } from '../../constant/const';
import { cookieParser } from '../../services/utilsService';
import { restCall } from '../../services/restService';
import { uploadClientSide } from '../../services/uploadService';
import { errorHandleUnauthorized, getUnauthorizedMsgCasting } from '../../services/errorHandlingService';

const { Dragger } = Upload;
const { confirm } = Modal;

const contentHtmlRender = (resultsArr, name) => {
  return (
    <div>
        { resultsArr.map(obj =>  obj.task).join( ', ') }
        <Divider dashed orientation={"left"} style={{marginTop: '10px', marginBottom: '10px'}}/>
        These refrences must be deleted before <strong>{ name }</strong> can be deleted.
    </div>
  )
};

class TemplateMyDocuments extends Component {

  constructor() {
    super();
    this.userId = cookieParser(CONST.COOKIE.USER_ID);
    this.state = {
      myDocumentsColumns: [],
      myDocumentsTableData: [],
      myDocumentsPagination: {current: 1},
      myDocuments: {uploads: [], isUploading: false, filename: ''},
      isMyDocumentTemplatesLoading: false,
      addUploadToTask: {isModalVisible: false, isLoading: false, taskIdArr: [], uploadRecord: {}},
      myTasksColumns: [],
      myTasksTableData: [],
      myTasksPagination: {current: 1},
      isMyTaskTemplatesLoading: false,
    };
    this.getMyTaskTemplates = this.getMyTaskTemplates.bind(this);
    this.getMyDocumentTemplates = this.getMyDocumentTemplates.bind(this);
    this.createUploadTemplate = this.createUploadTemplate.bind(this);
    this.handleMyDocumentsTableChange = this.handleMyDocumentsTableChange.bind(this);
    this.handleMyTaskTableChange = this.handleMyTaskTableChange.bind(this);
    this.renderConfirmDelete = this.renderConfirmDelete.bind(this);
    this.addUploadToTasks = this.addUploadToTasks.bind(this);
    this.addTaskRowSelection = this.addTaskRowSelection.bind(this);
    this.confirm = confirm;
    this.contentHtmlRender = contentHtmlRender;
  }

  componentDidMount() {
    const updatedRender = date => moment(date).format('MMMM, D, YYYY h:mma');
    // My Documents (change to upon open)
    const docTitleRender = (text, record) => {
      // TODO: can make this better.?.
      const url = (record.originFileObj) ? record.originFileObj.url : record.url;
      return (<div>
      <a href={url} target="_blank" rel="noopener noreferrer"><Icon type="link" /></a> <span>{record.name}</span></div>
    )};
    const createdRender = date => moment(date).format('MMMM, D, YYYY h:mma');
    const docActionsRender = (text, record) => (<div><a href="/#" onClick={e => {
        e.preventDefault();
        // if url doesn't exist, add it
        if (!record.url)
          record.url = record.originFileObj.url;
        this.setState({addUploadToTask: {...this.state.addUploadToTask, isModalVisible: true, uploadRecord: record}});
        this.getMyTaskTemplates();
      }}>Add to task</a> | <a href="/#" onClick={e => {
        e.preventDefault();
        this.renderConfirmDelete(record);
      }}>Delete</a></div>);
    this.myDocumentsColumnsArr = myDocumentsColumnsArr(docTitleRender, createdRender, docActionsRender);
    this.addUploadToTaskColumnsArr = addUploadToTaskColumnsArr(updatedRender);
    this.setState({myDocumentsColumns: this.myDocumentsColumnsArr, myTasksColumns: this.addUploadToTaskColumnsArr});
    this.getMyDocumentTemplates();
  }

  getMyDocumentTemplates() {
    this.setState({isMyDocumentTemplatesLoading: true});
    restCall(`/template/get-my-document-templates/${this.userId}/${this.state.myDocumentsPagination.current}`, CONST.REST_TYPE.GET)
      .then(res => res.json())
      .then(result => {
        // Upon 401
        if (result && !result.success && result.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, result.msg);

        if (result && result.success)
          this.setState({myDocumentsTableData: result.data.uploads, isMyDocumentTemplatesLoading: false, myDocumentsPagination: {current: this.state.myDocumentsPagination.current, total: result.data.count}});
      })
      .catch(err => '/template/get-my-document-templates Err: ' + err);
  }

  getMyTaskTemplates() {
    this.setState({isMyTaskTemplatesLoading: true});
    restCall(`/template/get-my-task-templates/${this.userId}/${this.state.myTasksPagination.current}`, CONST.REST_TYPE.GET)
      .then(res => res.json())
      .then(result => {
        // Upon 401
        if (result && !result.success && result.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, result.msg);

        if (result && result.success)
          this.setState({myTasksTableData: result.data.tasks, isMyTaskTemplatesLoading: false, myTasksPagination: {current: this.state.myTasksPagination.current, total: result.data.count}});
      })
      .catch(err => '/template/get-task-templates Err: ' + err);
  }

  createUploadTemplate(fileUpload) {
    restCall(`/template/create-upload-template/${this.userId}`, CONST.REST_TYPE.POST, fileUpload)
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && data.success) {
          setTimeout(() => {
            this.setState({myDocuments: {isUploading: false,}});
            message.success(`${(data.upload.name.length > 24) ? data.upload.name.substring(0,12) + '...' : data.upload.name }  has been uploaded successful.`);
          },1000);

          let myDocuments = {...this.state.myDocuments};
          myDocuments.isUploading = false;
          myDocuments.filename = '';
          myDocuments.uploads = [];
          this.setState({myDocuments: myDocuments});
          this.setState(prevState => ({
            myDocuments: myDocuments,
            myDocumentsTableData: [...prevState.myDocumentsTableData, data.upload]
          }));
          this.handleMyDocumentsTableChange(this.state.myDocumentsPagination);
        }
      })
      .catch(err => `/notifications/get-notifications Err: ` + err);
  }

  renderConfirmDelete(templateDoc) {
    restCall(`/template/get-active-uploads/${templateDoc.id}`, CONST.REST_TYPE.GET)
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && data.success) {
          const resultsArr = data.data;
          this.confirmDelete(templateDoc, resultsArr, this.getMyDocumentTemplates);
        }
      })
      .catch(err => `/notifications/dismiss-all-notifications Err: ` + err);
  }

  confirmDelete(templateDoc, resultsArr, getMyDocumentTemplates) {
    // logic in here for deletion or info
    const hasTasks = resultsArr.length > 0 ;
    const title = hasTasks ? 'This upload currently has the following task references to it:' : 'Are you sure you want delete?'; // or cannot delete due to...
    const content = hasTasks ?  this.contentHtmlRender(resultsArr, templateDoc.name)  : templateDoc.name;
    const okType = hasTasks ? 'primary' : 'danger';
    const okText = hasTasks ? 'Okay' : 'Confirm';

    this.confirm({
      title: title,
      content: content,
      okText: okText,
      okType: okType,
      cancelButtonProps: {disabled: hasTasks},
      maskClosable: true,
      onOk() {
        // hasTasks, do not delete, hasTasks > delete
        if (hasTasks)
          return;
        return new Promise(resolve => {
          const uploadKey = `${templateDoc.userId}/${templateDoc.uid}/${templateDoc.name}`; // Create key in order to delete object
          restCall(`/template/delete-template-upload/${templateDoc.id}`, CONST.REST_TYPE.DELETE, {uploadKey})
            .then(res => res.json())
            .then(data => {
              // Upon 401
              if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                return errorHandleUnauthorized(message, data.msg);

              if (data && data.success)
                resolve(getMyDocumentTemplates());
            })
            .catch(err => `/notifications/dismiss-all-notifications Err: ` + err);
        })
      }
    })
  }

  handleMyDocumentsTableChange(pagination) {
    this.setState({myDocumentsPagination: pagination},() => this.getMyDocumentTemplates());
  }

  handleMyTaskTableChange(pagination) {
    this.setState({myTasksPagination: pagination},() => this.getMyTaskTemplates());
  }

  addUploadToTasks() {
    const addUploadToTaskPayload = {uploadRecord: this.state.addUploadToTask.uploadRecord,
      taskIdArr: this.state.addUploadToTask.taskIdArr};
    restCall(`/template/add-upload-to-task`, CONST.REST_TYPE.POST, addUploadToTaskPayload)
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && data.success) {
          const fileName = this.state.addUploadToTask.uploadRecord.name;
          message.success(`${fileName} has been copied to task/s.`)
          this.setState({
            addUploadToTask: {...this.state.addUploadToTask, taskIdArr: [], uploadRecord: {}, isModalVisible: false, isLoading: false },
            myTasksPagination: {current: 1}
          });
        }
      })
      .catch(err => `/notifications/dismiss-all-notifications Err: ` + err);
  }

  addTaskRowSelection() {
    return {
      selectedRowKeys: this.state.addUploadToTask.taskIdArr,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({addUploadToTask: {...this.state.addUploadToTask, taskIdArr: selectedRowKeys}})
      }
    }
  }

  render() {
    return <div>
      <Modal
        title="Add Document to Task"
        className="pf-modal"
        visible={this.state.addUploadToTask.isModalVisible}
        okText="Add"
        confirmLoading={this.state.addUploadToTask.isLoading}
        okButtonProps={{disabled: !this.state.addUploadToTask.taskIdArr.length}}
        onOk={() => {
          this.setState({addUploadToTask: {...this.state.addUploadToTask, isLoading: true}});
          this.addUploadToTasks();
        }}
        onCancel={() => {
          this.setState({
            addUploadToTask: {...this.state.addUploadToTask, taskIdArr: [], uploadRecord: {}, isModalVisible: false},
            myTasksPagination: {current: 1}
          });
      }}>
        <Card
          className="pf-general-text-block"
          bordered={false}>
            <p
              style={{marginBottom: '0px'}}>
              Select tasks to add <strong>{this.state.addUploadToTask.uploadRecord.name}</strong> to single or multiple tasks at once.
            </p>
        </Card>
        {
          this.state.myTasksTableData.length > 0 &&  <Table
                                                loading={this.state.isMyTaskTemplatesLoading}
                                                rowSelection={this.addTaskRowSelection()}
                                                selectedRowKeys={this.state.addUploadToTask.taskIdArr}
                                                rowKey={record => record.id}
                                                columns={this.state.myTasksColumns}
                                                scroll={{x: 500, y: 275}}
                                                size='middle'
                                                dataSource={this.state.myTasksTableData}
                                                pagination={this.state.myTasksPagination}
                                                onChange={this.handleMyTaskTableChange}/>
        }
      </Modal>
      <Card
        className="pf-general-text-block"
        bordered={false}>
        <p
          style={{marginBottom: '0px'}}>
          Upload once and access your documents conveniently in <strong>My Documents</strong>. You can upload and seamlessly
          add them to any templated task, in <strong>My Tasks</strong> section, by selecting the <strong>Add to Task</strong> option.
        </p>
      </Card>
      <Divider dashed orientation={"left"} style={{marginBottom: '30px'}}>
        Templated Documents
      </Divider>
      {
        this.state.myDocumentsTableData.length > 0 &&  <Table
                                              rowKey={record => record.id}
                                              columns={this.state.myDocumentsColumns}
                                              scroll={{x: 500, y: 275}}
                                              size='middle'
                                              dataSource={this.state.myDocumentsTableData}
                                              pagination={this.state.myDocumentsPagination}
                                              onChange={this.handleMyDocumentsTableChange}/>
      }
      { // Empty state..
        this.state.myDocumentsTableData.length === 0 && <div style={{textAlign: 'center', marginBottom: '35px'}}>
            <Icon style={{ fontSize: '48px', marginBottom: '10px', color: '#ddd' }} type="folder-open" />
            <h3>You currently have no documents uploaded</h3>
            <p
              style={{marginBottom: '0px'}}>
              Get started by uploading a file below!
            </p>
        </div>
      }
      <Dragger
        className="pf-task-upload"
        showUploadList={false}
        fileList={this.state.myDocuments.uploads ? this.state.myDocuments.uploads : []}
        beforeUpload={file => {
          // TODO: may or may not need to figure out type, file.type
          if (file.size >= 100000000) { // 100000000 = 100MBs
            // TODO: return some kind of error message
            message.error('Upload exceeds max limit of 100MBs.');
            return false
          }
          return true;
        }}
        customRequest={fileEvent => {
          let myDocuments = {...this.state.myDocuments};
          myDocuments.isUploading = true;
          myDocuments.filename = fileEvent.file.name;
          this.setState({myDocuments: myDocuments});
          fileEvent.filename = fileEvent.file.name;
          // Create rest api for template, include something like below..
          restCall('/template/get-signed-url', CONST.REST_TYPE.POST, {fileEvent, userId: this.userId})
              .then(res => res.json())
              .then(data => {
                // Upon 401
                if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                  return errorHandleUnauthorized(message, data.msg);

                if (data && data.success) {
                  let signedUrl = data.signedUrlObj;
                  uploadClientSide(signedUrl, fileEvent)
                    .then(data => {
                      // Build object manually due to Dragger.?., maybe a TODO..
                      const uploadObj = {uid: data.uid,
                        url: data.url,
                        name: data.name,
                        lastModified: data.lastModified,
                        lastModifiedDate: data.lastModifiedDate,
                        size: data.size, type: data.type};
                      // Send file obj to create upload template
                      this.createUploadTemplate(uploadObj);
                    })
                    .catch(err => console.log('uploadClientSide Err: ', err));
                }
              });
        }}>

        <Icon type="cloud-upload" style={{fontSize: '42px', color: 'e8e8e8'}}/>
        <p
          style={{marginBottom: '0px'}}>
          <strong>Click or drag a file to this area to upload.</strong>
          <br/>
          <small>*Max file size 100MBs</small>
        </p>
        {/* Clean up below, ul li */}
        &nbsp;
        { this.state.myDocuments.isUploading && <Icon type="loading" style={{fontSize: 22, color: '#006aff'}}/> }
        &nbsp;
        { this.state.myDocuments.isUploading &&  `${(this.state.myDocuments.filename.length > 24) ? this.state.myDocuments.filename.substring(0,12) + '...' : this.state.myDocuments.filename }  is uploading.` }
      </Dragger>
    </div>
  }

}

export default TemplateMyDocuments;
