import React, { Component } from 'react';
import { restCall } from '../services/restService';
import { Form, Icon, Input, Button, Row, Col, Select, Divider, Card, message } from 'antd';
import { CONST } from '../constant/const';
import { capValue } from '../services/utilsService';
import { errorHandleUnauthorized, getUnauthorizedMsgCasting } from '../services/errorHandlingService';

const { Option } = Select;

class SettingsAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      type: '',
      password: '',
      passwordNew: '',
      passwordConfirm: '',
      isEnabled: false,
      isSettingLoading: false,
      isPasswordLoading: false,
      isProfileBtnDisabled: false,
      isPasswordBtnDisabled: true
    };

    this.selectTypesOptionsArr = [];
    this.selectTypesArr = [CONST.ROLE_TYPE.AGENT, CONST.ROLE_TYPE.BUYER, CONST.ROLE_TYPE.SELLER, CONST.ROLE_TYPE.ESCROW, CONST.ROLE_TYPE.TITLE, CONST.ROLE_TYPE.LENDER];
    this.re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[0-9a-zA-Z][\w~@#$%^&*+=`|\\{}:;!.?"()[\]-]{7,}$/; // Non special char
  }

  componentDidMount() {
    this.getProfileFields();
    this.buildSelectOptions();
  }

  buildSelectOptions() {
    this.selectTypesArr.forEach((type, idx) => {
      this.selectTypesOptionsArr.push(<Option key={idx} value={type}>{capValue(type)}</Option>)
    })
  }

  getProfileFields() {
    restCall('/settings/get-account', CONST.REST_TYPE.GET)
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && data.success)
          this.setState(prevState => data);
      })
      .catch(err => 'get-account Err: ' + err);
  }

  saveAccountSettings() {
    const accountObj = {firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        email: this.state.email,
                        type: this.state.type};
    restCall('/settings/save-account', CONST.REST_TYPE.POST, accountObj)
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && data.success) {
          message.success('Your account settings has been updated successfully');
          this.setState({isSettingLoading: false});
        }
      })
      .catch(err => {
        message.error('Something went wrong, please try back later');
        return 'save-account Err: ' + err;
      });
  }

  changePassword(cbResetPasswordFields) {
    const passwordObj = {password: this.state.password,
                          passwordNew: this.state.passwordNew,
                          passwordConfirm: this.state.passwordConfirm};
    restCall('/settings/change-password', CONST.REST_TYPE.POST, passwordObj)
      .then(res => res.json())
      .then(data => {
        // Upon 401
        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
          return errorHandleUnauthorized(message, data.msg);

        if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_REQUEST) { // BAD_REQUEST (400) (password does not match)
          message.error(data.msg, CONST.INTS.MESSAGE_DURATION); // TODO: state change triggers a redirect in render
          return;
        }

        if (data && data.success) {
          message.success('Your password has been changed successfully');
          this.setState({isPasswordLoading: false, password: '', passwordNew: '', passwordConfirm: '', isPasswordBtnDisabled: true});
          // Upon success, clear password fields
          cbResetPasswordFields();
        }
      })
      .catch(err => {
        message.error('Something went wrong, please try back later');
        return 'change-password Err: ' + err;
      });
  }

  // Check valid profile in order to enable/disable Save Changes button
  profileCheck() {
    const { isFieldTouched, validateFields } = this.props.form;
    if (!this.state.firstname || !this.state.lastname)
      this.setState({isProfileBtnDisabled: true});
    // Only check email if dirty
    else if (isFieldTouched('email'))
      validateFields(['email'])
        .then(() => this.setState({isProfileBtnDisabled: false}))
        .catch(() => this.setState({isProfileBtnDisabled: true}))
    else
      this.setState({isProfileBtnDisabled: false})
  }

  passwordCheck() {
    const { isFieldTouched, validateFields } = this.props.form;
    // Check if password form have been touched before checking and enabling
    if (isFieldTouched('new_password') && isFieldTouched('confirm_password') && isFieldTouched('confirm_password'))
      validateFields(['current_password', 'new_password', 'confirm_password'])
        .then(() => this.setState({isPasswordBtnDisabled: false}))
        .catch(() => this.setState({isPasswordBtnDisabled: true}))
  }

  validateConfirmPassword(password) {
    return (password === this.state.passwordNew);
  }

  render () {
    const { getFieldDecorator } = this.props.form;
    
    return <Form>
            <Row>
              <Col
                lg={12}
                sm={24}
                style={{paddingLeft: '5px'}}>
                  <Card
                    bordered={false}
                    style={{marginBottom: '25px', borderRadius: '3px', backgroundColor: '#f8f8f8'}}>
                    <Divider dashed orientation={"left"} style={{marginBottom: '30px'}}>Profile Details</Divider>
                    <p
                      style={{marginBottom: '35px'}}>
                      <strong>Please note:</strong> Upon changing email you will need to verify new email address.
                      Also, when changing your <strong>type</strong> you may experience issues on existing properties,
                      dependent upon what actions are taken on that property.
                    </p>
                    <Row
                      type="flex"
                      justify="space-between">
                      <Col
                        span={24}
                        md={11}
                        sm={24}>
                        <Form.Item
                          hasFeedback>
                          {getFieldDecorator('firstname', {
                            initialValue: this.state.firstname,
                            rules: [
                              {
                                type: 'string',
                                message: 'Input a first name',
                              },
                              {
                                required: true,
                                message: 'Input your first name',
                              },
                            ],
                          })(<Input
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="text"
                                placeholder="First Name"
                                onChange={e => {
                                  this.setState({firstname: e.target.value}, () => this.profileCheck());
                                }}/>)}
                          </Form.Item>
                      </Col>
                      <Col
                        span={24}
                        md={11}
                        sm={24}>
                      <Form.Item
                          hasFeedback>
                          {getFieldDecorator('lastname', {
                            initialValue: this.state.lastname,
                            rules: [
                              {
                                type: 'string',
                                message: 'Input your last name'
                              },
                              {
                                required: true,
                                message: 'Input your last name',
                              },
                            ]
                          })(<Input
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="text"
                                placeholder="Last Name"
                                onChange={e => {
                                  this.setState({lastname: e.target.value}, () => this.profileCheck())
                                }}/>)}
                          </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      hasFeedback>
                      {getFieldDecorator('email', {
                        initialValue: this.state.email,
                        rules: [
                          {
                            type: 'email',
                            message: 'Input a valid email address'
                          },
                          {
                            required: true,
                            message: 'Input a valid email address',
                            validator: (rule, value, cb) => {
                              if (value === '' || value === undefined)
                                return setTimeout(() => cb(false), 500);
                              setTimeout(() => cb(), 500);
                            },
                          },
                        ],
                      })(<Input
                            prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="E-mail"
                            onChange={e => {
                              this.setState({email: e.target.value}, () => this.profileCheck())
                            }}/>)}
                    </Form.Item>
                    <Form.Item>
                      <Select
                        showArrow={true}
                        value={this.state.type}
                        onChange={type => this.setState({type: type})}>
                          { this.selectTypesOptionsArr }
                      </Select>
                    </Form.Item>
                    <Form.Item
                      style={{marginBottom: '0'}}>
                      <Button
                        type="primary"
                        disabled={this.state.isProfileBtnDisabled}
                        loading={this.state.isSettingLoading}
                        onClick={() => {
                          this.setState({isSettingLoading: true});
                          this.saveAccountSettings();
                      }}>
                        Save Changes
                      </Button>
                    </Form.Item>
                    </Card>
                </Col>
              </Row>

            {/* Change Password below */}

              <Row>
                <Col
                  lg={12}
                  sm={24}
                  style={{paddingLeft: '5px'}}>
                  <Card
                    bordered={false}
                    style={{borderRadius: '3px', backgroundColor: '#f8f8f8'}}>
                  <Divider dashed orientation={"left"} style={{marginBottom: '30px'}}>Password</Divider>
                  <p
                    style={{marginBottom: '35px'}}>
                    Password must be a minimum of 8 characters 
                    and at least one digit and capital letter
                  </p>
                  <Form.Item
                    required={true}
                    hasFeedback>
                    {getFieldDecorator('current_password', {
                      rules: [
                        { 
                          required: true,
                          message: 'Current Password must be a valid password',
                          validator: (rule, value, cb) => {
                            if (this.re.test(value)) {
                              return setTimeout(() => cb(), 500);
                            } else {
                              return setTimeout(() => cb(false), 500);
                            }
                          }
                        }
                      ],
                    })(
                      <Input.Password
                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder="Current Password"
                        onChange={e => this.setState({password: e.target.value}, () => this.passwordCheck())}/>,
                    )}
                  </Form.Item>
                  <Form.Item
                    required={true}
                    hasFeedback>
                    {getFieldDecorator('new_password', {
                      rules: [
                        { 
                          required: true,
                          message: 'New Password must be a valid password',
                          validator: (rule, value, cb) => {
                            if (this.re.test(value)) {
                              return setTimeout(() => cb(), 500);
                            } else {
                              return setTimeout(() => cb(false), 500);
                            }
                          }
                        }
                      ],
                    })(
                      <Input.Password
                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder="New Password"
                        onChange={e => this.setState({passwordNew: e.target.value}, () => this.passwordCheck())}/>,
                    )}
                  </Form.Item>
                  <Form.Item
                      required={true}
                      hasFeedback>
                      {getFieldDecorator('confirm_password', {
                        rules: [
                          { 
                            required: true,
                            message: 'Confirm Password must match new password',
                            validator: (rule, value, cb) => {
                              if (this.re.test(value) && this.validateConfirmPassword(value)) {
                                return setTimeout(() => cb(), 500);
                              } else {
                                return setTimeout(() => cb(false), 500);
                              }
                            }
                          }
                        ],
                      })(
                        <Input.Password
                          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          type="password"
                          placeholder="Confirm Password"
                          onChange={e => this.setState({passwordConfirm: e.target.value}, () => this.passwordCheck())}/>,
                      )}
                    </Form.Item>
                    <Form.Item
                      style={{marginBottom: '0'}}>
                      <Button
                        type="primary"
                        disabled={this.state.isPasswordBtnDisabled}
                        loading={this.state.isPasswordLoading}
                        onClick={() => {
                          this.setState({isPasswordLoading: true});
                          // Pass reset fields in order to reset password fields
                          this.changePassword(this.props.form.resetFields);
                        }}>
                        Change Password
                      </Button>
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
          </Form>;
  };
}

const WrapSettingsAccount = Form.create({ name: 'settings_account' })(SettingsAccount);

export default WrapSettingsAccount;
