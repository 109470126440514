import React from 'react';
import { AddEditPropertyContext } from '../../context/AddEditPropertyProvider';
import { Form, Input, InputNumber, Select, Radio, Divider, Card } from 'antd';
import { formItemLayout, capValue, states } from '../../services/utilsService';

const { Option } = Select;
const { TextArea } = Input;

const detailsArr = [
  {
    name: 'setPrice',
    label: 'Set Price',
    placeholder: '$',
  },
  {
    name: 'offer',
    label: 'Offer',
    placeholder: '$',
  },
  {
    name: 'address',
    label: 'Address',
    placeholder: 'Address',
  },
  {
    name: 'city',
    label: 'City',
    placeholder: 'City',
  },
  {
    name: 'state',
    label: 'State',
    placeholder: 'Select',
  },
  {
    name: 'zipcode',
    label: 'Zipcode',
    placeholder: 'Zipcode',
  },
  {
    name: 'link',
    label: 'Link',
    placeholder: 'https://linktohome.com',
  }
];

const houseCondoPropertyDetails = [
  {
    name: 'sqft',
    label: 'SqFt',
    placeholder: 'n/a'
  },
  {
    name: 'lotSize',
    label: 'Lot size',
    placeholder: 'n/a'
  },
  {
    name: 'bd',
    label: 'Bd',
    placeholder: 'n/a'
  },
  {
    name: 'ba',
    label: 'Ba',
    placeholder: 'n/a'
  }
];

export const addEditPropertyBasicForm = () => {

  return (
    <AddEditPropertyContext.Consumer>
      {(context) => (
        <React.Fragment>
          {/* {console.log('Basic context: ', context.state)} */}
          <Form>
            <Card
              bordered={false}
              style={{marginBottom: '20px', backgroundColor: '#fafafa', borderRadius: '6px'}}>
              <p
                style={{marginBottom: '0px'}}>Please select the property type and fill in necessary values.</p>
            </Card>
            <Form.Item
              style={{marginBottom: '0px'}}
              label={"Type"}
              labelAlign={"left"}
              {...formItemLayout(2,8,0)}>
              <Radio.Group
                style={{marginBottom: '30px'}}
                onChange={e => context.utils.setValue({basicDetails: {...context.state.basicDetails, type: e.target.value}})}
                defaultValue={context.state.basicDetails.type}
                buttonStyle="solid">
                <Radio.Button value="house">House</Radio.Button>
                <Radio.Button value="condo">Condo</Radio.Button>
                <Radio.Button value="land">Land</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {
              detailsArr.map((val, idx) => {
                if (val.name === 'state')
                  return <Form.Item
                            key={idx}
                            {...formItemLayout(2,8,0)}
                            label={val.label}
                            labelAlign={"left"}
                            defaultValue={context.state.basicDetails[val.name]}>
                              { // If has state, show default/selected value or placeholder
                                context.state.basicDetails.state ? <Select
                                                                      showSearch={true}
                                                                      defaultValue={context.state.basicDetails.state}
                                                                      onChange={value => context.utils.setValue({basicDetails: {...context.state.basicDetails, [val.name]: value}})}>
                                                                        {
                                                                          Object.keys(states).map((key, idx) => <Option key={idx} value={key}>{states[key]}</Option>)
                                                                        }
                                                                    </Select>
                                                                    :
                                                                    <Select
                                                                      showSearch={true}
                                                                      placeholder={capValue(context.state.basicDetails[val.name]) || val.placeholder}
                                                                      onChange={value => context.utils.setValue({basicDetails: {...context.state.basicDetails, [val.name]: value}})}>
                                                                        {
                                                                          Object.keys(states).map((key, idx) => <Option key={idx} value={key}>{states[key]}</Option>)
                                                                        }
                                                                    </Select>
                              }
                          </Form.Item>

                return <Form.Item
                          key={idx}
                          {...formItemLayout(2,val.name === 'zipcode' ? 3 : 8,0)}
                          style={{marginBottom: val.name === 'link' ? '0px' : ''}}
                          label={val.label}
                          labelAlign={"left"}
                          value={context.state.basicDetails.setPrice}>
                            <Input
                              placeholder={val.placeholder}
                              value={context.state.basicDetails[val.name]}
                              onChange={e => context.utils.setValue({basicDetails: {...context.state.basicDetails, [val.name]: e.target.value}})}/>
                        </Form.Item>
              })
            }
            <Divider dashed orientation={"right"} style={{marginBottom: '30px'}}>House/Condo Details</Divider>
            {
              houseCondoPropertyDetails.map((val, idx) => {
                return <Form.Item
                          {...formItemLayout(2,3,0)}
                          style={{marginBottom: val.name === 'ba' ? '0px' : ''}}
                          key={idx}
                          label={val.label}
                          labelAlign={"left"}>
                            <InputNumber
                            min={0}
                            placeholder={val.placeholder}
                            defaultValue={context.state.basicDetails[val.name]}
                            formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/(,*)/g, '')}
                            onChange={value => context.utils.setValue({basicDetails: {...context.state.basicDetails, [val.name]: value}})}/>
                        </Form.Item>
              })
            }
            <Divider dashed orientation={"right"} style={{marginBottom: '30px'}}>Overview</Divider>
            <Form.Item
                {...formItemLayout(2,18,0)}
                style={{marginBottom: '0'}}
                label="Overview"
                labelAlign={"left"}>
                  <TextArea
                    defaultValue={context.state.basicDetails.overview}
                    placeholder="Property description."
                    rows={6}
                    onChange={e => context.utils.setValue({basicDetails: {...context.state.basicDetails, overview: e.target.value}})}/>
              </Form.Item>
          </Form>
        </React.Fragment>
              )}
    </AddEditPropertyContext.Consumer>
  );
}
