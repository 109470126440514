import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { restCall } from '../services/restService';
import { Row, Col, Typography, Divider, Icon, Form, Input, Button } from 'antd';
import { CONST } from '../constant/const';

const { Title, Paragraph } = Typography

const PasswordResetForm = props => {
  const { getFieldDecorator, getFieldValue, resetFields } = props.form;
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[0-9a-zA-Z][\w~@#$%^&*+=`|\\{}:;!.?"()[\]-]{7,}$/; // Non special char

  const validateConfirmPassword = (password, confirmPassword) => {
    return (password === confirmPassword);
  }

  return (
    <Form>
      <Form.Item
        required={true}
        hasFeedback>
        {getFieldDecorator('new_password', {
          getValueFromEvent: props.newPassword,
          rules: [
            {
              required: true,
              message: 'New Password must be a valid password',
              validator: (rule, value, cb) => {
                if (re.test(value)) {
                  return setTimeout(() => cb(), 500);
                } else {
                  return setTimeout(() => cb(false), 500);
                }
              }
            }
          ]
        })(
          <Input.Password
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="New Password"/>,
        )}
      </Form.Item>
      <Form.Item
          required={true}
          hasFeedback>
          {getFieldDecorator('confirm_password', {
            rules: [
              {
                required: true,
                message: 'Confirm Password must match new password',
                validator: (rule, value, cb) => {
                  // Need to send validate password both password
                  if (re.test(value) && validateConfirmPassword(value, getFieldValue('new_password'))) {
                    return setTimeout(() => cb(), 500) && props.setValueObj({password: value, isResetButtonDisabled: false, resetFields: resetFields});
                  } else {
                    return setTimeout(() => cb(false), 500) && props.setValueObj({password: '',isResetButtonDisabled: true});
                  }
                }
              }
            ],
          })(
            <Input.Password
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Confirm Password"/>,
          )}
        </Form.Item>
    </Form>
  );
}

const WrapPasswordResetForm = Form.create({ name: 'password_reset' })(PasswordResetForm);

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {token: props.match.params.token,
      userId: props.match.params.userId,
      password: '',
      errorMessage: '',
      showPasswordForm: true,
      showErrorMessage: false,
      showSuccessMessage: false,
      isResetButtonDisabled: true,
      isResetButtonLoading: false,
      resetFields: null}; // resetFields will come from form
    this.icon = <Icon type="loading" style={{fontSize: '42px'}}/>
    this.setValueObj = this.setValueObj.bind(this);
  }

  initResetPassword() {
    if (!this.state.password || !this.state.token || !this.state.userId)
      return null; // TODO: alert w/ error message
    restCall('/auth/reset-password', CONST.REST_TYPE.POST, {password: this.state.password, userId: this.state.userId, token: this.state.token})
      .then(res => res.json())
      .then(result => {
        if (result.success) {
          this.state.resetFields();
          this.setState({isResetButtonLoading: false, isResetButtonDisabled: true, password: '', showPasswordForm: false, showSuccessMessage: true});
        } else {
          this.state.resetFields();
          this.setState({isResetButtonLoading: false, isResetButtonDisabled: true, password: '', errorMessage: result.msg, showPasswordForm: false, showErrorMessage: true});
        }
      })
      .catch(err => console.log('/auth/reset-password: ', err))
}

  setValueObj(obj) {
    this.setState(obj);
  }

  render () {
    return <Row
            type="flex"
            justify="center"
            align="middle"
            style={{padding: '50px 25px'}}>
        <Col
          span={24}
          md={12}>
          <Title
            level={2}>
            Reset Password
          </Title>
          <Divider/>

          { this.state.showPasswordForm && <div>
              <Title
                level={4}>
                Please enter a new password.
              </Title>
              <Paragraph>
                Upon entering your new password, it must be a minimum of 8 characters, at least one digit and capital letter (eg. Password1). Confirm your new password and then reset.
              </Paragraph>
              <WrapPasswordResetForm
                setValueObj={this.setValueObj}/>
              <Button
                  type="primary"
                  disabled={this.state.isResetButtonDisabled}
                  loading={this.state.isResetButtonLoading}
                  onClick={() => {
                    // Rest call
                    this.setState({isResetButtonLoading: true});
                    this.initResetPassword();
                  }}>
                  Reset
              </Button>
            </div>
          }
          { this.state.showSuccessMessage &&
              <div>
                <Title
                  level={4}>
                  Great, your password has been reset!
                </Title>
                <Paragraph>
                  Now that your password has been reset, please head over to <Link to={{pathname: '/'}}>login</Link> and use your email and new password to login.
                </Paragraph>
                <Link
                  to={{pathname: '/'}}>
                  Go to Login
                </Link>
              </div>
          }
          { this.state.showErrorMessage &&
              <div>
                <Title
                  level={4}>
                  Error: { this.state.errorMessage }
                </Title>
                <Paragraph>
                  Sorry, unfortunately something went wrong during this process. Please try again by going back to <Link to={{pathname: '/'}}>login</Link> and reinitiate the forgot password process. Otherwise, if problem persists please contact <a href="mailto:support@propertyflo.com?subject=Reset password issue">support@propertyflo.com</a>, thanks.
                </Paragraph>
                <Link
                  to={{pathname: '/'}}>
                  Go to Login
                </Link>
              </div>
          }

        </Col>
      </Row>
  };
}

export default PasswordReset;
