import React from 'react';
import moment from 'moment';
import { Form, Input, DatePicker, Upload, Row, Col, Icon, message, Button, Alert, Card } from 'antd';
import { restCall } from '../../services/restService';
import { uploadClientSide } from '../../services/uploadService';
import { errorHandleUnauthorized, getUnauthorizedMsgCasting } from '../../services/errorHandlingService';
import { CONST } from '../../constant/const';
import { cookieParser } from '../../services/utilsService';

const { TextArea } = Input;

const templateEditTask = props => {

  const userId = cookieParser(CONST.COOKIE.USER_ID);
  const disabledDates = current => {
    return current && current < moment().endOf('day');
  };

  const { getFieldDecorator } = props.form;

  return(<Form
            layout='vertical'>
            <Form.Item>
            <Card
              className="pf-general-text-block"
              style={{marginBottom: '15px'}}
              bordered={false}>
              <p
                style={{marginBottom: '0px'}}>
                Uploads will be updated to templated task automatically, you will not need to update. If upload is already 
                available in "My Documents" then it can be added from there in order to not cause duplication. Edit Templated Task window  will not
                close till uploads have been completed.
              </p>
            </Card>
            <Alert showIcon message='Any uploads directly to a templated task will automatically be added to "My Documents".' type="info" />
            </Form.Item>
            <Form.Item
              labelAlign={"left"}
              label="Task"
              validateStatus={props.task ? CONST.VALIDATION_STATES.SUCCESS : CONST.VALIDATION_STATES.ERROR}
              hasFeedback>
              {getFieldDecorator('task', {
                rules: [
                  {
                    type: 'string',
                    required: true,
                    message: 'Please enter a task'
                  }
                ],
              })(<Input
                    type="text"
                    id={props.type + 'TaskId'}
                    placeholder="Task"/>)}
            </Form.Item>
            <Form.Item
              labelAlign={"left"}
              label="Notes">
              {getFieldDecorator('notes', {
                initialValue: ''
              })(
                <TextArea
                name="notes"
                autoSize="true"
                style={{minHeight: '60px'}}
                onFocus={e => e.target.style.minHeight = '90px'}
                onBlur={e => e.target.style.minHeight = '60px'}
                placeholder="Notes"/>
              )}
            </Form.Item>
            <Row gutter={[{ xs: 8, sm: 16}]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  labelAlign={"left"}
                  label="Upload">
                    <Upload
                      className="pf-task-upload"
                      showUploadList={true}
                      fileList={props.uploads ? props.uploads : []}
                      beforeUpload={file => {
                        // TODO: may or may not need to figure out type, file.type
                        if (file.size >= 100000000) { // 100000000 = 100MBs
                          // TODO: return some kind of error message
                          message.error('Upload exceeds max limit of 100MBs.');
                          return false
                        }
                        return true;
                      }}
                      customRequest={fileEvent => {
                        props.setEditTaskLoading(true);
                        fileEvent.filename = fileEvent.file.name;

                        restCall('/template/get-signed-url', CONST.REST_TYPE.POST, {fileEvent, userId: userId})
                            .then(res => res.json())
                            .then(data => {
                              // Upon 401
                              if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                                return errorHandleUnauthorized(message, data.msg);

                              if (data && data.success) {
                                let signedUrl = data.signedUrlObj;
                                uploadClientSide(signedUrl, fileEvent);
                              }
                            });
                      }}
                      onChange={e => {
                        if (!e.file || e.file.size >= 100000000)
                          return;
                        // Adds URL to file within list..
                        if (e.file.status === CONST.UPLOAD.STATUS.DONE) {
                          // TODO: upload e.file to some template upload api..
                          restCall(`/template/update-task-uploads/${props.id}`, CONST.REST_TYPE.POST, {file: e.file})
                            .then(res => res.json())
                            .then(data => {
                              // Upon 401
                              if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                                return errorHandleUnauthorized(message, data.msg);

                              if (data && data.success) {
                                // Then server response sends new updated uploads
                                props.setEditTaskLoading(false);
                                e.fileList.forEach((upload, idx) => {
                                  // replicate server data
                                  if (!upload.id) {
                                    upload.id = data.uploads[idx].id;
                                    upload.created = data.uploads[idx].created;
                                    upload.userId = data.uploads[idx].userId;
                                  }
                                });
                              }
                            })

                          e.fileList.map(file => {
                            if (file.originFileObj.url)
                              file.url = file.originFileObj.url
                            return file;
                          });
                        }
                        // Delete object if 'removed'
                        if (e.file.status === CONST.UPLOAD.STATUS.REMOVED) {
                          const uploadList = e.fileList;
                          const uploadId = e.file.id;
                          props.setEditTaskLoading(true);
                          // Remove upload from task and active m2m, (*only deletes upload from task, not file server)
                          restCall(`/template/delete-upload-from-task/${props.id}/${uploadId}`, CONST.REST_TYPE.DELETE, {uploads: uploadList})
                            .then(res => res.json())
                            .then(data => {
                              // Upon 401
                              if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                                return errorHandleUnauthorized(message, data.msg);

                              if (data && data.success)
                                props.setEditTaskLoading(false);
                            })
                            .catch(err => '/template/remove-template-upload Err: ' + err);
                        }
                        props.setStateOnEditTask({uploads: e.fileList});
                      }}>
                      <Button
                        block>
                        <Icon type="upload"/> Upload
                      </Button>
                      <div style={{textAlign: 'center', color: '#ccc'}}>
                        <small>*Max file size 100MBs</small>
                      </div>
                    </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  labelAlign={"left"}
                  label="Due">
                  {getFieldDecorator('dueDate', {
                  })(<div
                    className='pf-task-date-wrap'>
                    <DatePicker
                      format={'MM/DD/YYYY'}
                      disabledDate={disabledDates}/>
                  </div>)}
                </Form.Item>
              </Col>
            </Row>
          </Form>);
};

export const WrappedTemplateEditTask = Form.create({
                                                    name: 'template_edit_task',
                                                    onFieldsChange(props, changedField) {
                                                      props.setStateOnEditTask(changedField)
                                                    },
                                                    mapPropsToFields(props) {
                                                      return {
                                                        task: Form.createFormField({
                                                          value: props.task,
                                                        }),
                                                        notes: Form.createFormField({
                                                          value: props.notes,
                                                        }),
                                                        dueDate: Form.createFormField({
                                                          value: props.dueDate ? moment(props.dueDate) : null,
                                                        })
                                                      };
                                                    }
                                                  })(templateEditTask);
