import React, { Component } from 'react';
import AddPropertyContainer from '../containers/AddPropertyContainer';

class AddProperty extends Component {

  render() {
    return  <div>
              <AddPropertyContainer/>
            </div>
  }
}

export default AddProperty;
