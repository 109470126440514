import { CONST } from '../constant/const';

let hasUnauthorizedMsgBeenCasted = false;

export const getUnauthorizedMsgCasting = () => hasUnauthorizedMsgBeenCasted;

export const errorHandleUnauthorized = (message, msg) => {
  const errorMsg = msg ? msg : 'You will be redirected to login in order to authenticate in a moment.'
  message.error(errorMsg, CONST.INTS.MESSAGE_DURATION); // TODO: state change triggers a redirect in render
  hasUnauthorizedMsgBeenCasted = true;
  setTimeout(() => {
    window.location.pathname = '/';
  },7000);
}