import React, { Component } from 'react';
import AddEditPropertyProvider, { AddEditPropertyContext } from '../context/AddEditPropertyProvider';
import { addEditPropertyBasicForm } from '../components/PropertyAddEditComponents/addEditPropertyBasicForm';
import { addEditPropertyFactForm } from '../components/PropertyAddEditComponents/addEditPropertyFactForm';
import { addEditPropertyNameForm } from '../components/PropertyAddEditComponents/addEditPropertyNameForm';
import { addEditPropertyAssignmentForm } from '../components/PropertyAddEditComponents/addEditPropertyAssignmentForm';

import { Form, Tabs, Button, Collapse, Card } from 'antd';

const { TabPane } = Tabs;

const WrappedAddPropertyAssignment = Form.create({name: 'add_property_assignment'})(addEditPropertyAssignmentForm);
const WrappedAddPropertyBasic = Form.create({name: 'add_property_basic'})(addEditPropertyBasicForm);
const WrappedAddPropertyFact = Form.create({name: 'add_property_fact'})(addEditPropertyFactForm);
const WrappedAddPropertyName = Form.create({name: 'add_property_name'})(addEditPropertyNameForm);

const { Panel } = Collapse;

const saveButton = () => {
  return (<AddEditPropertyContext.Consumer>
                  {(context) => (
                  <React.Fragment>
                    <Button
                      disabled={context.state.isSaveDisabled}
                      loading={context.state.isLoading}
                      type="primary"
                      onClick={() => context.utils.saveProperty()}>
                        Save  
                    </Button>
                  </React.Fragment>
                  )}
              </AddEditPropertyContext.Consumer>);
  
}

class AddPropertyContainer extends Component {
  constructor(props) {
    super();
  }

  // TODO: Bring in context, check if user can add-property, if not, redirect
  render() {
    return  <div>
              <AddEditPropertyProvider>
                {<WrappedAddPropertyName {...this.props}/>}
                <Collapse
                  defaultActiveKey={1}
                  style={{marginBottom: '30px'}}>
                  <Panel
                    header="Invites"
                    key="1">
                    <WrappedAddPropertyAssignment {...this.props}/>
                  </Panel>
                  <Panel
                    header="Property Details" key="2">
                    <Card
                      bordered={false}
                      style={{marginBottom: '30px', backgroundColor: '#fafafa', borderRadius: '6px'}}>
                      <p
                        style={{marginBottom: '0px'}}>
                        Property details are optional, fill in what may be necessary for you and the participating parties needs.
                      </p>
                    </Card>
                    <Tabs type="card" style={{marginBottom: '30px'}}>
                      <TabPane tab="Basic Details" key="1">
                        <WrappedAddPropertyBasic {...this.props}/>
                      </TabPane>
                      <TabPane tab="Facts/Features" key="2">
                        <WrappedAddPropertyFact {...this.props}/>
                      </TabPane>
                      <TabPane tab="Construction Details" key="3" disabled={true}>
                        Tab 3
                      </TabPane>
                      <TabPane tab="Other" key="4" disabled={true}>
                        Tab 4
                      </TabPane>
                    </Tabs>
                  </Panel>
                </Collapse>
                { saveButton() }
              </AddEditPropertyProvider> 
            </div>
  }
}

export default AddPropertyContainer;