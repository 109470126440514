import React, { Component } from 'react';
import moment from 'moment';
import { Collapse, Icon, Input, Row, Col, Button, message } from 'antd';
import { restCall } from '../../services/restService';
import { cookieParser, capValue } from '../../services/utilsService';
import { errorHandleUnauthorized, getUnauthorizedMsgCasting } from '../../services/errorHandlingService';
import { CONST } from '../../constant/const';

const { Panel } = Collapse;
const { TextArea } = Input;

const getPanelHeader = commentLength => {
  return <div>
            <Icon type="message" /> {commentLength ? commentLength : ''} {commentLength ? ' Comments' : ' Add a comment'}
          </div>;
}

class PropertyTaskComments extends Component {
  constructor(props) {
    super();
    this.state = {...props, currentComment: '', commentsArr: [], isLoading: false, collapseKey: []};
    this.userId = cookieParser(CONST.COOKIE.USER_ID);
  }

  /*
  If marked as complete (does array sort..issue fix here). 
  Gets correct comments based on correct task
  */
  static getDerivedStateFromProps(props, state) {
    if (props.taskId !== state.taskId)
      return {...props, collapseKey: [], commentsArr: props.commentsIds.length ? state.commentsArr : []};
    return null;
  }

  getAllComments() {
    // If there are comments, getAllComments
    if (this.state.commentsIds.length <= 0)
      return;

    const commentIdArr = Object.assign(this.state.commentsIds, []);
    restCall(`/task/get-all-task-comments`, CONST.REST_TYPE.POST, commentIdArr)
              .then(res => res.json())
              .then(data => {
                // Upon 401
                if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                  return errorHandleUnauthorized(message, data.msg);

                if (data && data.success)
                  this.setState({commentsArr: data.commentsArr});
              });
  }

  createComment() {
    const commentObj = {userId: this.userId,
                        comment: this.state.currentComment,
                        propId: this.state.propId, // Will use to validate user id on property
                        taskId: this.state.taskId,
                        notificationUserId: null,
                        role: null};

    // Figure out who is leaving comment for notification purposes
    if (this.userId === this.state.propertyRoles[this.state.type][0]) {
      commentObj.notificationUserId = this.state.propertyRoles[CONST.ROLE_TYPE.SELLER].length > 0 ? this.state.propertyRoles[CONST.ROLE_TYPE.SELLER][0] : this.state.propertyRoles[CONST.ROLE_TYPE.BUYER][0];
      commentObj.role = this.state.type;
      
    } else {
      commentObj.notificationUserId = this.state.propertyRoles[this.state.type][0];
      commentObj.role = this.state.propertyRoles[CONST.ROLE_TYPE.SELLER].length > 0 ? CONST.ROLE_TYPE.SELLER : CONST.ROLE_TYPE.BUYER;
    }

    restCall(`/task/create-task-comment`, CONST.REST_TYPE.POST, commentObj)
                .then(res => res.json())
                .then(data => {
                  // Upon 401
                  if (data && !data.success && data.status === CONST.INTS.ERROR_CODE_BAD_TOKEN && !getUnauthorizedMsgCasting()) // BAD_TOKEN (401)
                    return errorHandleUnauthorized(message, data.msg);

                  if (data && data.success) {
                    this.state.updateTaskComments(data.commentObj.id);
                    this.setState({currentComment: '', commentsArr: this.state.commentsArr.concat(data.commentObj), commentsIds: this.state.commentsIds.concat(data.commentObj.id), isLoading: false});
                  }
                });
  }

  render() {
  return <Collapse
            bordered={false}
            defaultActiveKey={this.state.collapseKey}
            expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0}/>}
            onChange={() => {
                this.getAllComments();
          }}>
          <Panel
            className="pf-task-panel"
            key={this.state.collapseKey}
            header={getPanelHeader(this.props.commentsIds.length)}>
              {this.props.commentsIds.length > 0 && <hr className="pf-task-comment-hr"/>}
              <div className="pf-task-comment-content" style={{marginBottom: this.state.commentsIds.length > 0 ? '25px' : '0'}}>
                {
                  this.state.commentsArr.length > 0 && this.state.commentsArr.map((commentItem, idx) => {
                    return <div key={idx}>
                              <div className={'pf-comment-bubble-details '.concat(this.userId === commentItem.createdBy ? 'pf-task-comment-details-user' : 'pf-task-comment-details-opposing')}>
                                <strong>{this.userId === commentItem.createdBy ? '' : `${commentItem.firstname} ${commentItem.lastname}, `}</strong>
                                  {commentItem.type && capValue(commentItem.type) + ' | '}
                                  {moment(commentItem.created).format('MMM, D ddd YYYY h:mma')}
                              </div>
                              <div className={'pf-task-comment-bubble '.concat(this.userId === commentItem.createdBy ? 'pf-task-comment-bubble-user' : 'pf-task-comment-bubble-opposing')}>
                                <div className="pf-comment-bubble-text">{commentItem.comment}</div>
                              </div>
                            </div>
                  })
                }
              </div>
              <Row>
                <Col
                  span={24}>
                  <ul
                    style={{display: 'flex', alignItems: 'flex-end'}}
                    className="list-table">
                      <li
                        style={{width: '100%'}}>
                        <TextArea
                          prefix={<Icon type="message" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          placeholder="Write a comment..."
                          value={this.state.currentComment}
                          onFocus={e => e.target.style.minHeight = '60px'}
                          onBlur={e => e.target.style.minHeight = '30px'}
                          onChange={e => this.setState({currentComment: e.target.value})}
                          autoSize/>
                      </li>
                      <li>
                        <Button
                          loading={this.state.isLoading}
                          icon="arrow-up"
                          // shape="circle"
                          type="primary"
                          style={{marginLeft: '5px'}}
                          onClick={() => {
                            if (this.state.currentComment === '')
                              return;
                            this.setState({isLoading: true})
                            this.createComment()
                          }}>
                        </Button>
                      </li>
                  </ul>
                </Col>
              </Row>
          </Panel>
    </Collapse>
  }
}

export default PropertyTaskComments;
