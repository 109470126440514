import { CONST } from '../constant/const';

export const ORIGIN = window.location.hostname === 'localhost' ? `http://${window.location.hostname}:4001/api` : `${window.location.origin}/api`;
// mobile origin: 'http://192.168.0.57:4001/api' (local machines ip)

export const restCall = (route, type, body) => {
  let fetchObj = {method: type,
    headers: {'content-type': 'application/json'}, // Handled by cookies now, 'Authorization': authToken ? authToken : ''
    credentials: 'include'};

  if (type !== CONST.REST_TYPE.GET)
    fetchObj.body = JSON.stringify(body);

  return fetch(ORIGIN + route, fetchObj);
}