import React from 'react';
import { Form, Select, Input, Icon, Card } from 'antd';
import { formItemLayout, capValue } from '../../services/utilsService';
import { AddEditPropertyContext } from '../../context/AddEditPropertyProvider';

const { Option } = Select;

export const addEditPropertyAssignmentForm = props => {

  const { getFieldDecorator } = props.form;

  return(<AddEditPropertyContext.Consumer>
          {(context) => (
            <React.Fragment>
              <span style={{display: 'none'}}>
                {context.utils.assignmentForm.push(props.form)}
              </span>
              <Form>
                <Card
                  bordered={false}
                  style={{marginBottom: '30px', backgroundColor: '#fafafa', borderRadius: '6px'}}>
                  <h4>Please select all neccessary parties for this property.</h4>
                  <p
                    style={{marginBottom: '5px'}}>
                    Once the property has been saved, if party/s has/have already signed up they will be notified of the new property.
                    Otherewise, party/s will be notified with an onboarding email with further instructions.
                    Once the party has followed the link within the email, the property will be available to them in their property list.
                  </p>
                  <small><strong>Note:</strong> Users/emails can be multiple types on a single property if desired.</small>
                </Card>
                <Form.Item
                  {...formItemLayout(2,8,0)}
                  labelAlign={"left"}
                  label="Invitees">
                    <Select
                      mode="multiple"
                      showArrow={true}
                      placeholder="Invite necessary parties"
                      value={context.state.activeRolesArr}
                      onChange={e => {
                        let tempNewInviteArr = null;

                        // If edit, right now we only want to add for now
                        let selected = e[e.length - 1];
                        if (context.state.isEdit && context.state.newInviteArr.indexOf(selected) < 0 && context.state.activeRolesArr.indexOf(selected) < 0) {
                          context.utils.setValue({newInviteArr: context.state.newInviteArr.concat(selected)});
                        }

                        // If edit removal of invite, ensure newInviteArr correctness (remove what is not within activeRolesArr, e)
                        if (context.state.isEdit && context.state.activeRolesArr.indexOf(selected) > -1) {
                          tempNewInviteArr = [...context.state.newInviteArr];
                          context.state.newInviteArr.forEach(newInvite => {
                            // Finds undefined (no longer, activeRolesArr) and remove
                            if (e.find(current => current === newInvite) === undefined)
                              tempNewInviteArr.splice(tempNewInviteArr.indexOf(newInvite), 1);
                          });
                          context.utils.setValue({newInviteArr: [...tempNewInviteArr]});
                        }

                        context.utils.setRolesCorrectValue(props.form, e);
                        context.utils.setValue({activeRolesArr: e});
                        context.utils.isActivesRolesEmailsValid(props.form, context.state.activeRolesArr);
                      }}>
                      {
                        context.state.type && context.utils.getAddEditRolesArr(context.state.type, context.state.activeRolesArr).map((val, idx) => {
                          return <Option  key={idx}
                                          // If edit, we only want to add for now
                                          disabled={(context.state.isEdit && context.state.activeRolesArr.indexOf(val) >= 0 && context.state.newInviteArr.indexOf(val) < 0)}
                                          value={val}>{capValue(val)}
                                  </Option>
                        })
                      }
                    </Select>
                </Form.Item>
                { context.state.activeRolesArr.map((val, idx) => {
                    return <Form.Item
                            key={idx}
                            htmlFor={val}
                            name={val}
                            {...formItemLayout(2,8,0)}
                            label={capValue(val)}
                            hasFeedback>
                            {getFieldDecorator([val], {
                              rules: [
                                {
                                  type: 'email',
                                  message: 'Please enter a valid e-mail'
                                },
                                {
                                  required: true,
                                  message: 'Please enter a valid e-mail',
                                  validator: (rule, value, cb) => {
                                    if (value === '' || value === undefined)
                                      return setTimeout(() => cb(false), 500);
                                    setTimeout(() => cb(), 500);
                                  },
                                },
                              ],
                              initialValue: context.state.emails ? context.state.emails[val] : ''
                            })(<Input
                                  name={val}
                                  prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                  type="email"
                                  placeholder={`${capValue(val)}'s email address`}
                                  // If edit we only want to add for now
                                  disabled={(context.state.isEdit && context.state.newInviteArr.indexOf(val) < 0)}
                                  onChange={e => {
                                    context.utils.isActivesRolesEmailsValid(props.form);
                                    context.utils.setValue({emails: {...context.state.emails, [val]: e.target.value}})
                                  }}/>)}
                          </Form.Item>
                      })}
              </Form>
            </React.Fragment>
          )}
        </AddEditPropertyContext.Consumer>)
}
