import React, { Component } from 'react';
import TemplateMyTasks from './TemplateComponents/TemplateMyTasks';
import TemplateMyDocuments from './TemplateComponents/TemplateMyDocuments';
import { Card, Modal, Collapse } from 'antd';
import { CONST } from '../constant/const';
import { cookieParser } from '../services/utilsService';

const { confirm } = Modal;
const { Panel } = Collapse;


class Templates extends Component {

  constructor() {
    super();
    this.userId = cookieParser(CONST.COOKIE.USER_ID);
    this.state = {
      collapseKeys: {'1': true, '2': false, '3': false, '4': false },
      // pfTasksColumns: [],
      // pfTasksTableData: [],
      // pfTasksPagination: {current: 1},
      // pfDocumentsColumns: [],
      // pfDocumentsTableData: [],
      // pfDocumentsPagination: {current: 1},
      isLoading: false};

    this.confirm = confirm;
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleCollapseChange = this.handleCollapseChange.bind(this);
    // this.tempPfTasksArr = [];
    // this.tempPfDocumentsArr = [];
  }

  componentDidMount() {
    // PF Tasks array (change to upon open)
    /* TEMP comment out due to non-use today
    const pfTaskUpdatedRender = date => moment(date).format('MMMM, D, YYYY h:mma');
    const pfTaskActionsRender = (text, record) => (<a href="/#" onClick={e => {
        e.preventDefault();
        console.log('Edit', record);
      }}>Add task</a>);
    // this.pfTasksColumnsArr = pfTasksColumnsArr(pfTaskUpdatedRender, pfTaskActionsRender);

    // Temp generator for PF Tasks (replace w/ API)
    for (var k = 0; this.tempPfTasksArr.length < 20; k++)
      this.tempPfTasksArr.push({task: `Another task ${k}`, id: k, updated: new Date()});

    this.setState({pfTasksTableData: this.tempPfTasksArr, pfTasksColumns: this.pfTasksColumnsArr, isLoading: false, pfTasksPagination: {current: this.state.pfTasksPagination.current, total: this.tempPfTasksArr.length}});

    // PF Documents (change to upon open)
    const pfDocTitleRender = (text, record) => (<div>
        <a href={record.url} target="_blank" rel="noopener noreferrer"><Icon type="link" /></a> <span>{record.name}</span></div>
      );
    const pfCreatedRender = date => moment(date).format('MMMM, D, YYYY h:mma');
    const pfActionsRender = (text, record) => (<a href="/#" onClick={e => {
        e.preventDefault();
        console.log('Add: ', record);
      }}>Add to task</a>);
    this.pfDocumentsColumnsArr = pfDocumentsColumnsArr(pfDocTitleRender, pfCreatedRender, pfActionsRender);

    // PF document generator (replace w/ API)
    for (var l = 0; this.tempPfDocumentsArr.length < 20; l++)
      this.tempPfDocumentsArr.push({name: `Another document ${l}`, url: 'https://propertyflo.sfo2.digitaloceanspaces.com/pfTaskUpload/42bd2b44-8e94-4e1a-8bdc-4e727821224e/rc-upload-1607142670367-23/59734339959__CC270E81-5651-4289-AF35-A338BED66074.jpeg', id: l, created: new Date()});

    this.setState({myDocumentsColumns: this.myDocumentsColumnsArr, pfDocumentsTableData: this.tempPfDocumentsArr, pfDocumentsColumns: this.pfDocumentsColumnsArr, isLoading: false, pfDocumentsPagination: {current: this.state.pfDocumentsPagination.current, total: this.tempPfDocumentsArr.length}});
    */
  }

  handleCollapseChange(key) {
    // This inits a hide/show (removal of dom components) that will force a render (componentDidMount)
    let collapseKeysObj = {...this.state.collapseKeys};
    collapseKeysObj = Object.keys(collapseKeysObj).map(val => collapseKeysObj[val] = false);
    collapseKeysObj[key] = true;
    this.setState({collapseKeys: collapseKeysObj});
  }

  handleTableChange(pagination, filter, sorter) {
    this.setState({pagination: pagination}, () => this.getPage());
  }

  render() {
    return <div>
      <Card
        className="pf-general-text-block"
        bordered={false}>
        <p
          style={{marginBottom: '0px'}}>
          Utilizing templated tasks enhances the efficiency of assigning responsibilities to buyers or sellers.
          Easily create templated tasks and seamlessly upload associated documents—uploads are automatically saved to the designated My Documents section for convenient access.
        </p>
      </Card>

      <Collapse
        accordion
        defaultActiveKey={1}
        onChange={this.handleCollapseChange}
        style={{marginBottom: '30px'}}>
        {/* My Tasks, table */}
        <Panel
          header="My Tasks"
          key="1">
            {
              this.state.collapseKeys['1'] && <TemplateMyTasks/>
            }
        </Panel>
        {/* My Documents */}
        <Panel
          header="My Documents"
          key="2">
            {
              this.state.collapseKeys['2'] && <TemplateMyDocuments/>
            }
        </Panel>
        {/* PropertyFlo Tasks */}
        {/* <Panel
          header="PropertyFlo Tasks"
          key="3">
            <Card
              className="pf-general-text-block"
              bordered={false}>
              <p
                style={{marginBottom: '0px'}}>
                PropertyFlo tasks are pre-generated tasks provided by PropertyFlo and based upon your profile type (agent, lender, escrow, title), that can be added to your templated tasks by choosing 'Add task'.
              </p>
            </Card>
            {
              this.state.pfTasksTableData.length > 0 &&  <Table
                                                    rowKey={record => record.id}
                                                    columns={this.state.pfTasksColumns}
                                                    scroll={{x: 500, y: 275}}
                                                    size='middle'
                                                    dataSource={this.state.pfTasksTableData}
                                                    pagination={this.state.pfTasksPagination}
                                                    onChange={this.handleTableChange}/>
            }
        </Panel> */}
        {/* PropertyFlo Documents */}
        {/* <Panel
          header="PropertyFlo Documents"
          key="4">
            <Card
              className="pf-general-text-block"
              bordered={false}>
              <p
                style={{marginBottom: '0px'}}>
                PropertyFlo documents are pre-generated documents provided by PropertyFlo and based upon your profile type (agent, lender, escrow, title), that can be added to your templated documents by choosing 'add'.
              </p>
            </Card>
            {
              this.state.pfDocumentsTableData.length > 0 &&  <Table
                                                    rowKey={record => record.id}
                                                    columns={this.state.pfDocumentsColumns}
                                                    scroll={{x: 500, y: 275}}
                                                    size='middle'
                                                    dataSource={this.state.pfDocumentsTableData}
                                                    pagination={this.state.pfDocumentsPagination}
                                                    onChange={this.handleTableChange}/>
            }
        </Panel> */}
      </Collapse>

    </div>
  };
}

export default Templates;
